<template>
  <div class="row">
    <div class="col-lg-8">
      <card>
        <template slot="header">
          <h5 class="card-title">Set Kurs Emas</h5>
        </template>
        <template slot="default">
          <form v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            
            <fg-input label="Tanggal posting" 
                      required
                      :error="getError('Tanggal posting')">
              <el-date-picker v-model="model.postedDate" 
                              type="date" 
                              disabled
                              placeholder="Pilih tanggal"
                              format="dd-MM-yyyy"
                              v-validate="'required'"
                              data-vv-name="Tanggal posting"
                              :picker-options="datePickerOptions">
              </el-date-picker>
            </fg-input>

            <fg-input kind="money" 
                      v-model.number="model.price"
                      v-validate="'required|min_value:0|max_value:100000000000'"
                      data-vv-name="Harga emas"
                      label="Harga emas (per gram)"
                      required
                      :error="getError('Harga emas')">
              <template slot="addonLeft">Rp</template>
            </fg-input>

            <div class="pull-right">
              <p-button outline round @click="goToPrevPage">Batal</p-button>
              <p-button type="primary" round @click="validate">Set Kurs</p-button>
            </div>

          </form>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import { DatePicker, Loading } from 'element-ui'
  import { Card } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import { GOLD_PRICE_FETCH } from '../.././../store/actions/goldPrice'
  import alertHelper from '../../../helpers/alertHelper'

  Vue.use(Loading)

  export default {
    components: {
      Card,
      [DatePicker.name]: DatePicker
    },
    data () {
      return {
        isLoading: false,
        hasPrevRoute: false,
        model: {
          postedDate: moment(),
          price: null,
        },
        datePickerOptions: {
          firstDayOfWeek: 1,
          disabledDate (time) {
            return time.getTime() > moment()
          },
          shortcuts: [
            {
              text: 'Hari ini',
              onClick (picker) {
                picker.$emit('pick', moment())
              }
            },
            {
              text: 'Kemarin',
              onClick (picker) {
                const date = moment().add(-1, 'd')
                picker.$emit('pick', date)
              }
            },
          ]
        },
      }
    },
    methods: {
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.savePrice()
        })
      },
      savePrice () {
        this.isLoading = true

        this.axios.post(urlConstant.API_URL_GOLD_PRICES, this.model)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isLoading = false
              return
            }

            this.fetchGoldPrice()
            this.showSaveSuccessMessage()
            this.isLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isLoading = false
          })
      },
      showSaveSuccessMessage () {
        const title = 'Kurs emas berhasil diset!'
        
        alertHelper.basic(title, null, alertHelper.typeEnums.success, null, this.goToPrevPage)
      },
      fetchGoldPrice () {
        this.$store.dispatch(GOLD_PRICE_FETCH)
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_REFERENCES_GOLD_PRICES)
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name));
    },
  }
</script>
<style scoped>
</style>
