<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Buat Invoice Penjualan</h5>
          <h5 class="card-title" v-if="isView">Invoice Penjualan</h5>
        </template>
        <template slot="default">
          <h6>Detail</h6>
          <div class="pt-1" v-if="isView">
            <div class="alert" :class="getStatusAlertColor(model.status)">
              Status: <strong class="text-uppercase">{{getStatusLocal(model.status)}}</strong>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage()" v-if="canBack">Kembali</p-button>
          </div>

          <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Pelanggan" 
                            v-if="isNew"
                            required 
                            :disabled="isView"
                            :error="getError('Pelanggan')">
                    <el-select class="kf select-default" 
                              ref="customerDropdown"
                              v-model="model.customerId"
                              v-validate="'required'"
                              :disabled="isView"
                              data-vv-name="Pelanggan"
                              placeholder="Cari pelanggan"
                              @change="onCustomerChange(model)"
                              filterable
                              remote
                              :clearable="selects.initialCustomers[0] && model.customerId !== selects.initialCustomers[0].id"
                              :remote-method="fetchCustomers"
                              :loading="isFetchingCustomers">
                      <el-option v-for="option in selects.customers"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                                <div class="d-flex justify-content-between">
                                  <div v-html="$options.filters.highlight(option.name, customerSearchText)"></div>
                                  <div class="ml-3 small text-muted" v-html="getCustomerDescription(option, customerSearchText)"></div>
                                </div>
                      </el-option>
                      <template slot="empty">
                        <p class="el-select-dropdown__empty">
                          Tidak ada data<br/>
                          <button type="button" class="btn btn-round btn-warning mb-1" @click="openAddCustomerModal">Tambah Pelanggan</button>
                        </p>
                      </template>
                    </el-select>
                  </fg-input>
                  <fg-input label="Pelanggan" 
                            v-model="model.customerName"
                            v-if="isView"
                            disabled
                            required></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Nomor invoice" 
                            v-model="model.transactionNo"
                            v-if="isView"
                            disabled
                            required></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Petugas kasir" v-model="model.createdByFullName" disabled required></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Tanggal & waktu transaksi" :value="model.createdAt | dateTimeFormat" disabled required></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Catatan untuk pelanggan" :disabled="isView">
                    <textarea class="form-control" 
                              rows="3"
                              v-model="model.externalNote" 
                              :disabled="isView"></textarea>
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Memo internal" :disabled="isView">
                    <textarea class="form-control" 
                              rows="3"
                              v-model="model.internalNote" 
                              :disabled="isView"></textarea>
                  </fg-input>
                </div>
              </div>

              <h6 class="mt-3">Produk</h6>

              <div class="d-flex justify-content-end mt-2">
                <p-checkbox v-model="model.taxInclusive" 
                  :disabled="isView" 
                  text-left
                  @input="calculateAllItemAmounts()">
                  Harga termasuk pajak
                </p-checkbox>
              </div>

              <div class="section-items" :class="{'section-items-edit-mode': isNew}">
                <el-table :data="model.items" 
                          header-row-class-name="text-primary" 
                          class="table-compact" 
                          ref="itemsTable"
                          @current-change="onTableSelectionChange"
                          :highlight-current-row="isNew">
                  <el-table-column label="No." type="index" width="50" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-index">{{scope.$index + 1}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Produk" min-width="150" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input required 
                                  class="mb-0"
                                  :disabled="isView || !!scope.row.productId">
                          <el-select class="kf select-default" 
                                    v-model="scope.row.productId"
                                    @change="onProductChange(scope.row, scope.$index)"
                                    :disabled="isView || !!scope.row.productId"
                                    placeholder="Cari kode produk"
                                    filterable
                                    remote
                                    :remote-method="fetchProducts"
                                    :loading="isFetchingProducts">
                            <el-option v-for="option in selects.products"
                                      class="kf select-default"
                                      :value="option.id"
                                      :label="option.code"
                                      :key="option.id">
                                      <div class="d-flex justify-content-between">
                                        <div>{{ option.code }}</div>
                                        <div class="ml-3 small text-muted">{{ option.name }}</div>
                                      </div>
                            </el-option>
                          </el-select>
                        </fg-input>
                      </div>
                      <div class="cell-view">
                        <div>
                          <div v-if="isView">
                            <a href="javascript:void(0);" @click.prevent="goToProductDetail(scope.row.productId)">{{scope.row.productCode}}</a>
                          </div>
                          <div v-else>{{scope.row.productCode}}</div>
                        </div>
                        <div>{{scope.row.productName}}</div>
                        <div>{{scope.row.productMaterialName}} {{scope.row.productCarat}}k / {{scope.row.productCaratPercentage}}%</div>
                        <div>{{scope.row.productWeight}}gr<span v-if="scope.row.productSize"> / Ukuran {{scope.row.productSize}}</span></div>
                        <div>
                          <div v-if="scope.row.trayId" class="text-success">Baki: {{scope.row.trayCode}} - {{scope.row.trayName}}</div>
                          <div v-else class="text-warning">Produk diambil dari stok!</div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Catatan" min-width="150" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input v-model="scope.row.note"
                                  :disabled="isView || !scope.row.productId"
                                  class="mb-0"></fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.note || '-'}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Qty" width="90" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input type="number" 
                                  onwheel="this.blur()"
                                  v-model.number="scope.row.quantity"
                                  @change="calculateItemAmounts(scope.row)"
                                  :disabled="isView || !scope.row.productId"
                                  v-validate="scope.row.productId ? `required|min_value:1|max_value:${scope.row.productQuantity}` : ''"
                                  :required="!!scope.row.productId"
                                  :data-vv-name="`Qty-${scope.$index}`"
                                  data-vv-as="Qty"
                                  :error="getError(`Qty-${scope.$index}`)"
                                  hide-error-message
                                  class="mb-0">
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.quantity | numberFormat}}{{(scope.row.quantity || scope.row.quantity === 0) && scope.row.productUnitName ? ` ${scope.row.productUnitName}` : ''}}</div>
                      <div class="cell-error text-danger" v-if="isNew && getError(`Qty-${scope.$index}`)">
                        {{getError(`Qty-${scope.$index}`)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Harga satuan" width="150" align="right" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input kind="money" 
                                  v-model.number="scope.row.displayUnitPrice"
                                  :disabled="isView || !scope.row.productId"
                                  @change="calculateItemAmounts(scope.row)"
                                  v-validate="scope.row.productId ? 'required|min_value:0|max_value:100000000000' : ''"
                                  :required="!!scope.row.productId"
                                  :data-vv-name="`Harga satuan-${scope.$index}`"
                                  data-vv-as="Harga satuan"
                                  :error="getError(`Harga satuan-${scope.$index}`)"
                                  hide-error-message
                                  class="mb-0">
                          <template slot="addonLeft">Rp</template>
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.displayUnitPrice | currencyFormat}}</div>
                      <div class="cell-edit-info text-warning" v-if="scope.row.expectedUnitPrice && scope.row.expectedUnitPrice > 0">
                        Rekomendasi harga:<br/>{{scope.row.expectedUnitPrice | currencyFormat}}
                      </div>
                      <div class="cell-error text-danger" v-if="isNew && getError(`Harga satuan-${scope.$index}`)">
                        {{getError(`Harga satuan-${scope.$index}`)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Pajak" width="135" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input :disabled="isView || !scope.row.productId"
                                  class="mb-0">
                          <el-select class="kf select-default" 
                                    v-model="scope.row.taxes[0].taxItemId"
                                    placeholder="Pilih pajak"
                                    @change="onTaxItemChange(scope.row)"
                                    clearable
                                    filterable
                                    :disabled="isView || !scope.row.productId">
                            <el-option v-for="option in selects.taxItems"
                                      class="kf select-default"
                                      :value="option.id"
                                      :label="option.name"
                                      :key="option.id">
                                      <div class="d-flex justify-content-between">
                                        <div>{{ option.name }}</div>
                                        <div class="ml-3 small text-muted">{{ option.description }}</div>
                                      </div>
                            </el-option>
                          </el-select>
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.taxes[0].taxItemName || '-'}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Jumlah" width="150" align="right" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input kind="money" 
                                  v-model.number="scope.row.subTotalAmount"
                                  disabled
                                  class="mb-0">
                          <template slot="addonLeft">Rp</template>
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.subTotalAmount | currencyFormat}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column class-name="action-buttons td-actions" align="right" width="80" v-if="isNew">
                    <template slot-scope="scope">
                      <el-tooltip v-if="selectedItem && scope.row.productId" placement="top" content="Selesai Edit" class="cell-edit">
                        <p-button type="success" size="sm" icon @click.stop="selectTableItem()" class="rounded">
                          <i class="fa fa-check"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip v-if="scope.row.productId" placement="top" content="Keluarkan Produk">
                        <p-button type="danger" size="sm" icon class="rounded" @click.stop="removeItem(scope.row, scope.$index)">
                          <i class="fa fa-times"></i>
                        </p-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="row my-2">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                  <div class="row py-2" v-if="model.taxGroups.length">
                    <div class="col-6">Subtotal</div>
                    <div class="col-6 text-right">{{(model.subTotalAmount || 0) | currencyFormat}}</div>
                  </div>

                  <template v-if="model.taxGroups.length && model.taxInclusive">
                    <div class="row px-2 pb-2 small text-muted">
                      <div class="col-12">Sudah termasuk:</div>
                      <div class="col-12">
                        <div v-for="(taxGroup, index) in model.taxGroups" :key="index" class="row">
                          <div class="col-6">{{taxGroup.name}}<span v-if="!itemsHaveSameTax">*</span></div>
                          <div class="col-6 text-right">{{taxGroup.amount | currencyFormat}}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="model.taxGroups.length && !model.taxInclusive">
                    <div v-for="(taxGroup, index) in model.taxGroups" :key="index" class="row py-2">
                      <div class="col-6">{{taxGroup.name}}<span v-if="!itemsHaveSameTax">*</span></div>
                      <div class="col-6 text-right">{{taxGroup.amount | currencyFormat}}</div>
                    </div>
                  </template>

                  <div class="row py-2" v-if="model.taxGroups.length">
                    <div class="col-12">
                      <div class="border-top"></div>
                    </div>
                  </div>

                  <div class="row py-2">
                    <div class="col-6 text-uppercase"><strong>Total</strong></div>
                    <div class="col-6 text-right"><strong>{{(model.totalAmount || 0) | currencyFormat}}</strong></div>
                  </div>

                  <div class="row py-2" v-if="model.taxGroups.length && !itemsHaveSameTax">
                    <div class="col-12 small text-muted">* Pajak untuk produk tertentu</div>
                  </div>
                </div>
              </div>
            </form>

            <invoice-detail-payment 
              id="invoice-detail-payment"
              class="pt-3" 
              v-if="isView && isShowPayment"
              :new-mode="!model.activePayment"
              :view-mode="!!model.activePayment"
              :customer="{id: model.customerId, name: model.customerName}"
              :payment="model.activePayment"
              :invoices="[model]"
              @cancelled="goToPrevPage"
              @payment-created="showPaymentSuccessMessage">
            </invoice-detail-payment>

            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage()" v-if="canBack">Kembali</p-button>
              <p-button outline round @click="goToPrevPage()" v-if="isNew">Batal</p-button>
              <p-button type="danger" round @click="cancelSellInvoice()" v-if="canCancelSellInvoice">Batalkan Invoice</p-button>
              <p-button type="danger" round @click="cancelSellInvoicePayment()" v-if="canCancelSellInvoicePayment">Batalkan Pembayaran</p-button>
              <p-button type="danger" round @click="cancelPaymentAndSellInvoice()" v-if="canCancelSellInvoicePayment">Batalkan Pembayaran & Invoice</p-button>
              <p-button type="primary" round @click="validate()" v-if="isNew">Buat Invoice Penjualan</p-button>
              <p-button type="primary" round @click="createPayment()" v-if="canCreatePayment">Buat Pembayaran</p-button>
              <p-button type="primary" round @click="recalculate()" v-if="canRecalculate">Kalkulasi Ulang</p-button>
              <p-button type="primary" round @click="print()" v-if="canPrint"><i class="fa fa-print"></i>&nbsp;&nbsp;Cetak</p-button>
            </div>

            <iframe 
              :src="printSrc"
              v-if="canPrint && printIndex > 0"
              :key="printIndex"
              frameBorder="0"
              ref="printFrame"
              class="print-frame">
            </iframe>
          </div>
        </template>
      </card>

      <!-- Add customer modal -->
      <modal :show.sync="modals.addCustomer" 
             modal-classes="kf-modal-lg"
             body-classes="kf-modal-body-compact"
             :allow-outside-click="false">
        <h5 slot="header" class="my-0">Tambah Pelanggan</h5>
        <customer-detail-form
          v-if="modals.addCustomer"
          :is-modal="true"
          :is-new="true"
          @close="closeAddCustomerModal">
        </customer-detail-form>
      </modal>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import base64url from 'base64url'
  import _ from 'lodash'
  import { Select, Option, Loading, Table, TableColumn, Tooltip } from 'element-ui'
  import { Card, Modal } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import arrayHelper from '../../../helpers/arrayHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'
  import * as taxItemTypeEnum from '../../../enums/taxItemTypeEnum'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import * as sellInvoiceStatusEnum from '../../../enums/sellInvoiceStatusEnum'
  import InvoiceDetailPayment from './InvoiceDetailPayment'
  import * as userRoleEnum from '../../../enums/userRoleEnum'
  import * as sellInvoicePaymentStatusEnum from '../../../enums/sellInvoicePaymentStatusEnum'
  import * as taxItemCodeEnum from '../../../enums/taxItemCodeEnum'
  import * as customerSearchQueryOrderEnum from '../../../enums/customerSearchQueryOrderEnum'
  import alertHelper from '../../../helpers/alertHelper'
  import CustomerDetailForm from './CustomerDetailForm'
  import mathHelper from '../../../helpers/mathHelper'

  Vue.use(Loading)

  const initialInvoiceTaxItem = {
    id: null,
    taxItemId: null,
    taxItemName: '',
    amount: null
  }

  const initialInvoiceItem = {
    id: null,
    productId: null,
    productName: '',
    productCode: '',
    productDescription: '',
    productWeight: null,
    productSize: null,
    productCarat: null,
    productCaratPercentage: null,
    productMaterialId: null,
    productMaterialName: '',
    productUnitId: null,
    productUnitName: '',
    productQuantity: null,
    note: '',
    quantity: null,
    unitPrice: null,
    displayUnitPrice: null,
    expectedUnitPrice: null,
    subTotalAmount: null,
    taxes: [initialInvoiceTaxItem],
    taxAmount: null,

    // Tray information
    trayId: null,
    trayName: '',
    trayCode: '',
  }

  export default {
    components: {
      Card,
      Modal,
      [Option.name]: Option,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
      InvoiceDetailPayment,
      CustomerDetailForm,
    },
    data () {
      return {
        isInvoiceLoading: false,
        isReferencesLoading: false,
        isReferencesLoaded: false,
        isSaveLoading: false,
        isFetchingCustomers: false,
        isFetchingProducts: false,
        isNew: false,
        isView: false,
        isShowPayment: false,
        hasPrevRoute: false,
        selectedItem: null,
        defaultTaxItem: null,
        customerSearchText: '',
        isPrinting: false,
        printSrc: '',
        printIndex: 0,
        model: {
          id: null,
          customerId: null,
          customerName: '',
          transactionNo: '',
          externalNote: '',
          internalNote: '',
          subTotalAmount: null,
          taxAmount: null,
          taxGroups: [],
          totalAmount: null,
          items: [_.cloneDeep(initialInvoiceItem)],
          createdAt: null,
          createdByFullName: '',
          status: '',
          payments: [],
          activePayment: null,
          taxInclusive: true,
        },
        selects: {
          taxItems: [],
          customers: [],
          initialCustomers: [],
          products: [],
          initialProducts: [],
        },
        modals: {
          addCustomer: false,
        },
        intervals: {
          createdAtFetcher: null
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false

        if (!this.$route.params.invoiceSlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else {
          this.isNew = true
        }

        this.isShowPayment = false

        if (!this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }

        if (this.isNew) {
          const currentUser = this.$store.getters.getUser

          this.model.createdByFullName = currentUser.fullName

          const updateCreatedAt = () => {
            this.model.createdAt = moment().format()
          }

          updateCreatedAt()
          this.intervals.createdAtFetcher = setInterval(() => {
            updateCreatedAt()
          }, 1000 * 60)

          return
        }
        
        this.printSrc = `${window.location.origin}${urlConstant.WEB_URL_INVOICES_PRINT}/${this.$route.params.invoiceSlug}`

        const invoiceId = base64url.decode(this.$route.params.invoiceSlug)

        this.isInvoiceLoading = true
        this.axios.get(`${urlConstant.API_URL_INVOICES}/${invoiceId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isInvoiceLoading = false
              return
            }
            
            if (!response.data.invoice) {
              alertHelper.basic('Invoice penjualan tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              this.isInvoiceLoading = false
              return
            }
            
            this.model = _.cloneDeep(Object.assign(this.model, response.data.invoice))

            // Get the last active payment
            const paidPayments = this.model.payments && this.model.payments.length 
              ? this.model.payments.filter(p => p.status === sellInvoicePaymentStatusEnum.PAID) 
              : []
            this.model.activePayment = paidPayments.length ? paidPayments[paidPayments.length - 1] : null
            
            for (const item of this.model.items) {
              // Update the display unit price
              const unitTaxAmount = mathHelper.round(item.taxAmount / item.quantity)
              if (this.model.taxInclusive) {
                item.displayUnitPrice = mathHelper.round(item.unitPrice + unitTaxAmount)
              }
              else {
                item.displayUnitPrice = item.unitPrice
              }

              // Handle empty taxes
              if (!item.taxes || !item.taxes.length) {
                item.taxes = [_.cloneDeep(initialInvoiceTaxItem)]
              }
            }

            // Make sure we don't recalculate the tax here
            this.calculateAllItemAmounts(false)

            this.updatePaymentSectionState()
            this.checkForAutoPrint()
            
            this.isInvoiceLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isInvoiceLoading = false
          })
      },
      updatePaymentSectionState () {
        this.isShowPayment = this.model.status === sellInvoiceStatusEnum.PAID

        if (this.canCreatePayment && this.$route.query.payment === 'true') {
          this.createPayment()
        }
      },
      checkForAutoPrint () {
        if (this.canPrint && this.$route.query.print === 'true') {
          this.$nextTick(() => {
            this.print()
          })
        }
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.saveInvoiceConfirmation()
        })
      },
      saveInvoiceConfirmation () {
        // Make sure there are at least one item
        if (!this.model.items.find(i => i.productId)) {
          const content = 'Belum ada produk yang ditambahkan ke invoice.'
          alertHelper.basic(null, content, alertHelper.typeEnums.warning)
          return
        }

        const title = 'Konfirmasi Penjualan'
        const body = `
          <div>
            <div class="my-1">Jumlah barang: ${this.model.items.reduce((val, i) => val + i.quantity, 0)}</div>
            <div class="my-1"><strong>Total: ${filterHelper.currencyFormat(this.model.totalAmount)}</strong></div>
          </div>`
        const onConfirm = () => { this.saveInvoice() }

        alertHelper.confirmHtml(title, body, alertHelper.typeEnums.warning, 'Buat Invoice', 'Batal', onConfirm)
      },
      saveInvoice () {
        this.isSaveLoading = true

        // Remove empty objects
        const modelCopy = _.cloneDeep(this.model)
        modelCopy.items = modelCopy.items.filter(i => i.productId)
        for (const item of modelCopy.items) {
          item.taxes = item.taxes.filter(i => i.taxItemId)
        }

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_INVOICES, modelCopy)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.invoice)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      showSaveSuccessMessage (invoice) {
        const title = 'Invoice penjualan berhasil dibuat!'
        const contentHtml = `Nomor invoice: ${invoice.transactionNo}`
        const onConfirm = () => { this.$router.replace(`${urlConstant.WEB_URL_INVOICES_VIEW}/${base64url.encode(invoice.id.toString())}?payment=true`) }
        const onCancel = () => { this.$router.replace(urlConstant.WEB_URL_INVOICES) }

        alertHelper.confirmHtml(title, contentHtml, alertHelper.typeEnums.success, 'Terima Pembayaran', 'Daftar Penjualan', onConfirm, onCancel)
      },
      showSaveFailedMessage (apiResponse) {
        if (!apiResponse || !apiResponse.apiError) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_INVOICE_DUPLICATE_INVOICE_ITEM) {
          showAlert('Jenis produk yang sama diinput lebih dari sekali untuk satu invoice, mohon cek kembali daftar produk di invoice.')
          return
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_INVOICE_DUPLICATE_INVOICE_TAX_ITEM) {
          showAlert('Jenis pajak yang sama diinput lebih dari sekali untuk salah satu jenis produk, mohon cek kembali daftar pajak untuk masing-masing produk.')
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_CUSTOMERS, { params: { 
            pageSize: 1,
            pageNumber: 1,
            order: customerSearchQueryOrderEnum.CreatedAtAsc 
          }}),
          this.axios.get(urlConstant.API_URL_REFERENCES_TAX_ITEMS),
        ])
          .then(responses => {
            const pCustRes = responses[0]
            const pTaxRes = responses[1]

            // Initial customers
            const customers = pCustRes.data.isSuccess ? pCustRes.data.customers : []
            this.selects.initialCustomers = customers
            this.selects.customers = _.cloneDeep(this.selects.initialCustomers)
            if (this.selects.customers && this.selects.customers.length) {
              this.model.customerId = this.selects.customers[0].id
            }

            // Tax items
            const taxItems = pTaxRes.data.isSuccess ? pTaxRes.data.taxItems : []
            taxItems.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.taxItems = taxItems
            this.model.defaultTaxItem = taxItems.find(ti => ti.code === taxItemCodeEnum.getDefault())

            this.isReferencesLoading = false
          })
          .catch(() => {
            this.isReferencesLoading = false
          })
      },
      fetchCustomers (query) {
        this.customerSearchText = query

        if (!query) {
          this.selects.customers = _.cloneDeep(this.selects.initialCustomers)
          return
        }

        const params = { 
          name: query, 
          phoneNumber: query,
          identifierNo: query,
          email: query,
          useOrOperator: true,
          order: customerSearchQueryOrderEnum.NameAsc,
          pageSize: 10,
          pageNumber: 1 
        }

        this.isFetchingCustomers = true
        this.axios.get(urlConstant.API_URL_CUSTOMERS, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              this.selects.customers = []
              this.isFetchingCustomers = false
              return
            }
            
            this.selects.customers = response.data.customers
            this.isFetchingCustomers = false
          })
          .catch(() => {
            this.selects.customers = []
            this.isFetchingCustomers = false
          })
      },
      fetchProducts (query) {
        if (!query) {
          this.selects.products = _.cloneDeep(this.selects.initialProducts)
          return
        }
        
        const params = { 
          code: query,
          pageSize: 10,
          pageNumber: 1
        }

        const fetchProductsFromStock = () => {
          this.isFetchingProducts = true
          this.axios.get(urlConstant.API_URL_PRODUCTS_STOCKS, { params: params })
            .then(response => {
              if (!response.data.isSuccess) {
                this.selects.products = []
                this.isFetchingProducts = false
                return
              }
              
              this.selects.products = response.data.products
              this.isFetchingProducts = false
            })
            .catch(() => {
              this.selects.products = []
              this.isFetchingProducts = false
            })
        }

        // Fetch product from tray first. If doesn't exist, then get from the stocks
        this.isFetchingProducts = true
        this.axios.get(urlConstant.API_URL_PRODUCTS_TRAYS, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              this.selects.products = []
              this.isFetchingProducts = false
              return
            }
            
            this.selects.products = response.data.trayProducts
            this.isFetchingProducts = false

            if (this.selects.products.length === 0) {
              fetchProductsFromStock()
            }
          })
          .catch(() => {
            this.selects.products = []
            this.isFetchingProducts = false
          })
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_INVOICES)
        }
      },
      onProductChange (invoiceItem, rowIndex) {
        const product = invoiceItem.productId 
          ? this.selects.products.find(p => p.id === invoiceItem.productId) 
          : null

        if (product && this.model.items.filter(i => product.id === i.productId).length > 1) {
          const title = 'Gagal menambah produk!'
          const contentHtml = `Produk <strong>${product.code} - ${product.name}</strong> sudah ada di dalam transaksi.`

          alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.warning)

          invoiceItem.productId = null
          this.selects.products = []
          return
        }

        var productQuantity = 0;
        if (product) {
          productQuantity = product.trayId ? product.trayItemUnitAmount : product.unitAmount
        }
        
        invoiceItem.quantity = 1
        invoiceItem.productName = product ? product.name : ''
        invoiceItem.productCode = product ? product.code : ''
        invoiceItem.productQuantity = productQuantity
        invoiceItem.productUnitId = product ? product.unitId : null
        invoiceItem.productUnitName = product ? product.unitName : ''
        invoiceItem.productWeight = product ? product.weight : null
        invoiceItem.productSize = product ? product.size : null
        invoiceItem.productCarat = product ? product.carat : null
        invoiceItem.productCaratPercentage = product ? product.caratPercentage : null
        invoiceItem.productMaterialId = product ? product.materialId : null
        invoiceItem.productMaterialName = product ? product.materialName : ''
        invoiceItem.expectedUnitPrice = product ? product.expectedSellPrice : null
        invoiceItem.trayId = product ? product.trayId : null
        invoiceItem.trayName = product ? product.trayName : ''
        invoiceItem.trayCode = product ? product.trayCode : ''
        
        if (rowIndex === this.model.items.length - 1) {
          if (this.model.defaultTaxItem) {
            invoiceItem.taxes[0].taxItemId = this.model.defaultTaxItem.id
            this.onTaxItemChange(invoiceItem)
          }

          this.model.items.push(_.cloneDeep(initialInvoiceItem))
          this.selectTableItem(invoiceItem)
        }

        this.selects.products = []
      },
      onCustomerChange (model) {
        if (!model.customerId) {
          this.selects.customers = _.cloneDeep(this.selects.initialCustomers)
          model.customerId = this.selects.customers[0].id
          return
        }

        const customer = this.selects.customers.find(c => c.id === model.customerId)
        
        model.customerName = customer ? customer.name : ''
      },
      onTableSelectionChange (value) {
        this.selectedItem = value
      },
      onTaxItemChange (invoiceItem) {
        if (!invoiceItem.taxes || !invoiceItem.taxes[0]) {
          this.calculateItemAmounts(invoiceItem)
          return
        }

        const tax = invoiceItem.taxes[0]

        if (!tax.taxItemId) {
          tax.taxItemName = ''
          this.calculateItemAmounts(invoiceItem)
          return
        }

        const taxItem = this.selects.taxItems.find(ti => ti.id === tax.taxItemId)

        tax.taxItemName = taxItem ? taxItem.name : ''
        this.calculateItemAmounts(invoiceItem)
      },
      selectTableItem (row) {
        this.$refs.itemsTable.setCurrentRow(row)
      },
      removeItem (row, rowIndex) {
        const contentHtml = `Apakah kamu yakin ingin mengeluarkan produk <strong>${row.productName}</strong> dari transaksi?`
        const onConfirm = () => {
          this.model.items.splice(rowIndex, 1)
          this.calculateInvoiceAmounts()
        }

        alertHelper.confirmHtml(null, contentHtml, alertHelper.typeEnums.warning, 'Keluarkan Produk', 'Batal', onConfirm)
      },
      calculateTaxItemAmounts (tax, itemSubTotalAmount) {
        tax.amount = null

        const taxItem = tax && tax.taxItemId ?
          this.selects.taxItems.find(ti => ti.id === tax.taxItemId) : null

        if (!taxItem) {
          return
        }

        if (taxItem.type === taxItemTypeEnum.FIXED_AMOUNT) {
          tax.amount = taxItem.amount
          return
        }
        if (taxItem.type === taxItemTypeEnum.PERCENTAGE) {
          tax.amount = mathHelper.round(this.model.taxInclusive ? 
            itemSubTotalAmount - ((100 * itemSubTotalAmount) / (100 + taxItem.amount)) :
            itemSubTotalAmount * taxItem.amount / 100)
          return
        }
      },
      calculateItemAmounts (invoiceItem, recalculateTax = true) {
        invoiceItem.subTotalAmount = null
        invoiceItem.taxAmount = null

        if (!invoiceItem || 
            (!invoiceItem.displayUnitPrice && invoiceItem.displayUnitPrice !== 0) || 
            invoiceItem.displayUnitPrice < 0 ||
            (!invoiceItem.quantity && invoiceItem.quantity !== 0) ||
            invoiceItem.quantity < 0) {
          return
        }

        // Calculate tax
        if (recalculateTax) {
          for (const tax of invoiceItem.taxes) {
            this.calculateTaxItemAmounts(tax, mathHelper.round(invoiceItem.displayUnitPrice * invoiceItem.quantity))
          }
        }
        invoiceItem.taxAmount = mathHelper.round(invoiceItem.taxes.reduce((val, tax) => val + tax.amount, 0))

        // Recalculate the unit price when tax is inclusive
        if (this.model.taxInclusive) {
          let unitTaxAmount = mathHelper.round(invoiceItem.taxAmount / invoiceItem.quantity)
          invoiceItem.unitPrice = mathHelper.round(invoiceItem.displayUnitPrice - unitTaxAmount)
        }
        else {
          invoiceItem.unitPrice = invoiceItem.displayUnitPrice
        }
        
        // Sub total
        invoiceItem.subTotalAmount = mathHelper.round(invoiceItem.displayUnitPrice * invoiceItem.quantity)

        this.calculateInvoiceAmounts()
      },
      calculateInvoiceAmounts () {
        this.model.subTotalAmount = null
        this.model.taxGroups = []
        this.model.taxAmount = null
        this.model.totalAmount = null

        // Sub total
        const validInvoiceItems = this.model.items.filter(i => i.subTotalAmount || i.subTotalAmount === 0)
        this.model.subTotalAmount = mathHelper.round(validInvoiceItems.reduce((val, item) => val + item.subTotalAmount, 0))

        // Taxes
        this.updateTaxGroups()
        this.model.taxAmount = mathHelper.round(this.model.taxGroups.reduce((val, tg) => val + tg.amount, 0))
        
        // Grand total
        this.model.totalAmount = mathHelper.round(this.model.taxInclusive
          ? this.model.subTotalAmount
          : this.model.subTotalAmount + this.model.taxAmount)
      },
      calculateAllItemAmounts (recalculateTax = true) {
        this.model.items
          .filter(i => !!i.productId)
          .forEach(item => {
            this.calculateItemAmounts(item, recalculateTax)
          })
      },
      updateTaxGroups () {
        const allTaxes = this.model.items
          .reduce((taxes, item) => {
            taxes.push(...item.taxes)
            return taxes
          }, [])
          .filter(tax => tax.taxItemId)

        const group = _.groupBy(allTaxes, tax => tax.taxItemId)

        this.model.taxGroups = []
        
        for (const key of Object.keys(group)) {
          const taxGroup = group[key]
          const totalTaxAmount = mathHelper.round(taxGroup.reduce((val, tax) => val + tax.amount, 0))

          this.model.taxGroups.push({
            name: taxGroup[0].taxItemName,
            amount: totalTaxAmount,
            count: taxGroup.length
          })
        }
      },
      createPayment () {
        this.isShowPayment = true

        this.$nextTick(() => {
          document.querySelector('#invoice-detail-payment').scrollIntoView({behavior: "smooth"})
        })
      },
      getStatusAlertColor (invoiceStatus) {
        switch (invoiceStatus) {
          case sellInvoiceStatusEnum.OPEN:
            return 'alert-warning'
          case sellInvoiceStatusEnum.CLOSED:
            return 'alert-muted'
          case sellInvoiceStatusEnum.PAID:
            return 'alert-success'
          case sellInvoiceStatusEnum.VOIDED:
            return 'alert-danger'
          default:
            return 'alert-muted'
        }
      },
      getStatusLocal (invoiceStatus) {
        return sellInvoiceStatusEnum.getLocal(invoiceStatus).toUpperCase()
      },
      cancelSellInvoice () {
        const showSuccessMessage = (invoice) => {
          const title = 'Invoice telah dibatalkan!'
          const content = `Nomor invoice: ${invoice.transactionNo}`

          alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const action = () => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_INVOICES_CANCEL}/${this.model.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              showSuccessMessage(response.data.invoice)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }

        const content = 'Apakah kamu yakin ingin membatalkan invoice ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Batalkan Invoice', 'Tidak', action)
      },
      cancelSellInvoicePayment () {
        const showSuccessMessage = (payment) => {
          const title = 'Pembayaran invoice telah dibatalkan!'
          const content = `Nomor pembayaran: ${payment.transactionNo}`

          alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const action = () => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_INVOICES_PAYMENTS_CANCEL}/${this.model.activePayment.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              showSuccessMessage(response.data.payment)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }

        const content = 'Apakah kamu yakin ingin membatalkan pembayaran untuk invoice ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Batalkan Pembayaran', 'Tidak', action)
      },
      cancelPaymentAndSellInvoice () {
        let paymentResult = {}
        let invoiceResult = {}

        const showSuccessMessage = () => {
          const title = 'Pembayaran beserta invoice telah dibatalkan!'
          const contentHtml = `Nomor pembayaran: ${paymentResult.transactionNo}<br/>Nomor invoice: ${invoiceResult.transactionNo}`

          alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const cancelInvoiceAction = () => new Promise((resolve, reject) => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_INVOICES_CANCEL}/${this.model.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                reject()
                return
              }

              invoiceResult = response.data.invoice
              
              this.isSaveLoading = false
              resolve()
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
              reject()
            })
        })

        const cancelPaymentAction = () => new Promise((resolve, reject) => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_INVOICES_PAYMENTS_CANCEL}/${this.model.activePayment.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                reject()
                return
              }

              paymentResult = response.data.payment

              this.isSaveLoading = false
              resolve()
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
              reject()
            })
        })

        const action = () => {
          cancelPaymentAction().then(() => {
            cancelInvoiceAction().then(() => {
              showSuccessMessage()
            })
          })
        }

        const content = 'Apakah kamu yakin ingin membatalkan pembayaran beserta invoice ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Batalkan Pembayaran & Invoice', 'Tidak', action)
      },
      recalculate () {
        const showSuccessMessage = (invoice) => {
          const title = 'Invoice telah dikalkulasi ulang!'
          const content = `Nomor invoice: ${invoice.transactionNo}`

          alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const action = () => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_INVOICES_RECALCULATE}/${this.model.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              showSuccessMessage(response.data.invoice)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }

        const content = 'Apakah kamu yakin ingin melakukan kalkulasi ulang untuk invoice ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Kalkulasi Ulang', 'Tidak', action)
      },
      showPaymentSuccessMessage (invoicePayment) {
        const title = 'Pembayaran berhasil dibuat!'
        const content = `Nomor pembayaran: ${invoicePayment.transactionNo}`
        const onConfirm = () => { this.$router.replace(`${urlConstant.WEB_URL_INVOICES_VIEW}/${base64url.encode(this.model.id.toString())}?print=true`) }
        const onCancel = () => { this.$router.replace(urlConstant.WEB_URL_INVOICES) }

        alertHelper.confirm(title, content, alertHelper.typeEnums.success, 'Cetak', 'Daftar Penjualan', onConfirm, onCancel)
      },
      getCustomerDescription (customer, searchQuery) {
        const regex = new RegExp(searchQuery, 'i')

        const defaultResult = customer.identifierNo && customer.identifierNo !== '0'
          ? `<i class="fa fa-id-card-o"></i>&nbsp;${filterHelper.highlight(customer.identifierNo, searchQuery)}`
          : ''

        if (regex.test(customer.identifierNo)) {
          return defaultResult
        }

        if (regex.test(customer.phoneNumbersStr)) {
          return `<i class="fa fa-phone"></i>&nbsp;${filterHelper.highlight(customer.phoneNumbersStr, searchQuery)}`
        }

        if (regex.test(customer.email)) {
          return `<i class="fa fa-envelope-o"></i>&nbsp;${filterHelper.highlight(customer.email, searchQuery)}`
        }

        return defaultResult
      },
      openAddCustomerModal () {
        this.$refs.customerDropdown.blur()
        this.modals.addCustomer = true
      },
      closeAddCustomerModal (customer) {
        if (customer) {
          this.selects.customers = [customer]
          this.model.customerId = customer.id
        }

        this.modals.addCustomer = false
      },
      goToProductDetail (productId) {
        this.$router.push(`${urlConstant.WEB_URL_PRODUCTS_VIEW}/${base64url.encode(productId.toString())}`)
      },
      print () {
        this.isPrinting = true
        this.printIndex++

        this.$nextTick(() => {
          this.$refs.printFrame.onload = () => { 
            this.isPrinting = false
          }
        })
      },
    },
    computed: {
      isLoading () {
        return this.isInvoiceLoading || this.isReferencesLoading || this.isSaveLoading || this.isPrinting
      },
      itemsHaveSameTax () {
        const items = this.model.items.filter(i => !!i.productId)
        return this.model.taxGroups.length === 1 && 
               this.model.taxGroups[0].count === items.length
      },
      canCancelSellInvoicePayment () {
        const userRole = this.$store.getters.getUserRole
        const authorized = userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE.includes(userRole)

        return this.isView &&
               this.model.status === sellInvoiceStatusEnum.PAID && 
               this.model.activePayment.status === sellInvoicePaymentStatusEnum.PAID &&
               authorized &&
               this.isShowPayment
      },
      canCancelSellInvoice () {
        const userRole = this.$store.getters.getUserRole
        const authorized = userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE.includes(userRole)

        return this.isView &&
               (this.model.status === sellInvoiceStatusEnum.OPEN || this.model.status === sellInvoiceStatusEnum.VOIDED) && 
               authorized &&
               !this.isShowPayment
      },
      canCreatePayment () {
        return this.isView &&
               (this.model.status === sellInvoiceStatusEnum.OPEN || this.model.status === sellInvoiceStatusEnum.VOIDED) && 
               !this.isShowPayment
      },
      canRecalculate () {
        const userRole = this.$store.getters.getUserRole
        const authorized = userRole === userRoleEnum.SUPERADMIN

        return this.isView &&
               this.model.status === sellInvoiceStatusEnum.PAID && 
               this.model.activePayment.status === sellInvoicePaymentStatusEnum.PAID &&
               authorized &&
               this.isShowPayment
      },
      canPrint () {
        return this.isView &&
               this.model.status === sellInvoiceStatusEnum.PAID &&
               this.isShowPayment
      },
      canBack () {
        return this.isView &&
               (this.model.status === sellInvoiceStatusEnum.PAID || !this.isShowPayment)
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
    destroyed () {
      for (const prop in this.intervals) {
        clearInterval(this.intervals[prop])
      }
    },
  }
</script>
<style scoped lang="scss">
  .section-items {
    table {
      tbody {
        tr {
          .cell-edit, .cell-edit-info {
            display: none;
          }
          .cell-error {
            font-size: 80%;
            padding-left: 0;
            text-align: left;
            display: block;
          }
        }
      }
    }

    &.section-items-edit-mode {
      table {
        tbody {
          tr {
            &.current-row {
              .cell-edit {
                display: inline;
              }
              .cell-view, .cell-edit-info {
                font-size: 80%;
                padding-left: 11px;
                text-align: left;
                display: block;
              }
              .cell-error {
                padding-left: 11px;
              }
              .cell-index {
                margin-top: 8px;
              }
            }

            &:last-child {
              .cell-edit {
                display: inline;
              }
              .cell-view {
                display: none;
              }
              .cell-index {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .print-frame {
    width: 0;
    height: 0;
    position: absolute;

    // ----- DEBUG
    // width: 100%;
    // height: 300mm;
    // position: static;
  }
</style>
