<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Laporan Penjualan Selektif</h5>
    </template>
    <template slot="default">
      <div class="row">
        
        <div class="col-12">
          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage">Kembali</p-button>
            <download-excel
              :fetch="fetchInvoicesCsv"
              :fields="invoiceCsvSchema"
              type="csv"
              :escape-csv="false"
              :name="downloadCsvFileName + '.csv'">
              <p-button type="primary" round><i class="fa fa-cloud-download"></i>&nbsp;Unduh CSV</p-button>
            </download-excel>
          </div>
        </div>

        <div class="col-12 kf-table-filter-fields">
          <form class="my-2" v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <div class="row">
              <div class="col-md-6">
                <fg-input label="Periode">
                  <el-date-picker
                    v-model="searchModel.periodDateRange"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="Tanggal awal"
                    end-placeholder="Tanggal akhir"
                    format="dd-MM-yyyy"
                    :picker-options="dateRangePickerOptions"
                    :clearable="false">
                  </el-date-picker>
                </fg-input>
              </div>
              <div class="col-md-3">
                <fg-input label="Status">
                  <el-select class="kf select-default" v-model="searchModel.status" filterable>
                    <el-option v-for="option in selects.invoiceStatuses"
                              class="kf select-default"
                              :value="option.id"
                              :label="option.name"
                              :key="option.id">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div class="d-flex justify-content-md-end justify-content-center">
              <p-button outline round @click="resetFilter">Reset</p-button>
              <p-button round @click="applyFilter">Terapkan</p-button>
            </div>
          </form>
        </div>

        <div class="col-12 mt-3" v-if="summaryCards.length">
          <div class="row">
            <div class="col-xl-3 col-sm-6" v-for="card in summaryCards" v-bind:key="card.title">
              <stats-card :type="card.type"
                          :icon="card.icon"
                          :small-title="card.title"
                          :title="card.value"
                          :title-class="card.valueClass"
                          :small-title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 16}"
                          :title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 28}"
                          card-class="kf-card-shadow-low mb-3">
              </stats-card>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <el-table :data="invoices" 
                    header-row-class-name="text-primary"
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column label="Tanggal" width="195">
              <template slot-scope="scope">
                <div>{{scope.row.createdAt | dateTimeFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Nomor" width="180">
              <template slot-scope="scope">
                <div>
                  <a href="javascript:void(0);" @click.prevent="goToInvoiceDetail(scope.row.id)">{{scope.row.transactionNo}}</a>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="customerName" label="Pelanggan" min-width="200"></el-table-column>
            <el-table-column label="Total" align="right" width="145">
              <template slot-scope="scope">
                <div>{{scope.row.totalAmount | currencyFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Status" align="center" width="190">
              <template slot-scope="scope">
                <div class="text-uppercase" :class="getStatusTextColor(scope.row.status)">{{getStatusLocal(scope.row.status)}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import moment from 'moment'
  import JsonExcel from "vue-json-excel"
  import ResizeText from 'vue-resize-text'
  import { Card, Pagination } from '../../UIComponents'
  import StatsCard from '../../UIComponents/Cards/StatsCard'
  import { Table, TableColumn, Loading, Tooltip, Select, Option, DatePicker } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import * as dateTimeConstant from '../../../constants/dateTimeConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import * as sellInvoiceStatusEnum from '../../../enums/sellInvoiceStatusEnum'
  import arrayHelper from '../../../helpers/arrayHelper'
  import datePickerHelper from '../../../helpers/datePickerHelper'
  import * as sellInvoiceSearchQueryOrderEnum from '../../../enums/sellInvoiceSearchQueryOrderEnum'
  import * as routerConstant from '../../../constants/routerConstant'
  import alertHelper from '../../../helpers/alertHelper'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  const initialPagination = {
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    totalCount: 0,
    pageSizeOptions: pageSizeOptions
  }

  const initialSearchModel = {
    periodDateRange: null,
    status: null,
  }

  export default {
    components: {
      Card,
      StatsCard,
      [Tooltip.name]: Tooltip,
      [Pagination.name]: Pagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      ['downloadExcel']: JsonExcel
    },
    directives: {
      ResizeText
    },
    data () {
      return {
        hasPrevRoute: false,
        isNavigatingBack: false,
        isInvoicesLoading: false,
        isReferencesLoading: false,
        isReferencesLoaded: false,
        invoices: [],
        invoiceCsvSchema: {
          'No.': 'number',
          'Tanggal': 'createdAtCsv',
          'Nomor': 'transactionNo',
          'Pelanggan': 'customerName',
          'Total': 'totalAmount',
          'Status': 'statusCsv',
        },
        searchModel: _.cloneDeep(initialSearchModel),
        selects: {
          invoiceStatuses: [],
        },
        summaryCards: [],
        pagination: _.cloneDeep(initialPagination),
        dateRangePickerOptions: datePickerHelper.getReportDateRangePickerOptions(),
      }
    },
    created() {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setPagination()
        this.setSearchModel()

        if (!this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }

        this.fetchData()
      },
      fetchData (isJsonCsvOutput) {
        return new Promise((resolve, reject) => {
          this.isInvoicesLoading = true

          // Clone deep so we don't mutate the original object
          const searchModel = _.cloneDeep(this.searchModel)

          // Update start and end time
          if (searchModel.periodDateRange && searchModel.periodDateRange[0] && searchModel.periodDateRange[1]) {
            searchModel.startDate = searchModel.periodDateRange[0].startOf('day').format()
            searchModel.endDate = searchModel.periodDateRange[1].endOf('day').format()
          }
          delete searchModel.periodDateRange
          
          const query = {
            pageSize: this.pagination.pageSize,
            pageNumber: this.pagination.pageNumber,
            order: sellInvoiceSearchQueryOrderEnum.CreatedAtAsc,

            ...searchModel
          }

          if (isJsonCsvOutput) {
            query.pageSize = null
            query.pageNumber = null
          }
          
          this.axios.get(urlConstant.API_URL_REPORTS_SALES_ALT, { params: query })
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isInvoicesLoading = false
                reject()
                return
              }

              if (isJsonCsvOutput) {
                this.isInvoicesLoading = false
                resolve(response.data.invoices)
                return
              }
              
              this.invoices = response.data.invoices
              this.pagination.totalCount = response.data.totalCount
              
              // Update summary cards
              this.summaryCards = [
                {
                  type: 'warning',
                  icon: 'nc-icon nc-cart-simple',
                  title: 'Jumlah Transaksi',
                  value: filterHelper.numberFormat(response.data.totalCount),
                }
              ]

              if (searchModel.status === sellInvoiceStatusEnum.PAID) {
                this.summaryCards = this.summaryCards.concat([
                  {
                    type: 'primary',
                    icon: 'nc-icon nc-credit-card',
                    title: 'Total Penjualan',
                    value: filterHelper.currencyFormat(response.data.totalAmount),
                  },
                  {
                    type: 'primary',
                    icon: 'nc-icon nc-money-coins',
                    title: 'Laba Pnj. Kotor',
                    value: filterHelper.currencyFormat(response.data.grossProfit),
                  },
                  {
                    type: 'danger',
                    icon: 'nc-icon nc-bank',
                    title: 'Pajak Penjualan',
                    value: filterHelper.currencyFormat(response.data.taxAmount),
                  },
                  {
                    type: 'success',
                    icon: 'nc-icon nc-money-coins',
                    title: 'Laba Pnj. Bersih',
                    value: filterHelper.currencyFormat(response.data.netProfit),
                  },
                ])
              }
              else if (searchModel.status === sellInvoiceStatusEnum.OPEN) {
                this.summaryCards = this.summaryCards.concat([
                  {
                    type: 'primary',
                    icon: 'nc-icon nc-book-bookmark',
                    title: 'Total Piutang',
                    value: filterHelper.currencyFormat(response.data.totalAmount),
                  },
                ])
              }

              this.isInvoicesLoading = false
              resolve()
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isInvoicesLoading = false
              reject()
            })
        })
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_INVOICES_STATUSES),
        ])
          .then(responses => {
            const pInvoiceStatusesRes = responses[0]

            // Invoice statuses
            let invoiceStatuses = (pInvoiceStatusesRes.data.isSuccess ? pInvoiceStatusesRes.data.sellInvoiceStatuses : [])
              .map(s => {
                return {
                  id: s, 
                  name: this.getStatusLocal(s)
                }
              })
            invoiceStatuses.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.invoiceStatuses = invoiceStatuses

            this.isReferencesLoading = false
          })
          .catch(() => {
            this.isReferencesLoading = false
          })
      },
      async fetchInvoicesCsv () {
        let invoices = []

        try {
          invoices = await this.fetchData(true)
        } 
        catch {
          return []
        }

        if (!invoices || !invoices.length) {
          alertHelper.basic('Data tidak tersedia!', null, alertHelper.typeEnums.warning)
          return []
        }

        // Add counter and format properties
        let counter = 1
        for (const invoice of invoices) {
          invoice.number = counter++
          invoice.createdAtCsv = moment(invoice.createdAt).format(dateTimeConstant.CSV_DATE_TIME_FORMAT)
          invoice.statusCsv = this.getStatusLocal(invoice.status)
        }
        
        return invoices;
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)
        
        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }

        if (!query.startDate || !query.endDate) {
          query.startDate = moment().startOf('year').startOf('day').format(dateTimeConstant.MOMENT_DATE_FORMAT),
          query.endDate = moment().endOf('day').format(dateTimeConstant.MOMENT_DATE_FORMAT)
          isChanged = true
        }

        if (!query.status) {
          query.status = sellInvoiceStatusEnum.PAID
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,
        }

        const searchModel = _.cloneDeep(this.searchModel)

        for (const prop in searchModel) {
          if (searchModel[prop]) {
            query[prop] = searchModel[prop]
          }
        }

        // Period date range
        if (query.periodDateRange && query.periodDateRange[0] && query.periodDateRange[1]) {
          query.startDate = moment(query.periodDateRange[0]).format(dateTimeConstant.MOMENT_DATE_FORMAT)
          query.endDate = moment(query.periodDateRange[1]).format(dateTimeConstant.MOMENT_DATE_FORMAT)
        }
        delete query.periodDateRange

        this.$router.push({ path: this.$route.path, query: query })
      },
      setSearchModel () {
        for (const prop in this.searchModel) {
          if (this.$route.query[prop]) {
            this.searchModel[prop] = this.$route.query[prop]
          }

          if (this.searchModel[prop] === '') {
            this.searchModel[prop] = null
          }
        }

        // Period date range
        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.searchModel.periodDateRange = [
            moment(this.$route.query.startDate, dateTimeConstant.MOMENT_DATE_FORMAT),
            moment(this.$route.query.endDate, dateTimeConstant.MOMENT_DATE_FORMAT)
          ]
        }
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }
        
        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      changePage () {
        this.applyRoute()
      },
      getStatusTextColor (invoiceStatus) {
        switch (invoiceStatus) {
          case sellInvoiceStatusEnum.OPEN:
            return 'text-warning'
          case sellInvoiceStatusEnum.CLOSED:
            return 'text-muted'
          case sellInvoiceStatusEnum.PAID:
            return 'text-success'
          case sellInvoiceStatusEnum.VOIDED:
            return 'text-danger'
          default:
            return 'text-muted'
        }
      },
      getStatusLocal (invoiceStatus) {
        return sellInvoiceStatusEnum.getLocal(invoiceStatus).toUpperCase()
      },
      resetFilter () {
        this.pagination = _.cloneDeep(initialPagination)
        this.searchModel = _.cloneDeep(initialSearchModel)

        this.applyRoute()
      },
      applyFilter () {
        const totalCount = this.pagination.totalCount;
        this.pagination = _.cloneDeep(initialPagination)
        this.pagination.totalCount = totalCount

        this.applyRoute()
      },
      goToInvoiceDetail (invoiceId) {
        this.$router.push(`${urlConstant.WEB_URL_INVOICES_VIEW}/${base64url.encode(invoiceId.toString())}`)
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.isNavigatingBack = true
          this.$router.go(-1)
        }
        else {
          this.$router.replace(`${urlConstant.WEB_URL_REPORTS}?tab=sales`)
        }
      },
    },
    computed: {
      downloadCsvFileName () {
        const prefix = 'Laporan Penjualan Selektif'
        
        if (!this.searchModel.periodDateRange || this.searchModel.periodDateRange.length !== 2) {
          return prefix
        }
        
        const startDate = moment(this.searchModel.periodDateRange[0]).format(dateTimeConstant.MOMENT_DATE_FORMAT)
        const endDate = moment(this.searchModel.periodDateRange[1]).format(dateTimeConstant.MOMENT_DATE_FORMAT)

        return `${prefix} ${startDate} - ${endDate}`
      },
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      },
      isLoading () {
        return this.isInvoicesLoading || this.isReferencesLoading
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
    beforeRouteUpdate (to, from, next) {
      if (this.hasPrevRoute && this.isNavigatingBack && to.name === from.name) {
        this.$router.go(-1)
        next(new Error(routerConstant.REDIRECT_TO_SELF))
        return
      }

      next()
    },
  }
</script>
<style scoped lang="scss">
</style>
