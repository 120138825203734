<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Buat Transfer Produk</h5>
          <h5 class="card-title" v-if="isView">Detail Transfer Produk</h5>
          <h5 class="card-title" v-if="isEdit">Ubah Transfer Produk</h5>
        </template>
        <template slot="default">
          <h6>Detail</h6>
          <div class="pt-1" v-if="isView">
            <div class="alert" :class="getStatusAlertColor(model.status)">
              Status: <strong class="text-uppercase">{{getStatusLocal(model.status)}}</strong>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage()" v-if="isView">Kembali</p-button>
          </div>

          <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <fg-input :label="getStoreFieldLabel(model.direction)" 
                            required 
                            :disabled="isView"
                            :error="getError(getStoreFieldLabel(model.direction))">
                    <el-select class="kf select-default" 
                              v-model="model.store" 
                              v-validate="'required'"
                              :disabled="isView"
                              :data-vv-name="getStoreFieldLabel(model.direction)"
                              filterable>
                      <el-option v-for="option in selects.domainSettings"
                                class="kf select-default"
                                :value="option.host"
                                :label="option.name"
                                :key="option.host">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Kode transfer" 
                            v-model="model.code"
                            v-if="isView || isEdit"
                            disabled
                            required></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Tanggal transfer" 
                            v-if="isView || isEdit"
                            :value="model.transferDate | dateTimeFormat" 
                            disabled></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Tanggal diterima" 
                            v-if="isView || isEdit"
                            :value="model.receivedDate | dateTimeFormat" 
                            disabled></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Catatan" :disabled="isView">
                    <textarea class="form-control" 
                              rows="3"
                              v-model="model.note" 
                              :disabled="isView"></textarea>
                  </fg-input>
                </div>
              </div>

              <h6 class="mt-3">Produk</h6>

              <div class="section-items" :class="{'section-items-edit-mode': isNew || isEdit}">
                <el-table :data="model.items" 
                          header-row-class-name="text-primary" 
                          class="table-compact"
                          ref="itemsTable"
                          @current-change="onTableSelectionChange"
                          :highlight-current-row="isNew || isEdit">
                  <el-table-column label="No." type="index" width="50">
                    <template slot-scope="scope">
                      <div class="cell-index">{{getRowNumber(scope)}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Kode" width="190">
                    <template slot-scope="scope">
                      <div v-if="!scope.row.originProductId">
                        <fg-input required 
                                  class="mb-0"
                                  :disabled="isView">
                          <el-select class="kf select-default" 
                                      v-model="scope.row.originProductId"
                                      @change="onProductChange(scope.row, scope.$index)"
                                      :disabled="isView"
                                      placeholder="Tambah produk"
                                      filterable
                                      remote
                                      :remote-method="fetchProducts"
                                      :loading="isFetchingProducts">
                            <el-option v-for="option in selects.products"
                                        class="kf select-default"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                              <div class="d-flex justify-content-between">
                                <div>{{ option.code }}</div>
                                <div class="ml-3 small text-muted">{{ option.name }}</div>
                              </div>
                            </el-option>
                          </el-select>
                        </fg-input>
                      </div>
                      <div v-else>{{scope.row.productCode}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="productName" label="Nama" min-width="220"></el-table-column>
                  <el-table-column prop="productCategoryName" label="Kategori" width="150"></el-table-column>
                  <el-table-column label="Karat" width="110">
                    <template slot-scope="scope">
                      <el-tooltip v-if="!!scope.row.originProductId" placement="top" :content="caratTooltip(scope.row)">
                        <div>
                          <span>{{scope.row.productCarat}}k</span>
                          <span v-if="scope.row.productCaratPercentage"> / {{scope.row.productCaratPercentage}}%</span>
                        </div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column label="Berat" width="105">
                    <template slot-scope="scope">
                      <el-tooltip v-if="!!scope.row.originProductId" placement="top" :content="weightTooltip(scope.row)">
                        <div>
                          <span>{{scope.row.productWeight}}gr</span>
                          <span v-if="scope.row.productSize"> / {{scope.row.productSize}}</span>
                        </div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column label="Stok" width="115">
                    <template slot-scope="scope">
                      <div v-if="!!scope.row.originProductId" class="cell-edit">
                        <fg-input type="number" 
                                  onwheel="this.blur()"
                                  v-model.number="scope.row.productUnitAmount"
                                  :disabled="isView"
                                  v-validate="`required|min_value:1|max_value:${scope.row.productMaxUnitAmount}`"
                                  required
                                  :data-vv-name="`Stok-${scope.$index}`"
                                  data-vv-as="Stok"
                                  :error="getError(`Stok-${scope.$index}`)"
                                  hide-error-message
                                  class="mb-0">
                          <template slot="addonRight">{{scope.row.productUnitName}}</template>
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.productUnitAmount | numberFormat}}{{(scope.row.productUnitAmount || scope.row.productUnitAmount === 0) && scope.row.productUnitName ? ` ${scope.row.productUnitName}` : ''}}</div>
                      <div class="cell-error text-danger" v-if="(isNew || isEdit) && getError(`Stok-${scope.$index}`)">
                        {{getError(`Stok-${scope.$index}`)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column class-name="action-buttons td-actions" align="right" width="50" v-if="isNew || isEdit">
                    <template slot-scope="scope">
                      <div v-if="!!scope.row.originProductId">
                        <el-tooltip placement="top" content="Keluarkan Produk">
                          <p-button type="danger" size="sm" icon class="rounded" @click.stop="removeItem(scope.row, scope.$index)">
                            <i class="fa fa-times"></i>
                          </p-button>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </form>

            <div class="d-flex flex-wrap justify-content-md-end justify-content-center mt-3">
              <p-button outline round @click="goToPrevPage()" v-if="isView">Kembali</p-button>
              <p-button outline round @click="goToPrevPage()" v-if="isNew || isEdit">Batal</p-button>
              <p-button type="primary" round @click="validate()" v-if="isNew">Buat Transfer Produk</p-button>
              <p-button type="primary" round @click="validate()" v-if="isEdit">Simpan Perubahan</p-button>
              <p-button type="primary" round @click="receiveTransfer()" v-if="canReceiveTransfer">Konfirmasi Terima</p-button>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import { Select, Option, Loading, Table, TableColumn, Tooltip } from 'element-ui'
  import { Card } from '../../UIComponents'
  import filterHelper from '../../../helpers/filterHelper'
  import * as productTransferStatusEnum from '../../../enums/productTransferStatusEnum'
  import * as urlConstant from '../../../constants/urlConstant'
  import arrayHelper from '../../../helpers/arrayHelper'
  import alertHelper from '../../../helpers/alertHelper'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'
  import * as productTransferDirectionEnum from '../../../enums/productTransferDirectionEnum'

  Vue.use(Loading)

  const initialProductTransferItem = {
    id: null,
    originProductId: null,
    productName: '',
    productCode: '',
    productDescription: '',
    productWeight: null,
    productSize: null,
    productCarat: null,
    productCaratPercentage: null,
    productExchangePercentage: null,
    productCategoryCode: '',
    productCategoryName: '',
    productMaterialCode: '',
    productMaterialName: '',
    productIsCustomOrder: false,
    productPurchasePrice: null,
    productSellPrice: null,
    productUnitName: '',
    productUnitDescription: '',
    productUnitAmount: null,
    productMaxUnitAmount: null,
  }

  export default {
    components: {
      Card,
      [Option.name]: Option,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
    },
    data () {
      return {
        isNew: false,
        isView: false,
        isEdit: false,
        isProductTransferLoading: false,
        isSaveLoading: false,
        isReferencesLoading: false,
        isFetchingProducts: false,
        isReferencesLoaded: false,
        isTrayProductsLoading: false,
        selectedItem: null,
        hasPrevRoute: false,
        model: {
          id: null,
          code: '',
          store: '',
          note: '',
          status: '',
          direction: '',
          transferDate: null,
          receivedDate: null,
          items: [_.cloneDeep(initialProductTransferItem)],
          itemsCount: null,
        },
        selects: {
          domainSettings: [],
          products: [],
          initialProducts: [],
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false
        this.isEdit = false

        if (!this.$route.params.productTransferSlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else {
          this.isNew = true
        }

        if (!this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }

        if (this.isNew) {
          return
        }

        const productTransferId = base64url.decode(this.$route.params.productTransferSlug)

        this.isProductTransferLoading = true
        this.axios.get(`${urlConstant.API_URL_PRODUCT_TRANSFERS}/${productTransferId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isProductTransferLoading = false
              return
            }

            if (!response.data.productTransfer) {
              alertHelper.basic('Transfer produk tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              this.isProductTransferLoading = false
              return
            }

            this.initialModel = _.cloneDeep(response.data.productTransfer)
            this.model = response.data.productTransfer

            if (this.isEdit) {
              this.model.items.unshift(_.cloneDeep(initialProductTransferItem))
            }

            this.isProductTransferLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isProductTransferLoading = false
          })
      },
      getStatusAlertColor (status) {
        switch (status) {
          case productTransferStatusEnum.CREATED:
          case productTransferStatusEnum.CANCELLED:
            return 'alert-muted'
          case productTransferStatusEnum.IN_PROGRESS:
            return 'alert-warning'
          case productTransferStatusEnum.RECEIVED:
            return 'alert-success'
          case productTransferStatusEnum.RETURNED:
            return 'alert-danger'
          default:
            return 'alert-muted'
        }
      },
      getStatusLocal (status) {
        return productTransferStatusEnum.getLocal(status).toUpperCase()
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_PRODUCT_TRANSFERS)
        }
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_DOMAIN_LIST)
        ])
          .then(responses => {
            const pDomainRes = responses[0]
            
            // Domain settings
            const currentDomainSettings = this.$store.getters.getDomainSettings
            let domainSettings = pDomainRes.data.isSuccess ? pDomainRes.data.domainSettingsList : []
            domainSettings = domainSettings.filter(x => x.host !== currentDomainSettings.host)
            domainSettings.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.domainSettings = domainSettings

            this.isReferencesLoading = false;
          })
          .catch(() => {
            this.isReferencesLoading = false;
          })
      },
      onTableSelectionChange (value) {
        this.selectedItem = value
      },
      getRowNumber (scope) {
        return this.isView ? scope.$index + 1 : scope.$index
      },
      fetchProducts (query) {
        if (!query) {
          this.selects.products = _.cloneDeep(this.selects.initialProducts)
          return
        }
        
        const params = { 
          code: query,
          pageSize: 10,
          pageNumber: 1
        }
        
        this.isFetchingProducts = true
        this.axios.get(urlConstant.API_URL_PRODUCTS_STOCKS, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              this.selects.products = []
              this.isFetchingProducts = false
              return
            }
            
            this.selects.products = response.data.products
            this.isFetchingProducts = false
          })
          .catch(() => {
            this.selects.products = []
            this.isFetchingProducts = false
          })
      },
      onProductChange (transferItem, rowIndex) {
        const product = transferItem.originProductId 
          ? this.selects.products.find(p => p.id === transferItem.originProductId) 
          : null

        if (product && this.model.items.filter(i => product.id === i.originProductId).length > 1) {
          const title = 'Gagal menambah produk!'
          const contentHtml = `Produk <strong>${product.code} - ${product.name}</strong> sudah ada di dalam daftar transfer ini.`

          alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.warning)

          transferItem.originProductId = null
          this.selects.products = []
          return
        }

        const successAction = () => {
          transferItem.productName = product ? product.name : ''
          transferItem.productCode = product ? product.code : ''
          transferItem.productDescription = product ? product.description : ''
          transferItem.productWeight = product ? product.weight : null
          transferItem.productSize = product ? product.size : null
          transferItem.productCarat = product ? product.carat : null
          transferItem.productCaratPercentage = product ? product.caratPercentage : null
          transferItem.productExchangePercentage = product ? product.exchangePercentage : null
          transferItem.productCategoryName = product ? product.categoryName : ''
          transferItem.productMaterialName = product ? product.materialName : ''
          transferItem.productIsCustomOrder = product ? product.isCustomOrder : false
          transferItem.productPurchasePrice = product ? product.purchasePrice : null
          transferItem.productSellPrice = product ? product.sellPrice : null
          transferItem.productUnitName = product ? product.unitName : ''
          transferItem.productMaxUnitAmount = product ? product.unitAmount : null
          transferItem.productUnitAmount = 1

          if (rowIndex === 0) {
            this.model.items.unshift(_.cloneDeep(initialProductTransferItem))
            this.errors.clear()
          }
          
          this.selects.products = []
        }
      
        // Check if the product is in the tray
        this.isTrayProductsLoading = true
        this.axios.get(urlConstant.API_URL_PRODUCTS_TRAYS, { params: { 
          code: product.code,
          pageSize: 1,
          pageNumber: 1
        }})
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isTrayProductsLoading = false
              return
            }

            if (response.data.totalCount > 0) {
              const firstTrayProduct = response.data.trayProducts[0]

              const title = 'Gagal menambah produk!'
              const contentHtml = `Produk <strong>${product.code} - ${product.name}</strong> sedang terdaftar di dalam baki <strong>${firstTrayProduct.trayCode} - ${firstTrayProduct.trayName}</strong>.`

              alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.warning)

              transferItem.originProductId = null
              this.selects.products = []
            } else {
              successAction()
            }

            this.isTrayProductsLoading = false
          })
          .catch(() => {
            errorHandlerHelper.handleGeneralApiError()
            this.isTrayProductsLoading = false
          })
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.saveProductTransfer()
        })
      },
      saveProductTransfer () {
        this.isSaveLoading = true

        // Remove empty objects
        const modelCopy = _.cloneDeep(this.model)
        modelCopy.items = modelCopy.items.filter(i => i.originProductId)

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_PRODUCT_TRANSFERS, modelCopy)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.productTransfer)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        } else {
          // Product transfer is not editable for the moment
        }
      },
      showSaveSuccessMessage (transfer) {
        const title = this.isNew ? 'Transfer produk berhasil dibuat!' : 'Transfer produk berhasil diubah!'
        const content = `Kode transfer: ${transfer.code}`

        alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
      },
      showSaveFailedMessage (apiResponse) {
        if (!apiResponse || !apiResponse.apiError) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_PRODUCTTRANSFER_INVALID_PRODUCT_STOCK_QUANTITY) {
          showAlert('Jumlah stok untuk produk yang akan ditransfer melebihi stok produk yang tersedia, mohon cek kembali jumlah stok produk yang akan dimasukkan ke daftar transfer.')
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      caratTooltip (row) {
        if (!row || !row.productCarat) {
          return ''
        }

        let output = `Karat: ${row.productCarat}k`

        if (row.productCaratPercentage) {
          output += ` / Persentase Karat: ${row.productCaratPercentage}%`
        }

        return output
      },
      weightTooltip (row) {
        if (!row || !row.productWeight) {
          return ''
        }

        let output = `Berat: ${row.productWeight}gr`

        if (row.productSize) {
          output += ` / Ukuran: ${row.productSize}`
        }

        return output
      },
      removeItem (row, rowIndex) {
        const contentHtml = `Apakah kamu yakin ingin mengeluarkan produk <strong>${row.productName}</strong> dari daftar transfer?`
        const onConfirm = () => {
          this.model.items.splice(rowIndex, 1)
          this.errors.clear()
        }

        alertHelper.confirmHtml(null, contentHtml, alertHelper.typeEnums.warning, 'Keluarkan Produk', 'Batal', onConfirm)
      },
      getStoreFieldLabel (direction) {
        switch (direction) {
          case productTransferDirectionEnum.IN:
            return 'Transfer dari'
          default:
            return 'Transfer ke'
        }
      },
      receiveTransfer () {
        const showSuccessMessage = (productTransfer) => {
          const title = 'Transfer produk diterima!'
          const content = `Semua produk pada transfer ${productTransfer.code} telah ditambahkan ke stok.`

          alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const action = () => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_PRODUCT_TRANSFERS_RECEIVE}/${this.model.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              showSuccessMessage(response.data.productTransfer)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }

        const title = 'Konfirmasi Terima Produk'
        const content = 'Mohon periksa dengan seksama apakah produk yang tercatat sudah sesuai dengan produk yang diterima.'

        alertHelper.confirm(title, content, alertHelper.typeEnums.warning, 'Produk Sesuai', 'Batal', action)
      },
    },
    computed: {
      isLoading () {
        return this.isProductTransferLoading || this.isSaveLoading
      },
      canReceiveTransfer () {
        return this.isView && 
               this.model.status === productTransferStatusEnum.IN_PROGRESS && 
               this.model.direction === productTransferDirectionEnum.IN
      },
    },
    filters: {
      ...filterHelper.spread
    },
  }
</script>
<style scoped lang="scss">
  .section-items {
    table {
      tbody {
        tr {
          .cell-edit, .cell-edit-info {
            display: none;
          }
          .cell-error {
            font-size: 80%;
            padding-left: 0;
            text-align: left;
            display: block;
          }
        }
      }
    }

    &.section-items-edit-mode {
      table {
        tbody {
          tr {
            &.current-row {
              .cell-edit {
                display: inline;
              }
              .cell-view, .cell-edit-info {
                display: none;
              }
              .cell-error {
                padding-left: 11px;
              }
              .cell-index {
                margin-top: 8px;
              }
            }

            &:first-child {
              .cell-edit {
                display: inline;
              }
              .cell-view {
                display: none;
              }
              .cell-index {
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>
