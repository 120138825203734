<template>
  <div>
    <card>
      <template slot="header">
        <h5 class="card-title mb-1">Buat Laporan Penjualan Selektif</h5>
        <h6 v-if="model.year && model.month">Periode {{model.year}}-{{model.month}}</h6>
      </template>
      <template slot="default">
        <div class="row">

          <div class="col-12">
            <div class="alert" :class="getReportStatusColor(model.status)">
              Status: <strong class="text-uppercase">{{getReportStatusLocal(model.status)}}</strong>
            </div>
          </div>

          <div class="col-12">
            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage()">Kembali</p-button>
              <p-button round type="warning" v-if="canSaveDraftReport" @click="saveDraftReport()">
                <i slot="label" class="fa fa-floppy-o"></i>
                &nbsp;Simpan Draft
              </p-button>
              <p-button round type="primary" v-if="canSaveSubmitReport" @click="saveSubmitReport()">
                <i slot="label" class="fa fa-paper-plane-o"></i>
                &nbsp;Submit
              </p-button>
            </div>
          </div>

          <div class="col-12 pt-3 kf-cards-sticky" ref="stickyCards">
            <div class="row">
              <div class="col-xl-3 col-sm-6">
                <stats-card type="warning"
                            icon="nc-icon nc-cart-simple"
                            small-title="Jumlah Transaksi"
                            :key="'totalCount-' + checkboxChangeCounter"
                            :title="statsTotalCount()"
                            :small-title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 16}"
                            :title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 28}"
                            card-class="kf-card-stats kf-card-shadow-low mb-3">
                </stats-card>
              </div>
              <div class="col-xl-3 col-sm-6">
                <stats-card type="info"
                            icon="nc-icon nc-credit-card"
                            small-title="Total Penjualan"
                            :key="'totalAmount-' + checkboxChangeCounter"
                            :title="statsTotalAmount()"
                            :small-title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 16}"
                            :title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 28}"
                            card-class="kf-card-stats kf-card-shadow-low mb-3">
                </stats-card>
              </div>
              <div class="col-xl-3 col-sm-6">
                <stats-card type="danger"
                            icon="nc-icon nc-bank"
                            small-title="Pajak"
                            :key="'taxAmount-' + checkboxChangeCounter"
                            :title="statsTaxAmount()"
                            :small-title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 16}"
                            :title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 28}"
                            card-class="kf-card-stats kf-card-shadow-low mb-3">
                </stats-card>
              </div>
              <div class="col-xl-3 col-sm-6">
                <stats-card type="success"
                            icon="nc-icon nc-money-coins"
                            small-title="Penjualan Bersih"
                            :key="'subTotalAmount-' + checkboxChangeCounter"
                            :title="statsSubTotalAmount()"
                            :small-title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 16}"
                            :title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 28}"
                            card-class="kf-card-stats kf-card-shadow-low mb-3">
                </stats-card>
              </div>
            </div>
          </div>

          <div class="col-12 mt-2">
            <div class="d-flex justify-content-end">
              <p-checkbox 
                v-model="selectAllInvoices"
                text-left
                :disabled="isView"
                @input="selectAllInvoicesChange()">
                Pilih semua transaksi
              </p-checkbox>
            </div>
          </div>

          <div class="col-12 mb-3">
            <el-table :data="invoices" 
                      header-row-class-name="text-primary"
                      :row-class-name="getRowClassName"
                      v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
              <el-table-column label="Tanggal" width="195">
                <template slot-scope="scope">
                  <div>{{scope.row.createdAt | dateTimeFormat}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="transactionNo" label="Nomor" width="180"></el-table-column>
              <el-table-column prop="customerName" label="Pelanggan" min-width="200"></el-table-column>
              <el-table-column label="Total" align="right" width="145">
                <template slot-scope="scope">
                  <div>{{scope.row.totalAmount | currencyFormat}}</div>
                </template>
              </el-table-column>
              <el-table-column label="Status" align="center" width="190">
                <template slot-scope="scope">
                  <div class="text-uppercase" :class="getInvoiceStatusColor(scope.row.status)">{{getInvoiceStatusLocal(scope.row.status)}}</div>
                </template>
              </el-table-column>
              <el-table-column label="Pilih" align="center" width="90">
                <template slot-scope="scope">
                  <p-checkbox 
                    :key="'checkbox-' + scope.row.id + '-' + checkboxChangeCounter"
                    v-model="scope.row.isSelected"
                    :disabled="isView"
                    @input="checkboxChange()">
                  </p-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="col-12" v-if="invoices.length > 20">
            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage()">Kembali</p-button>
              <p-button round type="warning" v-if="canSaveDraftReport" @click="saveDraftReport()">
                <i slot="label" class="fa fa-floppy-o"></i>
                &nbsp;Simpan Draft
              </p-button>
              <p-button round type="primary" v-if="canSaveSubmitReport" @click="saveSubmitReport()">
                <i slot="label" class="fa fa-paper-plane-o"></i>
                &nbsp;Submit
              </p-button>
            </div>
          </div>

        </div>
      </template>
    </card>
  </div>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import moment from 'moment'
  import { Card } from '../../UIComponents'
  import StatsCard from '../../UIComponents/Cards/StatsCard'
  import { Table, TableColumn, Loading } from 'element-ui'
  import filterHelper from '../../../helpers/filterHelper'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import alertHelper from '../../../helpers/alertHelper'
  import * as urlConstant from '../../../constants/urlConstant'
  import * as sellInvoiceSearchQueryOrderEnum from '../../../enums/sellInvoiceSearchQueryOrderEnum'
  import * as sellInvoiceStatusEnum from '../../../enums/sellInvoiceStatusEnum'
  import * as reportAltStatusEnum from '../../../enums/reportAltStatusEnum'

  Vue.use(Loading)

  export default {
    components: {
      Card,
      StatsCard,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data () {
      return {
        hasPrevRoute: false,
        isFetching: false,
        isSaving: false,
        outstandingPeriod: null,
        invoices: [],
        summaryCards: [],
        model: {
          id: null,
          year: null,
          month: null,
          status: '',
          submittedAt: null,
          sellInvoices: [],
          sellInvoiceIds: [],
          invoicesCount: null,
          baseSubTotalAmount: null,
          subTotalAmount: null,
          taxAmount: null,
          totalAmount: null,
          grossProfit: null,
          netProfit: null,
        },
        stickyCardsObserver: null,
        checkboxChangeCounter: 0,
        selectAllInvoices: false,
      }
    },
    created() {
      const routeChanged = this.setDefaultRouteQuery()

      if (routeChanged) {
        return
      }
      
      this.init()

      this.$nextTick(() => {
        this.initStickyCards()
      })
    },
    methods: {
      init () {
        this.model.year = parseInt(this.$route.params.year)
        this.model.month = parseInt(this.$route.params.month)

        const fetchExistingReport = () => new Promise((resolve, reject) => {
          this.isFetching = true
          
          this.axios.get(`${urlConstant.API_URL_REPORTS_SALES_ALT_LIST}/${this.model.year}/${this.model.month}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isFetching = false
                reject()
                return
              }

              let hasExistingReport = false

              if (response.data.report) {
                this.model = response.data.report
                hasExistingReport = true
              }

              this.isFetching = false
              resolve(hasExistingReport)
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isFetching = false
              reject()
            })
        })

        const fetchOutstanding = () => new Promise((resolve, reject) => {
          this.isFetching = true

          const query = {
            userDate: moment().format()
          }

          this.axios.get(urlConstant.API_URL_REPORTS_SALES_ALT_LIST_OUTSTANDING, { params: query })
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isFetching = false
                reject()
                return
              }

              if (response.data.hasOutstanding) {
                this.outstandingPeriod = { 
                  year: response.data.year, 
                  month: response.data.month 
                }
              }

              this.isFetching = false
              resolve()
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isFetching = false
              reject()
            })
        })

        const fetchInvoices = () => {
          this.isFetching = true

          const query = {
            pageSize: null,
            pageNumber: null,
            order: sellInvoiceSearchQueryOrderEnum.CreatedAtAsc,
            startDate: moment([this.model.year, this.model.month - 1]).startOf('month').format(),
            endDate: moment([this.model.year, this.model.month - 1]).endOf('month').format(),
            status: sellInvoiceStatusEnum.PAID,
          }

          this.axios.get(urlConstant.API_URL_REPORTS_SALES, { params: query })
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isFetching = false
                return
              }

              this.invoices = response.data.invoices
              this.invoices.sort((a, b) => 
                (new moment(a.createdAt)).startOf('day') - (new moment(b.createdAt)).startOf('day') || 
                a.totalAmount - b.totalAmount)

              for (const invoice of this.invoices) {
                invoice.isSelected = this.model.id && invoice.reportAlternateId === this.model.id
              }

              if (this.invoices.length && !this.invoices.find(x => !x.isSelected)) {
                this.selectAllInvoices = true
              }

              this.isFetching = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isFetching = false
            })
        }

        fetchExistingReport().then(hasExistingReport => {
          if (hasExistingReport) {
            fetchInvoices()
            return
          }
          
          fetchOutstanding().then(() => {
            if (this.outstandingPeriod && 
                (this.model.year !== this.outstandingPeriod.year || 
                this.model.month !== this.outstandingPeriod.month)) {
              alertHelper.basic('Periode laporan tidak valid!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              return
            }

            fetchInvoices()
          })
        })
      },
      setDefaultRouteQuery () {
        let isChanged = false
        let query = _.cloneDeep(this.$route.query)
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_REPORT_SALES_ALT_LIST)
        }
      },
      getReportStatusColor (reportStatus) {
        switch (reportStatus) {
          case reportAltStatusEnum.DRAFT:
            return 'alert-warning'
          case reportAltStatusEnum.SUBMITTED:
            return 'alert-success'
          default:
            return 'alert-primary'
        }
      },
      getReportStatusLocal (reportStatus) {
        return (reportAltStatusEnum.getLocal(reportStatus) || 'Baru').toUpperCase()
      },
      getInvoiceStatusColor (invoiceStatus) {
        switch (invoiceStatus) {
          case sellInvoiceStatusEnum.OPEN:
            return 'text-warning'
          case sellInvoiceStatusEnum.CLOSED:
            return 'text-muted'
          case sellInvoiceStatusEnum.PAID:
            return 'text-success'
          case sellInvoiceStatusEnum.VOIDED:
            return 'text-danger'
          default:
            return 'text-muted'
        }
      },
      getInvoiceStatusLocal (invoiceStatus) {
        return sellInvoiceStatusEnum.getLocal(invoiceStatus).toUpperCase()
      },
      checkboxChange () {
        this.checkboxChangeCounter++
      },
      selectAllInvoicesChange () {
        for (const invoice of this.invoices) {
          invoice.isSelected = this.selectAllInvoices
        }

        this.checkboxChange()
      },
      initStickyCards () {
        const stickyCardsEl = this.$refs.stickyCards

        this.stickyCardsObserver = new IntersectionObserver( 
          ([e]) => e.target.classList.toggle('kf-sticky-top', e.intersectionRatio < 1),
          { threshold: [1] }
        );

        this.stickyCardsObserver.observe(stickyCardsEl)
      },
      statsTotalCount () {
        return filterHelper.numberFormat(this.invoices.filter(x => x.isSelected).length)
      },
      statsTotalAmount () {
        return filterHelper.currencyFormat(this.invoices.filter(x => x.isSelected).reduce((a, b) => a + b.totalAmount, 0))
      },
      statsTaxAmount () {
        return filterHelper.currencyFormat(this.invoices.filter(x => x.isSelected).reduce((a, b) => a + b.taxAmount, 0))
      },
      statsSubTotalAmount () {
        return filterHelper.currencyFormat(this.invoices.filter(x => x.isSelected).reduce((a, b) => a + b.subTotalAmount, 0))
      },
      saveReport (isSubmit) {
        this.isSaving = true
        
        const params = {
          year: this.model.year,
          month: this.model.month,
          sellInvoiceIds: this.invoices.filter(x => x.isSelected).map(x => x.id),
          isSubmit: isSubmit,
          userDate: moment().format(),
        }

        this.axios.post(urlConstant.API_URL_REPORTS_SALES_ALT_LIST, params)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isSaving = false
              return
            }

            this.showSaveSuccessMessage(response.data.report)
            this.isSaving = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isSaving = false
          })
      },
      saveDraftReport () {
        this.saveReport()
      },
      saveSubmitReport () {
        const title = 'Apakah kamu yakin?'
        const content = 'Laporan penjualan selektif yang telah disubmit tidak dapat diubah lagi.'
        const onConfirm = () => { this.saveReport(true) }

        alertHelper.confirm(title, content, alertHelper.typeEnums.warning, 'Submit', 'Batal', onConfirm)
      },
      showSaveSuccessMessage (report) {
        const title = report.status === reportAltStatusEnum.DRAFT 
          ? 'Draft laporan penjualan berhasil disimpan!'
          : 'Laporan penjualan berhasil disubmit!'

        const content = `Periode: ${report.year}-${report.month}`
        
        alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
      },
      getRowClassName ({row, rowIndex}) {
        if (!rowIndex) {
          return ''
        }

        const prefDate = (new moment(this.invoices[rowIndex - 1].createdAt)).startOf('day')
        const currentDate = (new moment(row.createdAt)).startOf('day')

        return currentDate.isSame(prefDate) ? '' : 'kf-first-group'
      },
    },
    computed: {
      isLoading () {
        return this.isFetching || this.isSaving
      },
      canSaveSubmitReport () {
        return this.model.status !== reportAltStatusEnum.SUBMITTED
      },
      canSaveDraftReport () {
        return this.model.status !== reportAltStatusEnum.SUBMITTED
      },
      isView () {
        return this.model.status === reportAltStatusEnum.SUBMITTED
      },
      isEdit () {
        return this.model.status === reportAltStatusEnum.DRAFT
      },
      isNew () {
        return !this.model.status
      },
    },
    filters: {
      ...filterHelper.spread
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
    destroyed () {
      if (this.stickyCardsObserver) {
        this.stickyCardsObserver.disconnect()
      }
    },
  }
</script>
<style scoped lang="scss">
  @media screen and (min-width: 576px) {
    .kf-cards-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: -0.1px;
      z-index: 1;

      .kf-card-stats {
        background-color: #fff;
      }

      &.kf-sticky-top {
        .kf-card-shadow-low {
          box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .el-table {
    ::v-deep {
      table > tbody > tr.kf-first-group > td { 
        border-top-style: solid;
        border-top-width: 4px;
        border-top-color: #EBEEF5;
      }
    }
  }
</style>
