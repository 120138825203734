<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Transaksi Penjualan</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <div class="order-md-2 text-center">
              <p-button round type="primary" @click="newInvoice()">
                <i slot="label" class="nc-icon nc-simple-add"></i>
                &nbsp;Buat Invoice Penjualan
              </p-button>
            </div>
            <div class="order-md-1 text-center">
              <p-button :outline="!isShowFilter" round @click="toggleFilter" class="kf-color-only-transition">
                Saring&nbsp;
                <i slot="labelRight" class="nc-icon" :class="{ 'nc-minimal-down': !isShowFilter, 'nc-minimal-up': isShowFilter }"></i>
              </p-button>
            </div>
          </div>
        </div>
        
        <collapse-transition :duration="200">
          <div class="col-12 collapsed kf-table-filter-fields" v-show="isShowFilter">
            <form class="my-2" v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
              <div class="row">
                <div class="col-md-4">
                  <fg-input label="Nomor invoice" v-model="searchModel.transactionNo"></fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Status">
                    <el-select class="kf select-default" v-model="searchModel.status" clearable filterable>
                      <el-option v-for="option in selects.invoiceStatuses"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
              </div>

              <div class="d-flex justify-content-md-end justify-content-center">
                <p-button outline round @click="resetFilter">Reset</p-button>
                <p-button round @click="applyFilter">Terapkan</p-button>
              </div>
            </form>
          </div>
        </collapse-transition>

        <div class="col-12 mb-3">
          <el-table :data="invoices" 
                    header-row-class-name="text-primary"
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column label="Tanggal" width="195">
              <template slot-scope="scope">
                <div>{{scope.row.createdAt | dateTimeFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="transactionNo" label="Nomor" width="180"></el-table-column>
            <el-table-column prop="customerName" label="Pelanggan" min-width="200"></el-table-column>
            <el-table-column label="Total" align="right" width="145">
              <template slot-scope="scope">
                <div>{{scope.row.totalAmount | currencyFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Status" align="center" width="190">
              <template slot-scope="scope">
                <div class="text-uppercase" :class="getStatusTextColor(scope.row.status)">{{getStatusLocal(scope.row.status)}}</div>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="center" label="Tindakan" width="120">
              <template slot-scope="props">
                <el-tooltip placement="top" content="Detail Invoice Penjualan">
                  <p-button type="success" size="sm" icon @click="handleDetail(props.row)" class="rounded">
                    <i class="fa fa-file-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Buat Pembayaran" v-if="canCreatePayment(props.row)">
                  <p-button type="warning" size="sm" icon @click="createPayment(props.row)" class="rounded">
                    <i class="fa fa-money"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import base64url from 'base64url'
  import _ from 'lodash'
  import { CollapseTransition } from 'vue2-transitions';
  import { Card, Pagination } from '../../UIComponents'
  import { Table, TableColumn, Loading, Tooltip, Select, Option } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import * as sellInvoiceStatusEnum from '../../../enums/sellInvoiceStatusEnum'
  import arrayHelper from '../../../helpers/arrayHelper'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  const initialPagination = {
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    totalCount: 0,
    pageSizeOptions: pageSizeOptions
  }

  const initialSearchModel = {
    transactionNo: null,
    status: null,
  }

  export default {
    components: {
      Card,
      CollapseTransition,
      [Tooltip.name]: Tooltip,
      [Pagination.name]: Pagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data () {
      return {
        isInvoicesLoading: false,
        isReferencesLoading: false,
        isReferencesLoaded: false,
        isShowFilter: false,
        invoices: [],
        searchModel: _.cloneDeep(initialSearchModel),
        selects: {
          invoiceStatuses: [],
        },
        pagination: _.cloneDeep(initialPagination),
      }
    },
    created() {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setPagination()
        this.setSearchModel()

        if (!this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }

        this.fetchData()
      },
      fetchData () {
        this.isInvoicesLoading = true

        // Clone deep so we don't mutate the original object
        const searchModel = _.cloneDeep(this.searchModel)
        
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,

          ...searchModel
        }
        
        this.axios.get(urlConstant.API_URL_INVOICES, { params: query })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isInvoicesLoading = false
              return
            }
            
            this.invoices = response.data.invoices
            this.pagination.totalCount = response.data.totalCount

            this.isInvoicesLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isInvoicesLoading = false
          })
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_INVOICES_STATUSES),
        ])
          .then(responses => {
            const pInvoiceStatusesRes = responses[0]

            // Invoice statuses
            let invoiceStatuses = (pInvoiceStatusesRes.data.isSuccess ? pInvoiceStatusesRes.data.sellInvoiceStatuses : [])
              .map(s => {
                return {
                  id: s, 
                  name: this.getStatusLocal(s)
                }
              })
            invoiceStatuses.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.invoiceStatuses = invoiceStatuses

            this.isReferencesLoading = false
          })
          .catch(() => {
            this.isReferencesLoading = false
          })
      },
      newInvoice () {
        this.$router.push(urlConstant.WEB_URL_INVOICES_NEW)
      },
      handleDetail (row) {
        this.$router.push(`${urlConstant.WEB_URL_INVOICES_VIEW}/${base64url.encode(row.id.toString())}`)
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,
        }

        for (const prop in this.searchModel) {
          if (this.searchModel[prop]) {
            query[prop] = this.searchModel[prop]
          }
        }

        this.$router.push({ path: this.$route.path, query: query })
      },
      setSearchModel () {
        for (const prop in this.searchModel) {
          if (this.$route.query[prop]) {
            this.searchModel[prop] = this.$route.query[prop]
            this.isShowFilter = true
          }
          
          if (this.searchModel[prop] === '') {
            this.searchModel[prop] = null
          }
        }
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }
        
        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      changePage () {
        this.applyRoute()
      },
      getStatusTextColor (invoiceStatus) {
        switch (invoiceStatus) {
          case sellInvoiceStatusEnum.OPEN:
            return 'text-warning'
          case sellInvoiceStatusEnum.CLOSED:
            return 'text-muted'
          case sellInvoiceStatusEnum.PAID:
            return 'text-success'
          case sellInvoiceStatusEnum.VOIDED:
            return 'text-danger'
          default:
            return 'text-muted'
        }
      },
      getStatusLocal (invoiceStatus) {
        return sellInvoiceStatusEnum.getLocal(invoiceStatus).toUpperCase()
      },
      canCreatePayment (row) {
        if (row && (row.status === sellInvoiceStatusEnum.OPEN || row.status === sellInvoiceStatusEnum.VOIDED)) {
          return true
        }

        return false
      },
      createPayment (row) {
        this.$router.push(`${urlConstant.WEB_URL_INVOICES_VIEW}/${base64url.encode(row.id.toString())}?payment=true`)
      },
      toggleFilter () {
        this.isShowFilter = !this.isShowFilter
      },
      resetFilter () {
        this.pagination = _.cloneDeep(initialPagination)
        this.searchModel = _.cloneDeep(initialSearchModel)

        this.applyRoute()
      },
      applyFilter () {
        const totalCount = this.pagination.totalCount;
        this.pagination = _.cloneDeep(initialPagination)
        this.pagination.totalCount = totalCount

        this.applyRoute()
      },
    },
    computed: {
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      },
      isLoading () {
        return this.isInvoicesLoading || this.isReferencesLoading
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
  }
</script>
<style scoped lang="scss">
</style>
