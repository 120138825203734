<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Baki Penjualan</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <div class="order-md-2 text-center">
              <p-button round type="primary" @click="newTray()">
                <i slot="label" class="nc-icon nc-simple-add"></i>
                &nbsp;Buat Baki Penjualan
              </p-button>
            </div>
            <div class="order-md-1 text-center">
              <p-button :outline="!isShowFilter" round @click="toggleFilter" class="kf-color-only-transition">
                Saring&nbsp;
                <i slot="labelRight" class="nc-icon" :class="{ 'nc-minimal-down': !isShowFilter, 'nc-minimal-up': isShowFilter }"></i>
              </p-button>
            </div>
          </div>
        </div>
        
        <collapse-transition :duration="200">
          <div class="col-12 collapsed kf-table-filter-fields" v-show="isShowFilter">
            <form class="my-2" v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
              <div class="row">
                <div class="col-md-4">
                  <fg-input label="Nama Baki" v-model="searchModel.name"></fg-input>
                </div>
              </div>

              <div class="d-flex justify-content-md-end justify-content-center">
                <p-button outline round @click="resetFilter">Reset</p-button>
                <p-button round @click="applyFilter">Terapkan</p-button>
              </div>
            </form>
          </div>
        </collapse-transition>

        <div class="col-12 mb-3">
          <el-table :data="trays" 
                    header-row-class-name="text-primary"
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column prop="code" label="Kode" width="160"></el-table-column>
            <el-table-column prop="name" label="Nama Baki" min-width="220"></el-table-column>
            <el-table-column prop="trayOwner.fullName" label="Penanggung Jawab" width="220"></el-table-column>
            <el-table-column label="Status" align="center" width="190">
              <template slot-scope="scope">
                <div class="text-uppercase" :class="getStatusTextColor(scope.row.isActive)">{{getStatusLocal(scope.row.isActive)}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Jumlah Produk" align="center" width="160">
              <template slot-scope="scope">
                <div>{{scope.row.itemsCount}} buah</div>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="center" label="Tindakan" width="120">
              <template slot-scope="props">
                <el-tooltip placement="top" content="Detail Baki">
                  <p-button type="success" size="sm" icon @click="handleDetail(props.row)" class="rounded">
                    <i class="fa fa-file-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ubah Baki">
                  <p-button type="warning" size="sm" icon @click="handleEdit(props.row)" class="rounded">
                    <i class="fa fa-pencil-square-o"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import { CollapseTransition } from 'vue2-transitions';
  import { Card, Pagination } from '../../UIComponents'
  import { Table, TableColumn, Loading, Tooltip } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import routerHelper from '../../../helpers/routerHelper'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  const initialPagination = {
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    totalCount: 0,
    pageSizeOptions: pageSizeOptions
  }

  const initialSearchModel = {
    name: null
  }

  export default {
    components: {
      Card,
      CollapseTransition,
      [Pagination.name]: Pagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
    },
    data () {
      return {
        isTraysLoading: false,
        isShowFilter: false,
        trays: [],
        searchModel: _.cloneDeep(initialSearchModel),
        pagination: _.cloneDeep(initialPagination),
      }
    },
    created() {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setPagination()
        this.setSearchModel()

        this.fetchData()
      },
      fetchData () {
        this.isTraysLoading = true

        // Clone deep so we don't mutate the original object
        const searchModel = _.cloneDeep(this.searchModel)
        
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,

          ...searchModel
        }
        
        this.axios.get(urlConstant.API_URL_TRAYS, { params: query })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isTraysLoading = false
              return
            }
            
            this.trays = response.data.trays
            this.pagination.totalCount = response.data.totalCount

            this.isTraysLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isTraysLoading = false
          })
      },
      toggleFilter () {
        this.isShowFilter = !this.isShowFilter
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,
        }

        for (const prop in this.searchModel) {
          if (this.searchModel[prop]) {
            query[prop] = this.searchModel[prop]
          }
        }

        routerHelper.safePush({ path: this.$route.path, query: query })
      },
      setSearchModel () {
        for (const prop in this.searchModel) {
          if (this.$route.query[prop]) {
            this.searchModel[prop] = this.$route.query[prop]
            this.isShowFilter = true
          }

          if (this.searchModel[prop] === '') {
            this.searchModel[prop] = null
          }
        }
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }
        
        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      changePage () {
        this.applyRoute()
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      resetFilter () {
        this.pagination = _.cloneDeep(initialPagination)
        this.searchModel = _.cloneDeep(initialSearchModel)

        this.applyRoute()
      },
      applyFilter () {
        const totalCount = this.pagination.totalCount;
        this.pagination = _.cloneDeep(initialPagination)
        this.pagination.totalCount = totalCount

        this.applyRoute()
      },
      getStatusTextColor (isActive) {
        return isActive ? 'text-success' : 'text-muted'
      },
      getStatusLocal (isActive) {
        return isActive ? 'Aktif' : 'Tidak aktif'
      },
      handleDetail (row) {
        routerHelper.safePush(`${urlConstant.WEB_URL_TRAYS_VIEW}/${base64url.encode(row.id.toString())}`)
      },
      handleEdit (row) {
        routerHelper.safePush(`${urlConstant.WEB_URL_TRAYS_EDIT}/${base64url.encode(row.id.toString())}`)
      },
      newTray () {
        routerHelper.safePush(urlConstant.WEB_URL_TRAYS_NEW)
      },
    },
    computed: {
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      },
      isLoading () {
        return this.isTraysLoading
      },
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
  }
</script>
<style scoped>
</style>
