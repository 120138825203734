export const CREATED = 'Created'
export const IN_PROGRESS = 'InProgress'
export const RECEIVED = 'Received'
export const RETURNED = 'Returned'
export const CANCELLED = 'Cancelled'

const locals = {}
locals[CREATED] = 'Dibuat'
locals[IN_PROGRESS] = 'Dikirim'
locals[RECEIVED] = 'Diterima'
locals[RETURNED] = 'Retur'
locals[CANCELLED] = 'Batal'

export const getLocal = (key) => { return locals[key] ? locals[key] : key }
