<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Daftar Penjualan Selektif</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12" v-if="outstandingPeriod && dueReportDateUrl">
          <div class="alert alert-danger">
            Laporan penjualan selektif untuk periode <strong>{{ outstandingPeriod.year }}-{{ outstandingPeriod.month }}</strong> belum disubmit. 
            Klik <router-link :to="dueReportDateUrl">disini</router-link> untuk membuat laporan.
          </div>
        </div>

        <div class="col-12" v-if="noInvoice">
          <div class="alert alert-primary">
            Belum ada invoice penjualan yang bisa dilaporkan. 
            Klik <router-link :to="invoicesUrl" class="text-warning">disini</router-link> untuk mulai membuat invoice penjualan.
          </div>
        </div>

        <div class="col-12">
          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage">Kembali</p-button>
            <p-button type="primary" round @click="handleCreate" v-if="outstandingPeriod && dueReportDateUrl">
              <i slot="label" class="nc-icon nc-simple-add"></i>
              &nbsp;Buat Laporan Periode {{ outstandingPeriod.year }}-{{ outstandingPeriod.month }}
            </p-button>
          </div>
        </div>

        <div class="col-12 mb-3">
          <el-table :data="salesAltReports" 
                    header-row-class-name="text-primary" 
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column label="Periode" min-width="120">
              <template slot-scope="scope">
                <div>{{scope.row.year}}-{{scope.row.month}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Jml Transaksi" width="160" align="right">
              <template slot-scope="scope">
                <div>{{scope.row.invoicesCount | numberFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Total Penjualan" width="180" align="right">
              <template slot-scope="scope">
                <div>{{scope.row.totalAmount | currencyFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Total Pajak" width="180" align="right">
              <template slot-scope="scope">
                <div>{{scope.row.taxAmount | currencyFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Penjualan Bersih" width="185" align="right">
              <template slot-scope="scope">
                <div>{{scope.row.subTotalAmount | currencyFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Tanggal Submit" width="195">
              <template slot-scope="scope">
                <div v-if="scope.row.submittedAt">{{scope.row.submittedAt | dateTimeFormat}}</div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="center" label="Tindakan" width="180">
              <template slot-scope="props">
                <el-tooltip placement="top" content="Lihat Penjualan" v-if="canView(props.row)">
                  <p-button type="success" size="sm" icon @click="handleView(props.row)" class="rounded">
                    <i class="fa fa-file-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ubah Penjualan" v-if="canEdit(props.row)">
                  <p-button type="warning" size="sm" icon @click="handleEdit(props.row)" class="rounded">
                    <i class="fa fa-pencil-square-o"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import moment from 'moment'
  import { Card, Pagination } from '../../UIComponents'
  import { Table, TableColumn, Loading, Tooltip } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import * as reportAltStatusEnum from '../../../enums/reportAltStatusEnum'
  import * as routerConstant from '../../../constants/routerConstant'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  export default {
    components: {
      Card,
      [Table.name]: Table,
      [Tooltip.name]: Tooltip,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
    },
    data () {
      return {
        hasPrevRoute: false,
        isNavigatingBack: false,
        isOutstandingLoading: false,
        isInvoicesLoading: false,
        outstandingPeriod: null,
        noInvoice: false,
        salesAltReports: [],
        invoicesUrl: urlConstant.WEB_URL_INVOICES,
        pagination: {
          pageNumber: defaultPageNumber,
          pageSize: defaultPageSize,
          totalCount: 0,
          pageSizeOptions: pageSizeOptions
        },
      }
    },
    created() {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setPagination()
        this.fetchData()
      },
      fetchData () {
        const fetchOutstanding = () => {
          this.isOutstandingLoading = true

          const query = {
            userDate: moment().format()
          }

          this.axios.get(urlConstant.API_URL_REPORTS_SALES_ALT_LIST_OUTSTANDING, { params: query })
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isOutstandingLoading = false
                return
              }

              if (response.data.hasOutstanding) {
                this.outstandingPeriod = { 
                  year: response.data.year, 
                  month: response.data.month 
                }
              }

              this.noInvoice = response.data.noInvoice

              this.isOutstandingLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isOutstandingLoading = false
            })
        }

        const fetchInvoices = () => {
          this.isInvoicesLoading = true

          const query = {
            pageSize: this.pagination.pageSize,
            pageNumber: this.pagination.pageNumber
          }

          this.axios.get(urlConstant.API_URL_REPORTS_SALES_ALT_LIST, { params: query })
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isInvoicesLoading = false
                return
              }
              
              this.salesAltReports = response.data.salesAltReports
              this.pagination.totalCount = response.data.totalCount

              this.isInvoicesLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isInvoicesLoading = false
            })
        }

        fetchOutstanding()
        fetchInvoices()
      },
      setDefaultRouteQuery () {
        let isChanged = false
        let query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }

        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      changePage () {
        this.applyRoute()
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        }

        this.$router.push({ path: this.$route.path, query: query })
      },
      handleView (report) {
        this.$router.push(`${urlConstant.WEB_URL_REPORT_SALES_ALT_LIST}/${report.year}/${report.month}`)
      },
      handleEdit (report) {
        this.$router.push(`${urlConstant.WEB_URL_REPORT_SALES_ALT_LIST}/${report.year}/${report.month}`)
      },
      handleCreate () {
        this.$router.push(this.dueReportDateUrl)
      },
      canView (report) {
        return report.status === reportAltStatusEnum.SUBMITTED
      },
      canEdit (report) {
        return report.status !== reportAltStatusEnum.SUBMITTED
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.isNavigatingBack = true
          this.$router.go(-1)
        }
        else {
          this.$router.replace(`${urlConstant.WEB_URL_REPORTS}?tab=sales`)
        }
      },
    },
    computed: {
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      },
      isLoading () {
        return this.isOutstandingLoading || this.isInvoicesLoading
      },
      dueReportDateUrl () {
        return this.outstandingPeriod
          ? `${urlConstant.WEB_URL_REPORT_SALES_ALT_LIST}/${this.outstandingPeriod.year}/${this.outstandingPeriod.month}`
          : ''
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
    beforeRouteUpdate (to, from, next) {
      if (this.hasPrevRoute && this.isNavigatingBack && to.name === from.name) {
        this.$router.go(-1)
        next(new Error(routerConstant.REDIRECT_TO_SELF))
        return
      }

      next()
    },
  }
</script>
<style scoped lang="scss">
</style>
