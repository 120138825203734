<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Laporan Mutasi Stok Kategori</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage">Kembali</p-button>
          </div>
        </div>
        
        <div class="col-12 kf-table-filter-fields">
          <form class="my-2" v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <div class="row">
              <div class="col-md-6">
                <fg-input label="Periode">
                  <el-date-picker
                    v-model="searchModel.periodDateRange"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="Tanggal awal"
                    end-placeholder="Tanggal akhir"
                    format="dd-MM-yyyy"
                    :picker-options="dateRangePickerOptions"
                    :clearable="false">
                  </el-date-picker>
                </fg-input>
              </div>
            </div>

            <div class="d-flex justify-content-md-end justify-content-center">
              <p-button outline round @click="resetFilter">Reset</p-button>
              <p-button round @click="applyFilter">Terapkan</p-button>
            </div>
          </form>
        </div>

        <div class="col-12 mt-3" v-if="summaryCards.length">
          <div class="row">
            <div class="col-xl-3 col-sm-6" v-for="card in summaryCards" v-bind:key="card.title">
              <stats-card :type="card.type"
                          :icon="card.icon"
                          :small-title="card.title"
                          :title="card.value"
                          :title-class="card.valueClass"
                          :small-title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 16}"
                          :title-resize-options="{ratio: 1, minFontSize: 8, maxFontSize: 28}"
                          card-class="kf-card-shadow-low mb-3">
              </stats-card>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <el-table :data="stockCategories" 
                    header-row-class-name="text-primary"
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column prop="categoryName" label="Kategori" min-width="200"></el-table-column>
            <!-- <el-table-column label="Stok Masuk" align="right" width="140">
              <template slot-scope="scope">
                <div>{{scope.row.stockIn | numberFormat}}</div>
              </template>
            </el-table-column> -->
            <el-table-column label="Stok Keluar" align="right" width="140">
              <template slot-scope="scope">
                <div>{{scope.row.stockOut | numberFormat}}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="Mutasi" align="right" width="140">
              <template slot-scope="scope">
                <div :class="getMutationTextColor(scope.row.mutation)">{{scope.row.mutation | numberFormat}}</div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import moment from 'moment'
  import ResizeText from 'vue-resize-text'
  import StatsCard from '../../UIComponents/Cards/StatsCard'
  import { Card } from '../../UIComponents'
  import { Table, TableColumn, Loading, DatePicker } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import * as dateTimeConstant from '../../../constants/dateTimeConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import datePickerHelper from '../../../helpers/datePickerHelper'
  import arrayHelper from '../../../helpers/arrayHelper'
  import * as routerConstant from '../../../constants/routerConstant'

  Vue.use(Loading)

  const initialSearchModel = {
    periodDateRange: null,
  }

  export default {
    components: {
      Card,
      StatsCard,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker
    },
    directives: {
      ResizeText
    },
    data () {
      return {
        hasPrevRoute: false,
        isNavigatingBack: false,
        isStockCategoriesLoading: false,
        isReferencesLoading: false,
        stockCategories: [],
        selects: {
          productCategories: [],
        },
        summaryCards: [],
        searchModel: _.cloneDeep(initialSearchModel),
        dateRangePickerOptions: datePickerHelper.getReportDateRangePickerOptions(),
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setSearchModel()
        this.fetchData()
      },
      fetchData () {
        const fetchProductCategories = () => {
          return new Promise((resolve, reject) => {
            this.isReferencesLoading = true

            this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_CATEGORIES)
              .then(response => {
                let categories = response.data.isSuccess ? response.data.productCategories : []
                categories.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
                this.selects.productCategories = categories

                this.isReferencesLoading = false
                resolve()
              })
              .catch(() => {
                this.isReferencesLoading = false
                reject()
              })
          })
        }

        const fetchStockCategories = () => {
          this.isStockCategoriesLoading = true

          // Clone deep so we don't mutate the original object
          const searchModel = _.cloneDeep(this.searchModel)

          // Update start and end time
          if (searchModel.periodDateRange && searchModel.periodDateRange[0] && searchModel.periodDateRange[1]) {
            searchModel.startDate = searchModel.periodDateRange[0].startOf('day').format()
            searchModel.endDate = searchModel.periodDateRange[1].endOf('day').format()
          }
          delete searchModel.periodDateRange
          
          const query = {
            ...searchModel
          }
          
          this.axios.get(urlConstant.API_URL_REPORTS_PRODUCTS_STOCK_CAT_MUTATIONS, { params: query })
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isStockCategoriesLoading = false
                return
              }

              this.stockCategories = this.selects.productCategories.map(x => ({
                categoryId: x.id,
                categoryName: x.name,
                stockIn: 0,
                stockOut: 0,
                mutation: 0
              }))

              for (const categoryMutation of response.data.stockCategoryMutations) {
                let stockCategory = this.stockCategories.find(x => x.categoryId == categoryMutation.productCategoryId)
                
                if (stockCategory) {
                  stockCategory.stockOut = categoryMutation.quantity
                }

                stockCategory.mutation = stockCategory.stockIn - stockCategory.stockOut
              }

              // Sort
              this.stockCategories.sort((a, b) => b.stockOut - a.stockOut || arrayHelper.stringCompare(a.name, b.name))

              // Update summary cards
              this.summaryCards = [
                {
                  type: 'warning',
                  icon: 'nc-icon nc-cart-simple',
                  title: 'Total Stok Keluar',
                  value: filterHelper.numberFormat(this.stockCategories.reduce((a, b) => a + b.stockOut, 0)),
                }
              ]
              
              this.isStockCategoriesLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isStockCategoriesLoading = false
            })
        }

        fetchProductCategories()
          .then(fetchStockCategories)
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)

        if (!query.startDate || !query.endDate) {
          query.startDate = moment().startOf('year').startOf('day').format(dateTimeConstant.MOMENT_DATE_FORMAT),
          query.endDate = moment().endOf('day').format(dateTimeConstant.MOMENT_DATE_FORMAT)
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      applyRoute () {
        const query = {}

        const searchModel = _.cloneDeep(this.searchModel)

        for (const prop in searchModel) {
          if (searchModel[prop]) {
            query[prop] = searchModel[prop]
          }
        }

        // Period date range
        if (query.periodDateRange && query.periodDateRange[0] && query.periodDateRange[1]) {
          query.startDate = moment(query.periodDateRange[0]).format(dateTimeConstant.MOMENT_DATE_FORMAT)
          query.endDate = moment(query.periodDateRange[1]).format(dateTimeConstant.MOMENT_DATE_FORMAT)
        }
        delete query.periodDateRange

        this.$router.push({ path: this.$route.path, query: query })
      },
      setSearchModel () {
        for (const prop in this.searchModel) {
          if (this.$route.query[prop]) {
            this.searchModel[prop] = this.$route.query[prop]
          }

          if (this.searchModel[prop] === '') {
            this.searchModel[prop] = null
          }
        }

        // Period date range
        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.searchModel.periodDateRange = [
            moment(this.$route.query.startDate, dateTimeConstant.MOMENT_DATE_FORMAT),
            moment(this.$route.query.endDate, dateTimeConstant.MOMENT_DATE_FORMAT)
          ]
        }
      },
      resetFilter () {
        this.searchModel = _.cloneDeep(initialSearchModel)

        this.applyRoute()
      },
      applyFilter () {
        this.applyRoute()
      },
      getMutationTextColor (mutation) {
        if (mutation > 0) {
          return 'text-success'
        }
        if (mutation < 0) {
          return 'text-danger'
        }

        return ''
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.isNavigatingBack = true
          this.$router.go(-1)
        }
        else {
          this.$router.replace(`${urlConstant.WEB_URL_REPORTS}?tab=products`)
        }
      },
    },
    computed: {
      isLoading () {
        return this.isStockCategoriesLoading || this.isReferencesLoading
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
    beforeRouteUpdate (to, from, next) {
      if (this.hasPrevRoute && this.isNavigatingBack && to.name === from.name) {
        this.$router.go(-1)
        next(new Error(routerConstant.REDIRECT_TO_SELF))
        return
      }

      next()
    },
  }
</script>
<style scoped lang="scss">
</style>
