// General
export const API_ERROR_GENERAL_INVALID_INPUT = 'General_InvalidInput'
export const API_ERROR_GENERAL_EMPTY_MANDATORY_FIELD = 'General_EmptyMandatoryField'

// Identity
export const API_ERROR_IDENTITY_DUPLICATE_USERNAME = 'DuplicateUserName'
export const API_ERROR_IDENTITY_DUPLICATE_EMAIL = 'DuplicateEmail'
export const API_ERROR_IDENTITY_PASSWORD_MISMATCH = 'PasswordMismatch'

// User
export const API_ERROR_USER_PASSWORD_LENGTH_INVALID = 'User_PasswordLengthInvalid'
export const API_ERROR_USER_INCORRECT_PASSWORD_CONFIRMATION = 'User_IncorrectPasswordConfirmation'
export const API_ERROR_USER_PHONE_NUMBER_CONTAINS_OTHER_THAN_DIGIT = 'User_PhoneNumberContainsOtherThanDigit'

// Invoice
export const API_ERROR_INVOICE_DUPLICATE_INVOICE_ITEM = 'SellInvoice_DuplicateInvoiceItemId'
export const API_ERROR_INVOICE_DUPLICATE_INVOICE_TAX_ITEM = 'SellInvoice_DuplicateInvoiceItemTaxItemId'

// Purchase
export const API_ERROR_PURCHASE_DUPLICATE_INVOICE_TAX_ITEM = 'PurchaseInvoice_DuplicateInvoiceItemTaxItemId'

// Customer
export const API_ERROR_CUSTOMER_DUPLICATE_ID_NO = 'Customer_DuplicateIdentifierNo'
export const API_ERROR_CUSTOMER_DUPLICATE_ID_DETAILS = 'Customer_DuplicateIdentifierDetails'

// Supplier
export const API_ERROR_SUPPLIER_DUPLICATE_ID_DETAILS = 'Supplier_DuplicateIdentifierDetails'

// Tray
export const API_ERROR_PRODUCTTRAY_PRODUCT_ALREADY_REGISTERED_IN_TRAY = 'ProductTray_ProductAlreadyRegisteredInTray'
export const API_ERROR_PRODUCTTRAY_INVALID_TRAY_STOCK_QUANTITY = 'ProductTray_InvalidTrayStockQuantity'
export const API_ERROR_PRODUCTTRAY_DUPLICATE_TRAY_ITEM_PRODUCT_ID = 'ProductTray_DuplicateTrayItemProductId'

// Product Transfer
export const API_ERROR_PRODUCTTRANSFER_INVALID_PRODUCT_STOCK_QUANTITY = 'ProductTransfer_InvalidProductTransferStockQuantity'
