<template>
  <div :id="id" class="section-invoice-detail-payment">
    <h6>Pembayaran</h6>
    <form v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
      <div class="row">
        <div class="col-md-6">
          <fg-input label="Oleh pelanggan" 
                    v-model="model.customerName"
                    disabled
                    required></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input label="Nomor pembayaran" 
                    v-model="model.transactionNo"
                    v-if="isView"
                    disabled
                    required></fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <fg-input label="Metode pembayaran" 
                    required
                    :disabled="isView"
                    :error="getError('Metode pembayaran')">
            <el-select class="kf select-default" 
                      v-model="model.paymentMethod"
                      filterable
                      v-validate="'required'"
                      :disabled="isView"
                      data-vv-name="Metode pembayaran">
              <el-option v-for="option in selects.paymentMethods"
                        class="kf select-default"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id">
              </el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input label="Tanggal & waktu pembayaran" 
                    required
                    :error="getError('Tanggal & waktu pembayaran')">
            <el-date-picker v-model="model.paymentDate" 
                            type="datetime" 
                            :disabled="isView"
                            placeholder="Pilih tanggal & waktu"
                            format="dd-MM-yyyy hh:mm A"
                            v-validate="'required'"
                            data-vv-name="Tanggal & waktu pembayaran"
                            :picker-options="dateTimePickerOptions">
            </el-date-picker>
          </fg-input>
        </div>
      </div>

      <el-table :data="model.sellInvoices" 
                header-row-class-name="text-primary" 
                class="mt-2 table-compact">
        <el-table-column type="index" width="35"></el-table-column>
        <el-table-column label="Nomor invoice" min-width="140" property="transactionNo" ></el-table-column>
        <el-table-column label="Jumlah" width="130" align="right">
          <template slot-scope="scope">
            <div>{{scope.row.totalAmount | currencyFormat}}</div>
          </template>
        </el-table-column>
      </el-table>

      <div class="row my-2">
        <div class="col-md-7"></div>
        <div class="col-md-5">
          <div class="row py-2">
            <div class="col-4 text-uppercase"><strong>Total</strong></div>
            <div class="col-8 text-right"><strong>{{(model.totalAmount || 0) | currencyFormat}}</strong></div>
          </div>
          <div class="row py-2" v-if="isNew">
            <div class="col-4 align-self-center">Bayar</div>
            <div class="col-8 text-right">
              <div class="d-flex justify-content-end align-items-start">
                <el-tooltip placement="top" content="Salin Total Bayar">
                  <p-button type="success" size="md" icon @click="setPayAmount(model.totalAmount)" class="rounded my-0 mr-1">
                    <i class="fa fa-clone"></i>
                  </p-button>
                </el-tooltip>
                <fg-input kind="money" 
                          v-model.number="model.payAmount"
                          v-validate="`required|min_value:${minimumPayment}|max_value:100000000000`"
                          data-vv-name="Bayar"
                          required
                          :disabled="isView"
                          :error="getError('Bayar')"
                          class="mb-0"
                          input-classes="text-right"
                          @change="calculateChangeAmount()">
                  <template slot="addonLeft">Rp</template>
                </fg-input>
              </div>
            </div>
          </div>
          <div class="row py-2" v-if="isView">
            <div class="col-4">Bayar</div>
            <div class="col-8 text-right">{{(model.payAmount || 0) | currencyFormat}}</div>
          </div>
          <div class="row py-2">
            <div class="col-4">Kembali</div>
            <div class="col-8 text-right">{{(model.changeAmount || 0) | currencyFormat}}</div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <p-button outline round @click="handleCancel" v-if="isNew">Batal</p-button>
        <p-button type="primary" round @click="validate" v-if="isNew">Bayar</p-button>
      </div>
    </form>
  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import _ from 'lodash'
  import { Select, Option, Loading, Table, TableColumn, DatePicker, Tooltip } from 'element-ui'
  import filterHelper from '../../../helpers/filterHelper'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import arrayHelper from '../../../helpers/arrayHelper'
  import * as paymentMethodEnum from '../../../enums/paymentMethodEnum'
  import alertHelper from '../../../helpers/alertHelper'

  Vue.use(Loading)

  export default {
    inheritAttrs: false,
    name: 'invoice-detail-payment',
    components: {
      [Option.name]: Option,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [Tooltip.name]: Tooltip
    },
    props: {
      id: { type: String },
      newMode: { type: Boolean }, 
      viewMode: { type: Boolean }, 
      customer: { type: Object },
      invoices: { type: Array },
      payment: { type: Object },
    },
    data () {
      return {
        isSaveLoading: false,
        isReferencesLoading: false,
        isNew: false,
        isView: false,
        model: {
          id: null,
          transactionNo: '',
          customerId: null,
          customerName: '',
          paymentMethod: '',
          paymentDate: moment(),
          sellInvoices: [],
          sellInvoiceIds: [],
          totalAmount: null,
          payAmount: null,
          changeAmount: null,
          createdByFullName: '',
          createdAt: ''
        },
        selects: {
          paymentMethods: [],
        },
        dateTimePickerOptions: {
          firstDayOfWeek: 1,
          disabledDate (time) {
            return time.getTime() > moment()
          },
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = this.newMode
        this.isView = this.viewMode
        
        this.fetchReferences()

        if (this.isNew) {
          this.model.customerId = this.customer ? this.customer.id : null
          this.model.customerName = this.customer ? this.customer.name : ''

          this.model.sellInvoices = this.invoices ? _.cloneDeep(this.invoices) : []
          this.calculateTotalAmount()
        }
        else if (this.isView) {
          if (this.payment) {
            this.model = _.cloneDeep(Object.assign(this.model, _.cloneDeep(this.payment)))
          }
          
          this.model.sellInvoices = this.invoices ? _.cloneDeep(this.invoices) : []
        }
      },
      calculateTotalAmount () {
        this.model.totalAmount = this.model.sellInvoices.reduce((val, i) => val + i.totalAmount, 0)
      },
      calculateChangeAmount () {
        const changeAmount = this.model.payAmount - this.model.totalAmount
        this.model.changeAmount = changeAmount > 0 ? changeAmount : 0
      },
      setPayAmount (amount) {
        this.model.payAmount = amount
        this.calculateChangeAmount()
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      handleCancel () {
        this.$emit('cancelled')
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.savePaymentConfirmation()
        })
      },
      savePaymentConfirmation () {
        const title = 'Konfirmasi Pembayaran'
        const body = `
          <div>
            <div class="my-1">Total: ${filterHelper.currencyFormat(this.model.totalAmount)}</div>
            <div class="my-1">Bayar: ${filterHelper.currencyFormat(this.model.payAmount)}</div>
            <div class="my-1"><strong>Kembali: ${filterHelper.currencyFormat(this.model.changeAmount)}</strong></div>
          </div>`
        const onConfirm = () => { this.savePayment() }

        alertHelper.confirmHtml(title, body, alertHelper.typeEnums.warning, 'Bayar', 'Batal', onConfirm)
      },
      savePayment () {
        this.isSaveLoading = true

        this.model.sellInvoiceIds = this.model.sellInvoices ?
          this.model.sellInvoices.map(i => i.id) : []

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_INVOICES_PAYMENTS, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }
              
              this.$emit('payment-created', response.data.invoicePayment)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_REFERENCES_PAYMENT_METHODS),
        ])
          .then(responses => {
            const pPaymentMethodsRes = responses[0]

            // Payment methods
            let paymentMethods = (pPaymentMethodsRes.data.isSuccess ? pPaymentMethodsRes.data.paymentMethods : [])
              .map(pm => {
                return {
                  id: pm, 
                  name: paymentMethodEnum.getLocal(pm)
                }
              })
            paymentMethods.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.paymentMethods = paymentMethods
            const defaultPaymentMethod = this.selects.paymentMethods.find(pm => pm.id === paymentMethodEnum.getDefault())
            this.model.paymentMethod = defaultPaymentMethod ? defaultPaymentMethod.id : ''

            this.isReferencesLoading = false
          })
          .catch(() => {
            this.isReferencesLoading = false
          })
      },
    },
    computed: {
      isLoading () {
        return this.isSaveLoading || this.isReferencesLoading
      },
      minimumPayment () {
        return this.model.totalAmount ? Math.floor(this.model.totalAmount) : 0
      }
    },
    filters: {
      ...filterHelper.spread
    },
  }
</script>
<style scoped lang="scss">
</style>
