import router from '../router/router'

export default new function () {
  this.safePush = (route) => {
    router.push(route)
      .catch(error => {
        if (error && error.name === 'NavigationDuplicated') {
          return
        }
        throw error
      })
  }
}
