<template>
  <div class="row">
    <div class="col-lg-8">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Tambah Pelanggan</h5>
          <h5 class="card-title" v-if="isView">Detail Pelanggan</h5>
          <h5 class="card-title" v-if="isEdit">Ubah Detail Pelanggan</h5>
        </template>
        <template slot="default">
          <customer-detail-form 
            :is-new="isNew"
            :is-view="isView"
            :is-edit="isEdit"
            @close="goToPrevPage">
          </customer-detail-form>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import { Card } from '../../UIComponents'
  import CustomerDetailForm from './CustomerDetailForm'
  import * as urlConstant from '../../../constants/urlConstant'

  export default {
    components: {
      Card,
      CustomerDetailForm,
    },
    data () {
      return {
        isNew: false,
        isView: false,
        isEdit: false,
        hasPrevRoute: false,
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false
        this.isEdit = false

        if (!this.$route.params.customerSlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else if (this.$route.path.includes('/edit/')) {
          this.isEdit = true
        }
        else {
          this.isNew = true
        }
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_CUSTOMERS)
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name));
    },
  }
</script>
<style scoped lang="scss">
</style>
