<template>
  <div class="row">
    <div class="col-lg-8">
      <card>
        <template slot="header">
          <h5 class="card-title">Akun Detail</h5>
        </template>
        <template slot="default">
          <div v-loading="isAccountLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            
            <div class="row">
              <div class="col-md-6">
                <fg-input label="Nama depan" v-model="model.firstName" disabled></fg-input>
              </div>

              <div class="col-md-6">
                <fg-input label="Nama belakang" v-model="model.lastName" disabled></fg-input>
              </div>
            </div>

            <fg-input label="Email" v-model="model.email" disabled type="email"></fg-input>

            <fg-input label="Alamat" disabled>
              <textarea class="form-control" 
                        rows="3" 
                        v-model="model.address" 
                        disabled></textarea>
            </fg-input>

            <fg-input label="Nomor telepon" disabled>
              <textarea class="form-control" 
                        rows="3" 
                        v-model="model.phoneNumbersStr" 
                        disabled></textarea>
            </fg-input>

          </div>
        </template>
      </card>

      <card>
        <template slot="header">
          <h5 class="card-title">Ubah Password</h5>
        </template>
        <template slot="default">
          <div v-loading="isSavingPassword" element-loading-background="rgba(255, 255, 255, 0.6)">
            <form>

              <fg-input label="Password lama" 
                        v-model="passwordModel.oldPassword"
                        type="password"
                        required
                        v-validate="'required|min:6|max:100'"
                        data-vv-name="Password lama"
                        :error="getError('Password lama')"></fg-input>

              <fg-input label="Password baru" 
                        v-model="passwordModel.newPassword"
                        type="password"
                        required
                        v-validate="'required|min:6|max:100'"
                        data-vv-name="Password baru"
                        data-vv-as="Password baru"
                        ref="newPassword"
                        :error="getError('Password baru')"></fg-input>

              <fg-input label="Ulangi password baru" 
                        v-model="passwordModel.newPasswordConfirm"
                        type="password"
                        required
                        v-validate="'required|confirmed:newPassword'"
                        data-vv-name="Ulangi password baru"
                        :error="getError('Ulangi password baru')"></fg-input>

              <div class="pull-right">
                <p-button type="primary" round @click="validateChangePasswordForm">Ubah Password</p-button>
              </div>

            </form>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { Loading } from 'element-ui'
  import { Card } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import alertHelper from '../../../helpers/alertHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'
  import { AUTH_CLEAR } from '../../../store/actions/auth'

  Vue.use(Loading)

  export default {
    components: {
      Card,
    },
    data () {
      return {
        isAccountLoading: false,
        isSavingPassword: false,
        model: {
          id: '',
          firstName: '',
          lastName: '',
          fullName: '',
          email: '',
          address: '',
          phoneNumbers: [],
          phoneNumbersStr: '',
          role: '',
        },
        passwordModel: {
          oldPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        }
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isAccountLoading = true
        this.axios.get(urlConstant.API_URL_ACCOUNT_DETAIL)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isAccountLoading = false
              return
            }
            
            this.model = response.data.user
            this.isAccountLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isAccountLoading = false
          })
      },
      validateChangePasswordForm () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.passwordModel, isValid)

          if (!isValid) {
            return
          }

          this.savePassword()
        })
      },
      savePassword () {
        this.isSavingPassword = true
        this.axios.post(urlConstant.API_URL_ACCOUNT_CHANGE_PASSWORD, this.passwordModel)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showChangePasswordFailedMessage(response.data)
                this.isSavingPassword = false
                return
              }

              this.showChangePasswordSuccessMessage()
              this.isSavingPassword = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSavingPassword = false
            })
      },
      showChangePasswordSuccessMessage () {
        const title = 'Password berhasil diubah!'
        const content = 'Silakan login ulang dengan password baru kamu.'
        const onConfirm = () => { this.logout() }

        alertHelper.basic(title, content, alertHelper.typeEnums.success, 'Login', onConfirm)
      },
      showChangePasswordFailedMessage (apiResponse) {
        if (!apiResponse || apiResponse.apiError || !apiResponse.errors || !apiResponse.errors.length) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.errors.includes(apiErrorCodeConstant.API_ERROR_IDENTITY_PASSWORD_MISMATCH)) {
          showAlert('Password lama salah.')
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      logout() {
        this.$store
          .dispatch(AUTH_CLEAR)
          .then(() => {
            this.$router.push(urlConstant.WEB_URL_LOGIN)
          })
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
    },
  }
</script>
<style scoped lang="scss">
</style>
