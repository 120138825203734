import moment from 'moment'

export default new function () {
  this.getReportDateRangePickerOptions = function () {
    return {
      firstDayOfWeek: 1,
      disabledDate (time) {
        return time.getTime() > moment()
      },
      shortcuts: [
        {
          text: 'Hari ini',
          onClick (picker) {
            const start = moment().startOf('day')
            const end = moment().endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Kemarin',
          onClick (picker) {
            const start = moment().add(-1, 'd').startOf('day')
            const end = moment().add(-1, 'd').endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Minggu ini',
          onClick (picker) {
            const start = moment().startOf('isoWeek').startOf('day')
            const end = moment().endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Minggu lalu',
          onClick (picker) {
            const start = moment().add(-1, 'w').startOf('isoWeek').startOf('day')
            const end = moment().add(-1, 'w').endOf('isoWeek').endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Bulan ini',
          onClick (picker) {
            const start = moment().startOf('month').startOf('day')
            const end = moment().endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Bulan lalu',
          onClick (picker) {
            const start = moment().add(-1, 'M').startOf('month').startOf('day')
            const end = moment().add(-1, 'M').endOf('month').endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Tahun ini',
          onClick (picker) {
            const start = moment().startOf('year').startOf('day')
            const end = moment().endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Tahun lalu',
          onClick (picker) {
            const start = moment().add(-1, 'y').startOf('year').startOf('day')
            const end = moment().add(-1, 'y').endOf('year').endOf('day')
            picker.$emit('pick', [start, end])
          }
        },
      ]
    }
  }
}
