<template>
  <form v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">

    <fg-input label="Nama produk" 
              v-model="model.name"
              placeholder="Contoh: Cincin Ukir Mata 5 Merah Hitam 5.75gr"
              :disabled="isView"
              required
              @change="onNameChange()"
              v-validate="'required|max:150'"
              data-vv-name="Nama produk"
              :error="getError('Nama produk')"></fg-input>

    <fg-input label="Deskripsi" :disabled="isView">
      <textarea class="form-control" 
                rows="3" 
                placeholder="Contoh: Model ukir bali dengan permata mirah dikelilingi permata hitam."
                v-model="model.description" 
                @change="onDescriptionChange()"
                :disabled="isView"></textarea>
    </fg-input>

    <div class="row">
      <div class="col-md-6">

        <fg-input type="number" 
                  onwheel="this.blur()"
                  label="Karat"
                  required
                  v-model.number="model.carat"
                  v-validate="'required|min_value:0|max_value:24'"
                  data-vv-name="Karat"
                  :disabled="isView"
                  :error="getError('Karat')">
          <template slot="addonRight">karat</template>
        </fg-input>

      </div>
      <div class="col-md-6">

        <fg-input type="number" 
                  onwheel="this.blur()"
                  label="Persentase karat"
                  required
                  v-model.number="model.caratPercentage"
                  v-validate="'required|min_value:0|max_value:100'"
                  data-vv-name="Persentase karat"
                  :disabled="isView"
                  :error="getError('Persentase karat')">
          <template slot="addonRight">%</template>
        </fg-input>

      </div>
      <div class="col-md-6">

        <fg-input type="number"
                  label="Berat"
                  required
                  onwheel="this.blur()" 
                  v-model.number="model.weight"
                  v-validate="'required|min_value:0'"
                  data-vv-name="Berat"
                  :disabled="isView"
                  :error="getError('Berat')">
          <template slot="addonRight">gr</template>
        </fg-input>

      </div>
      <div class="col-md-6">

        <fg-input type="number"
                  label="Ukuran"
                  onwheel="this.blur()" 
                  v-model.number="model.size"
                  v-validate="'min_value:0'"
                  data-vv-name="Ukuran"
                  :disabled="isView"
                  :error="getError('Ukuran')">
        </fg-input>

      </div>
      <div class="col-md-6">

        <fg-input type="number" 
                  onwheel="this.blur()"
                  label="Persentase tukar barang"
                  v-model.number="model.exchangePercentage"
                  v-validate="'min_value:0|max_value:120'"
                  data-vv-name="Persentase tukar barang"
                  :disabled="isView"
                  :error="getError('Persentase tukar barang')">
          <template slot="addonRight">%</template>
        </fg-input>

      </div>
      <div class="col-md-6">

        <fg-input label="Bahan" 
                  required 
                  :disabled="isView"
                  :error="getError('Bahan')">
          <el-select class="kf select-default" 
                    v-model="model.materialId"
                    v-validate="'required'"
                    :disabled="isView"
                    data-vv-name="Bahan"
                    @change="onMaterialhange(model)"
                    filterable>
            <el-option v-for="option in selects.materials"
                      class="kf select-default"
                      :value="option.id"
                      :label="option.name"
                      :key="option.id">
            </el-option>
          </el-select>
        </fg-input>

      </div>
      <div class="col-md-6">

        <fg-input label="Kategori" 
                  required 
                  :disabled="isView"
                  :error="getError('Kategori')">
          <el-select class="kf select-default" 
                    v-model="model.categoryId"
                    v-validate="'required'"
                    :disabled="isView"
                    data-vv-name="Kategori"
                    filterable>
            <el-option v-for="option in selects.categories"
                      class="kf select-default"
                      :value="option.id"
                      :label="option.name"
                      :key="option.id">
            </el-option>
          </el-select>
        </fg-input>

      </div>
    </div>

    <fg-input kind="money" 
              v-model.number="model.unitPurchasePrice"
              v-validate="'required|min_value:0|max_value:100000000000'"
              data-vv-name="Harga perolehan"
              label="Harga perolehan (per unit)"
              required
              :disabled="isView"
              :error="getError('Harga perolehan')">
      <template slot="addonLeft">Rp</template>
    </fg-input>

    <div class="row">
      <div class="col-md-6">
        
        <fg-input type="number" 
                  onwheel="this.blur()"
                  label="Jumlah stok" 
                  required
                  v-model.number="model.unitAmount"
                  v-validate="'required|min_value:0'"
                  data-vv-name="Jumlah stok"
                  :disabled="isView"
                  :error="getError('Jumlah stok')"></fg-input>

      </div>
      <div class="col-md-6">
        
        <fg-input label="Satuan stok" 
                  required
                  disabled
                  :error="getError('Satuan stok')">
          <el-select class="kf select-default" 
                    v-model="model.unitId"
                    filterable
                    v-validate="'required'"
                    disabled
                    data-vv-name="Satuan stok">
            <el-option v-for="option in selects.units"
                      class="kf select-default"
                      :value="option.id"
                      :label="option.description"
                      :key="option.id">
            </el-option>
          </el-select>
        </fg-input>

      </div>
    </div>

    <fg-input label="Catatan internal" :disabled="isView">
      <textarea class="form-control" 
                rows="3" 
                placeholder="Contoh: Perlu diperbaiki dan dibersihkan."
                v-model="model.internalNote" 
                :disabled="isView"></textarea>
    </fg-input>

    <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
      <p-button outline round @click="closeForm()" v-if="isView">Kembali</p-button>
      <p-button outline round @click="closeForm()" v-if="isNew || isEdit">Batal</p-button>
      <p-button type="primary" 
                round
                @click="validate()"
                v-if="isNew">Tambahkan Produk</p-button>
      <p-button type="primary" 
                round
                @click="validate()"
                v-if="isEdit">Simpan Perubahan</p-button>
    </div>

  </form>
</template>
<script>
  import Vue from 'vue'
  import { Loading, Select, Option } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import arrayHelper from '../../../helpers/arrayHelper'

  Vue.use(Loading)

  export default {
    inheritAttrs: false,
    name: 'purchase-detail-product-form',
    components: {
      [Option.name]: Option,
      [Select.name]: Select,
    },
    props: {
      isModal: { type: Boolean },
      isNew: { type: Boolean },
      isView: { type: Boolean },
      isEdit: { type: Boolean },
    },
    data () {
      return {
        isReferencesLoading: false,
        initialModel: {},
        model: {
          id: null,
          name: '',
          code: '',
          description: '',
          weight: null,
          size: null,
          carat: null,
          caratPercentage: null,
          exchangePercentage: null,
          categoryId: null,
          materialId: null,
          materialName: '',
          unitPurchasePrice: null,
          unitId: 1,
          unitAmount: 1,
          internalNote: '',
        },
        selects: {
          units: [],
          categories: [],
          materials: [],
        }
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.fetchReferences()
      },
      fetchReferences () {
        this.isReferencesLoading = true;

        Promise.all([
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_CATEGORIES),
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_MATERIALS),
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_UNITS)
        ])
          .then(responses => {
            const pCatRes = responses[0]
            const pMatRes = responses[1]
            const pUnitRes = responses[2]

            // Product categories
            let categories = pCatRes.data.isSuccess ? pCatRes.data.productCategories : []
            categories.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.categories = categories

            // Product materials
            let materials = pMatRes.data.isSuccess ? pMatRes.data.productMaterials : []
            materials.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.materials = materials

            // Product units
            let units = pUnitRes.data.isSuccess ? pUnitRes.data.productUnits : []
            units.sort((a, b) => arrayHelper.stringCompare(a.description, b.description))
            this.selects.units = units

            this.isReferencesLoading = false;
          })
          .catch(() => {
            this.isReferencesLoading = false;
          })
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.closeForm(this.model)
        })
      },
      closeForm (product) {
        this.$emit('close', product)
      },
      getError (fieldName) {
        const errorMsg = this.errors.first(fieldName)
        return errorMsg
      },
      onNameChange () {
        if (!this.model.name) {
          return
        }

        this.model.name = this.model.name.trim()
        this.model.name = `${this.model.name.charAt(0).toUpperCase()}${this.model.name.substring(1)}`
      },
      onDescriptionChange () {
        if (!this.model.description) {
          return
        }

        this.model.description = this.model.description.trim()
        this.model.description = `${this.model.description.charAt(0).toUpperCase()}${this.model.description.substring(1)}`
      },
      onMaterialhange (item) {
        if (!item.materialId) {
          item.materialName = ''
          return
        }

        const material = this.selects.materials.find(x => x.id === item.materialId)

        item.materialName = material ? material.name : ''
      },
    },
    computed: {
      isLoading () {
        return this.isReferencesLoading
      },
    },
  }
</script>
<style scoped lang="scss">
</style>
