<template>
  <div class="row">
    <div class="col-lg-8">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Tambah Karyawan</h5>
          <h5 class="card-title" v-if="isView">Detail Karyawan</h5>
          <h5 class="card-title" v-if="isEdit">Ubah Detail Karyawan</h5>
        </template>
        <template slot="default">
          <form v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            
            <div class="d-flex flex-wrap justify-content-md-end justify-content-center" v-if="isView">
              <p-button outline round @click="goToPrevPage">Kembali</p-button>
            </div>

            <div class="row">
              <div class="col-md-6">

                <fg-input label="Nama depan" 
                      v-model="model.firstName"
                      :disabled="isView"
                      required
                      v-validate="'required'"
                      data-vv-name="Nama depan"
                      :error="getError('Nama depan')"></fg-input>

              </div>
              <div class="col-md-6">

                <fg-input label="Nama belakang" 
                          v-model="model.lastName"
                          :disabled="isView"
                          required
                          v-validate="'required'"
                          data-vv-name="Nama belakang"
                          :error="getError('Nama belakang')"></fg-input>

              </div>
            </div>

            <fg-input label="Email" 
                      v-model="model.email"
                      :disabled="isView || isEdit"
                      type="email"
                      required
                      v-validate="'required|email'"
                      data-vv-name="Email"
                      :error="getError('Email')"></fg-input>

            <fg-input label="Password" 
                      v-model="model.password"
                      v-if="isNew"
                      type="password"
                      required
                      v-validate="'required|min:6|max:100'"
                      data-vv-name="Password"
                      data-vv-as="Password"
                      ref="password"
                      :error="getError('Password')"></fg-input>

            <fg-input label="Ulangi password" 
                      v-model="model.confirmPassword"
                      v-if="isNew"
                      type="password"
                      required
                      v-validate="'required|confirmed:password'"
                      data-vv-name="Ulangi password"
                      :error="getError('Ulangi password')"></fg-input>
                      
            <fg-input label="Akses" 
                      required 
                      :disabled="isView || !canEditRole"
                      :error="getError('Akses')">
              <el-select class="kf select-default" 
                        v-model="model.role"
                        v-validate="'required'"
                        :disabled="isView || !canEditRole"
                        data-vv-name="Akses"
                        filterable>
                <el-option v-for="option in filteredRoles"
                          class="kf select-default"
                          :value="option.id"
                          :label="option.name"
                          :key="option.id">
                </el-option>
              </el-select>
            </fg-input>

            <fg-input label="Alamat" :disabled="isView">
              <textarea class="form-control" 
                        rows="3" 
                        v-model="model.address" 
                        :disabled="isView"></textarea>
            </fg-input>

            <fg-input label="Nomor telepon" :disabled="isView"
                      :error="getError('Nomor telepon')">
              <textarea class="form-control" 
                        rows="3" 
                        placeholder="Contoh: 081XXX, 082XXX"
                        v-model="model.phoneNumbersStr" 
                        :disabled="isView"
                        v-validate="{ regex:/^([0-9 ,]+)$/ }"
                        data-vv-name="Nomor telepon"></textarea>
              <template slot="infoBlock" v-if="isNew || isEdit">
                <div class="small mt-1">
                  Jika lebih dari satu nomor, pisahkan dengan tanda koma.
                </div>
              </template>
            </fg-input>

            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage" v-if="isView">Kembali</p-button>
              <p-button outline round @click="goToPrevPage" v-if="isNew || isEdit">Batal</p-button>
              <p-button type="primary" 
                        round
                        @click="validate"
                        v-if="isNew">Tambahkan Karyawan</p-button>
              <p-button type="primary" 
                        round
                        @click="validate"
                        v-if="isEdit">Simpan Perubahan</p-button>
            </div>

          </form>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import base64url from 'base64url'
  import _ from 'lodash'
  import { Select, Option, Loading } from 'element-ui'
  import { Card } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import arrayHelper from '../../../helpers/arrayHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'
  import * as userRoleEnum from '../../../enums/userRoleEnum'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import alertHelper from '../../../helpers/alertHelper'

  Vue.use(Loading)

  export default {
    components: {
      Card,
      [Option.name]: Option,
      [Select.name]: Select,
    },
    data () {
      return {
        isEmployeeLoading: false,
        isReferencesLoading: false,
        isSaveLoading: false,
        isNew: false,
        isView: false,
        isEdit: false,
        hasPrevRoute: false,
        initialModel: {},
        model: {
          id: '',
          firstName: '',
          lastName: '',
          fullName: '',
          email: '',
          password: '',
          confirmPassword: '',
          address: '',
          phoneNumbers: [],
          phoneNumbersStr: '',
          role: '',
        },
        selects: {
          roles: [],
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false
        this.isEdit = false

        if (!this.$route.params.employeeSlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else if (this.$route.path.includes('/edit/')) {
          this.isEdit = true
        }
        else {
          this.isNew = true
        }
        
        this.fetchReferences()

        if (this.isNew) {
          return
        }

        const employeeId = base64url.decode(this.$route.params.employeeSlug)

        this.isEmployeeLoading = true
        this.axios.get(`${urlConstant.API_URL_EMPLOYEES}/${employeeId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isEmployeeLoading = false
              return
            }

            if (!response.data.employee) {
              alertHelper.basic('Karyawan tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              this.isEmployeeLoading = false
              return
            }

            this.initialModel = _.cloneDeep(response.data.employee)
            this.model = response.data.employee
            
            this.isEmployeeLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isEmployeeLoading = false
          })
      },
      getError (fieldName) {
        const errorMsg = this.errors.first(fieldName)

        if (errorMsg && fieldName === "Nomor telepon") {
          return "Nomor telepon hanya boleh berisi angka dan tanda koma sebagai pemisah."
        }

        return errorMsg
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.saveEmployee()
        })
      },
      saveEmployee () {
        this.isSaveLoading = true

        if (this.model.phoneNumbersStr) {
          this.model.phoneNumbers = this.model.phoneNumbersStr.split(",")
        }

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_EMPLOYEES, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.employee)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        } else {
          this.axios.post(`${urlConstant.API_URL_EMPLOYEES}/${this.model.id}`, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.employee)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      showSaveSuccessMessage (employee) {
        const title = this.isNew ? 'Karyawan berhasil ditambahkan!' : 'Detail karyawan berhasil diubah!'
        const content = `Nama karyawan: ${employee.fullName}`

        alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
      },
      showSaveFailedMessage (apiResponse) {
        if (!apiResponse || apiResponse.apiError || !apiResponse.errors || !apiResponse.errors.length) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.errors.includes(apiErrorCodeConstant.API_ERROR_IDENTITY_DUPLICATE_USERNAME) || 
            apiResponse.errors.includes(apiErrorCodeConstant.API_ERROR_IDENTITY_DUPLICATE_EMAIL)) {
          showAlert(`Email <i>${ this.model.email }</i> sudah terdaftar.`)
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      fetchReferences () {
        this.isReferencesLoading = true;

        Promise.all([
          this.axios.get(urlConstant.API_URL_ACCOUNT_ROLES)
        ])
          .then(responses => {
            const pRolRes = responses[0]

            // Roles
            let roles = (pRolRes.data.isSuccess ? pRolRes.data.roles : [])
              .map(x => {
                return {
                  id: x, 
                  name: userRoleEnum.getLocal(x)
                }
              })
            roles.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.roles = roles
            
            this.isReferencesLoading = false;
          })
          .catch(() => {
            this.isReferencesLoading = false;
          })
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_EMPLOYEES)
        }
      },
    },
    computed: {
      isLoading () {
        return this.isEmployeeLoading || this.isReferencesLoading || this.isSaveLoading
      },
      filteredRoles () {
        if (this.isView) {
          return this.selects.roles
        }

        const userRole = this.$store.getters.getUserRole

        let result = this.selects.roles.filter(r => r.id !== userRoleEnum.SUPERADMIN && r.id !== userRole)

        if (userRole === userRoleEnum.ADMIN) {
          result = result.filter(r => r.id !== userRoleEnum.OWNER)
        }
        else if (userRole === userRoleEnum.MANAGER) {
          result = result.filter(r => r.id !== userRoleEnum.OWNER)
          result = result.filter(r => r.id !== userRoleEnum.ADMIN)
        }

        return result
      },
      canEditRole () {
        const currentUser = this.$store.getters.getUser

        if (!currentUser) {
          return false
        }

        return currentUser.id != this.model.id
      }
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name));
    },
  }
</script>
<style scoped>
</style>
