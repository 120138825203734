<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Buat Pembelian</h5>
          <h5 class="card-title" v-if="isView">Detail Pembelian</h5>
        </template>
        <template slot="default">
          <h6>Detail</h6>
          <div class="pt-1" v-if="isView">
            <div class="alert" :class="getStatusAlertColor(model.status)">
              Status: <strong class="text-uppercase">{{getStatusLocal(model.status)}}</strong>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage()" v-if="canBack">Kembali</p-button>
          </div>

          <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <fg-input 
                    label="Vendor" 
                    v-if="isNew"
                    required 
                    :disabled="isView"
                    :error="getError('Vendor')">
                    <el-select 
                      class="kf select-default" 
                      ref="supplierDropdown"
                      v-model="model.supplierId"
                      v-validate="'required'"
                      :disabled="isView"
                      data-vv-name="Vendor"
                      placeholder="Cari vendor"
                      @change="onSupplierChange(model)"
                      filterable
                      remote
                      :clearable="selects.initialSuppliers[0] && model.supplierId !== selects.initialSuppliers[0].id"
                      :remote-method="fetchSuppliers"
                      :loading="isFetchingSuppliers">
                      <el-option 
                        v-for="option in selects.suppliers"
                        class="kf select-default"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id">
                        <div class="d-flex justify-content-between">
                          <div v-html="$options.filters.highlight(option.name, supplierSearchText)"></div>
                          <div class="ml-3 small text-muted" v-html="getSupplierDescription(option, supplierSearchText)"></div>
                        </div>
                      </el-option>
                      <template slot="empty">
                        <p class="el-select-dropdown__empty">
                          Tidak ada data<br/>
                          <button type="button" class="btn btn-round btn-warning mb-1" @click="openAddSupplierModal()">Tambah Vendor</button>
                        </p>
                      </template>
                    </el-select>
                  </fg-input>
                  <fg-input 
                    label="Vendor" 
                    v-model="model.supplierName"
                    v-if="isView"
                    disabled
                    required></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input 
                    label="Nomor pembelian" 
                    v-model="model.transactionNo"
                    v-if="isView"
                    disabled
                    required></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Petugas pembelian" v-model="model.createdByFullName" disabled required></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Tanggal & waktu transaksi" :value="model.createdAt | dateTimeFormat" disabled required></fg-input>
                </div>
              </div>

              <div v-if="isDefaultSupplierSelected" class="row">
                <div class="col-md-6">
                  <fg-input label="Nama penjual" 
                            v-model="model.patronName"
                            :disabled="isView"
                            v-validate="'max:100'"
                            data-vv-name="Nama penjual"
                            :error="getError('Nama penjual')"></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Nomor telepon penjual" 
                            v-model="model.patronPhone"
                            :disabled="isView"
                            v-validate="'max:100'"
                            data-vv-name="Nomor telepon penjual"
                            :error="getError('Nomor telepon penjual')"></fg-input>
                </div>
              </div>

              <div v-if="isDefaultSupplierSelected" class="row">
                <div class="col-md-6">
                  <fg-input label="Alamat penjual" :disabled="isView">
                    <textarea class="form-control" 
                              rows="3" 
                              v-model="model.patronAddress" 
                              :disabled="isView"></textarea>
                  </fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <fg-input label="Catatan untuk vendor" :disabled="isView">
                    <textarea 
                      class="form-control" 
                      rows="3"
                      v-model="model.externalNote" 
                      :disabled="isView"></textarea>
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Memo internal" :disabled="isView">
                    <textarea 
                      class="form-control" 
                      rows="3"
                      v-model="model.internalNote" 
                      :disabled="isView"></textarea>
                  </fg-input>
                </div>
              </div>

              <h6 class="mt-3">Produk</h6>

              <div class="d-flex justify-content-md-end justify-content-center mt-2">
                <p-button round type="primary" @click="openAddProductModal()" v-if="isNew">
                  <i slot="label" class="nc-icon nc-simple-add"></i>
                  &nbsp;Tambah Produk
                </p-button>
              </div>

              <div class="d-flex justify-content-end mt-2">
                <p-checkbox v-model="model.taxInclusive" 
                  disabled 
                  text-left>
                  Harga termasuk pajak
                </p-checkbox>
              </div>

              <div class="section-items" :class="{'section-items-edit-mode': isNew}">
                <el-table 
                  :data="model.items" 
                  header-row-class-name="text-primary" 
                  class="table-compact" 
                  ref="itemsTable"
                  @current-change="onTableSelectionChange"
                  :highlight-current-row="isNew">
                  <el-table-column label="No." type="index" width="50" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-index">{{scope.$index + 1}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Produk" min-width="150" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div v-if="scope.row.product.code">{{scope.row.product.code}}</div>
                      {{scope.row.product.name}}
                      <br/>
                      {{scope.row.product.materialName}} {{scope.row.product.carat}}k / {{scope.row.product.caratPercentage}}%
                      <br/>
                      {{scope.row.product.weight}}gr
                    </template>
                  </el-table-column>
                  <el-table-column label="Catatan" min-width="150" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      {{scope.row.note || '-'}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Qty" width="90" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      {{scope.row.quantity | numberFormat}}{{(scope.row.quantity || scope.row.quantity === 0) && scope.row.productUnitName ? ` ${scope.row.productUnitName}` : ''}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Harga satuan" width="150" align="right" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      {{scope.row.unitPurchasePrice | currencyFormat}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Pajak" width="135" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      <div class="cell-edit">
                        <fg-input 
                          :disabled="isView || !scope.row.product"
                          class="mb-0">
                          <el-select 
                            class="kf select-default" 
                            v-model="scope.row.taxes[0].taxItemId"
                            placeholder="Pilih pajak"
                            @change="onTaxItemChange(scope.row)"
                            clearable
                            filterable
                            :disabled="isView || !scope.row.product">
                            <el-option 
                              v-for="option in selects.taxItems"
                              class="kf select-default"
                              :value="option.id"
                              :label="option.name"
                              :key="option.id">
                              <div class="d-flex justify-content-between">
                                <div>{{ option.name }}</div>
                                <div class="ml-3 small text-muted">{{ option.description }}</div>
                              </div>
                            </el-option>
                          </el-select>
                        </fg-input>
                      </div>
                      <div class="cell-view">{{scope.row.taxes[0].taxItemName || '-'}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Jumlah" width="150" align="right" class-name="vertical-align-top">
                    <template slot-scope="scope">
                      {{scope.row.displaySubTotalAmount | currencyFormat}}
                    </template>
                  </el-table-column>
                  <el-table-column class-name="action-buttons td-actions" align="right" width="80" v-if="isNew">
                    <template slot-scope="scope">
                      <el-tooltip v-if="selectedItem && scope.row.product" placement="top" content="Selesai Edit" class="cell-edit">
                        <p-button type="success" size="sm" icon @click.stop="selectTableItem()" class="rounded">
                          <i class="fa fa-check"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip v-if="scope.row.product" placement="top" content="Keluarkan Produk">
                        <p-button type="danger" size="sm" icon class="rounded" @click.stop="removeItem(scope.row, scope.$index)">
                          <i class="fa fa-times"></i>
                        </p-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="row my-2">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                  <div class="row py-2" v-if="model.taxGroups.length">
                    <div class="col-6">Subtotal</div>
                    <div class="col-6 text-right">{{(model.displaySubTotalAmount || 0) | currencyFormat}}</div>
                  </div>

                  <template v-if="model.taxGroups.length && model.taxInclusive">
                    <div class="row px-2 pb-2 small text-muted">
                      <div class="col-12">Sudah termasuk:</div>
                      <div class="col-12">
                        <div v-for="(taxGroup, index) in model.taxGroups" :key="index" class="row">
                          <div class="col-6">{{taxGroup.name}}<span v-if="!itemsHaveSameTax">*</span></div>
                          <div class="col-6 text-right">{{taxGroup.amount | currencyFormat}}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="model.taxGroups.length && !model.taxInclusive">
                    <div v-for="(taxGroup, index) in model.taxGroups" :key="index" class="row py-2">
                      <div class="col-6">{{taxGroup.name}}<span v-if="!itemsHaveSameTax">*</span></div>
                      <div class="col-6 text-right">{{taxGroup.amount | currencyFormat}}</div>
                    </div>
                  </template>

                  <div class="row py-2" v-if="model.taxGroups.length">
                    <div class="col-12">
                      <div class="border-top"></div>
                    </div>
                  </div>

                  <div class="row py-2">
                    <div class="col-6 text-uppercase"><strong>Total</strong></div>
                    <div class="col-6 text-right"><strong>{{(model.totalAmount || 0) | currencyFormat}}</strong></div>
                  </div>

                  <div class="row py-2" v-if="model.taxGroups.length && !itemsHaveSameTax">
                    <div class="col-12 small text-muted">* Pajak untuk produk tertentu</div>
                  </div>
                </div>
              </div>
            </form>

            <purchase-detail-payment 
              id="purchase-detail-payment"
              class="pt-3" 
              v-if="isView && isShowPayment"
              :new-mode="!model.activePayment"
              :view-mode="!!model.activePayment"
              :supplier="{id: model.supplierId, name: model.supplierName}"
              :payment="model.activePayment"
              :invoices="[model]"
              @cancelled="goToPrevPage"
              @payment-created="showPaymentSuccessMessage">
            </purchase-detail-payment>

            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage()" v-if="canBack">Kembali</p-button>
              <p-button outline round @click="goToPrevPage()" v-if="isNew">Batal</p-button>
              <p-button type="danger" round @click="cancelPurchaseInvoice()" v-if="canCancelPurchaseInvoice">Batalkan Invoice Pembelian</p-button>
              <p-button type="danger" round @click="cancelPurchaseInvoicePayment()" v-if="canCancelPurchaseInvoicePayment">Batalkan Pembayaran</p-button>
              <p-button type="danger" round @click="cancelPaymentAndPurchaseInvoice()" v-if="canCancelPurchaseInvoicePayment">Batalkan Pembayaran & Invoice</p-button>
              <p-button type="primary" round @click="validate()" v-if="isNew">Buat Invoice Pembelian</p-button>
              <p-button type="primary" round @click="createPayment()" v-if="canCreatePayment">Buat Pembayaran</p-button>
              <p-button type="primary" round @click="print()" v-if="canPrint"><i class="fa fa-print"></i>&nbsp;&nbsp;Cetak</p-button>
            </div>

            <iframe 
              :src="printSrc"
              v-if="canPrint && printIndex > 0"
              :key="printIndex"
              frameBorder="0"
              ref="printFrame"
              class="print-frame">
            </iframe>
          </div>
        </template>
      </card>

      <!-- Add supplier modal -->
      <modal 
        :show.sync="modals.addSupplier" 
        modal-classes="kf-modal-lg"
        body-classes="kf-modal-body-compact"
        :allow-outside-click="false">
        <h5 slot="header" class="my-0">Tambah Vendor</h5>
        <supplier-detail-form
          v-if="modals.addSupplier"
          :is-modal="true"
          :is-new="true"
          @close="closeAddSupplierModal">
        </supplier-detail-form>
      </modal>

      <!-- Add product modal -->
      <modal 
        :show.sync="modals.addProduct" 
        modal-classes="kf-modal-lg"
        body-classes="kf-modal-body-compact"
        :allow-outside-click="false">
        <h5 slot="header" class="my-0">Tambah Produk</h5>
        <purchase-detail-product-form
          v-if="modals.addProduct"
          :is-modal="true"
          :is-new="true"
          @close="closeAddProductModal">
        </purchase-detail-product-form>
      </modal>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import base64url from 'base64url'
  import _ from 'lodash'
  import { Select, Option, Loading, Table, TableColumn, Tooltip } from 'element-ui'
  import { Card, Modal } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import arrayHelper from '../../../helpers/arrayHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'
  import * as taxItemTypeEnum from '../../../enums/taxItemTypeEnum'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import * as purchaseInvoiceStatusEnum from '../../../enums/purchaseInvoiceStatusEnum'
  import * as taxItemCodeEnum from '../../../enums/taxItemCodeEnum'
  import * as supplierSearchQueryOrderEnum from '../../../enums/supplierSearchQueryOrderEnum'
  import alertHelper from '../../../helpers/alertHelper'
  import mathHelper from '../../../helpers/mathHelper'
  import SupplierDetailForm from './SupplierDetailForm'
  import PurchaseDetailProductForm from './PurchaseDetailProductForm'
  import * as userRoleEnum from '../../../enums/userRoleEnum'
  import PurchaseDetailPayment from './PurchaseDetailPayment'
  import * as purchaseInvoicePaymentStatusEnum from '../../../enums/purchaseInvoicePaymentStatusEnum'
  import * as supplierConstant from '../../../constants/supplierConstant'

  Vue.use(Loading)

  const initialPurchaseTaxItem = {
    id: null,
    taxItemId: null,
    taxItemName: '',
    amount: null
  }

  export default {
    components: {
      Card,
      Modal,
      [Option.name]: Option,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
      SupplierDetailForm,
      PurchaseDetailProductForm,
      PurchaseDetailPayment,
    },
    data () {
      return {
        isPurchaseLoading: false,
        isReferencesLoading: false,
        isReferencesLoaded: false,
        isSaveLoading: false,
        isFetchingSuppliers: false,
        isNew: false,
        isView: false,
        isShowPayment: false,
        hasPrevRoute: false,
        selectedItem: null,
        defaultTaxItem: null,
        supplierSearchText: '',
        isPrinting: false,
        printSrc: '',
        printIndex: 0,
        model: {
          id: null,
          supplierId: null,
          supplierCode: '',
          supplierName: '',
          transactionNo: '',
          patronName: '',
          patronAddress: '',
          patronPhone: '',
          externalNote: '',
          internalNote: '',
          taxInclusive: true,
          items: [],
          createdAt: null,
          createdByFullName: '',
          status: '',
          displaySubTotalAmount: null,
          taxAmount: null,
          taxGroups: [],
          totalAmount: null,
          payments: [],
          activePayment: null,
        },
        selects: {
          taxItems: [],
          suppliers: [],
          initialSuppliers: [],
        },
        modals: {
          addSupplier: false,
          addProduct: false,
        },
        intervals: {
          createdAtFetcher: null
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false

        if (!this.$route.params.purchaseSlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else {
          this.isNew = true
        }

        this.isShowPayment = false

        if (!this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }

        if (this.isNew) {
          const currentUser = this.$store.getters.getUser

          this.model.createdByFullName = currentUser.fullName

          const updateCreatedAt = () => {
            this.model.createdAt = moment().format()
          }

          updateCreatedAt()
          this.intervals.createdAtFetcher = setInterval(() => {
            updateCreatedAt()
          }, 1000 * 60)

          return
        }

        this.printSrc = `${window.location.origin}${urlConstant.WEB_URL_PURCHASES_PRINT}/${this.$route.params.purchaseSlug}`

        const purchaseId = base64url.decode(this.$route.params.purchaseSlug)

        this.isPurchaseLoading = true
        this.axios.get(`${urlConstant.API_URL_PURCHASES}/${purchaseId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isPurchaseLoading = false
              return
            }
            
            if (!response.data.purchaseInvoice) {
              alertHelper.basic('Transaksi pembelian tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              this.isPurchaseLoading = false
              return
            }
            
            this.model = _.cloneDeep(Object.assign(this.model, response.data.purchaseInvoice))

            // Get the last active payment
            const paidPayments = this.model.payments && this.model.payments.length 
              ? this.model.payments.filter(p => p.status === purchaseInvoicePaymentStatusEnum.PAID) 
              : []
            this.model.activePayment = paidPayments.length ? paidPayments[paidPayments.length - 1] : null

            for (const item of this.model.items) {
              item.displaySubTotalAmount = item.product.unitPurchasePrice * item.product.unitAmount

              // Handle empty taxes
              if (!item.taxes || !item.taxes.length) {
                item.taxes = [_.cloneDeep(initialPurchaseTaxItem)]
              }
            }

            // Make sure we don't recalculate the tax here
            this.calculateAllItemAmounts(false)

            this.updatePaymentSectionState()
            this.checkForAutoPrint()
            
            this.isPurchaseLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isPurchaseLoading = false
          })
      },
      updatePaymentSectionState () {
        this.isShowPayment = this.model.status === purchaseInvoiceStatusEnum.PAID

        if (this.canCreatePayment && this.$route.query.payment === 'true') {
          this.createPayment()
        }
      },
      checkForAutoPrint () {
        if (this.canPrint && this.$route.query.print === 'true') {
          this.$nextTick(() => {
            this.print()
          })
        }
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.savePurchaseConfirmation()
        })
      },
      savePurchaseConfirmation () {
        // Make sure there are at least one item
        if (!this.model.items.find(i => !!i.product)) {
          const content = 'Belum ada produk yang ditambahkan ke pembelian.'
          alertHelper.basic(null, content, alertHelper.typeEnums.warning)
          return
        }

        const title = 'Konfirmasi Pembelian'
        const body = `
          <div>
            <div class="my-1">Jumlah barang: ${this.model.items.reduce((val, i) => val + i.quantity, 0)}</div>
            <div class="my-1"><strong>Total: ${filterHelper.currencyFormat(this.model.totalAmount)}</strong></div>
          </div>`
        const onConfirm = () => { this.savePurchase() }

        alertHelper.confirmHtml(title, body, alertHelper.typeEnums.warning, 'Buat Pembelian', 'Batal', onConfirm)
      },
      savePurchase () {
        this.isSaveLoading = true

        // Remove empty objects
        const modelCopy = _.cloneDeep(this.model)
        modelCopy.items = modelCopy.items.filter(i => !!i.product)
        for (const item of modelCopy.items) {
          item.taxes = item.taxes.filter(i => i.taxItemId)
        }

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_PURCHASES, modelCopy)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.purchaseInvoice)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      showSaveSuccessMessage (purchase) {
        const title = 'Transaksi pembelian berhasil dibuat!'
        const contentHtml = `Nomor pembelian: ${purchase.transactionNo}`
        const onConfirm = () => { this.$router.replace(`${urlConstant.WEB_URL_PURCHASES_VIEW}/${base64url.encode(purchase.id.toString())}?payment=true`) }
        const onCancel = () => { this.$router.replace(urlConstant.WEB_URL_PURCHASES) }

        alertHelper.confirmHtml(title, contentHtml, alertHelper.typeEnums.success, 'Proses Pembayaran', 'Daftar Pembelian', onConfirm, onCancel)
      },
      showPaymentSuccessMessage (invoicePayment) {
        const title = 'Pembayaran berhasil dibuat!'
        const content = `Nomor pembayaran: ${invoicePayment.transactionNo}`
        const onConfirm = () => { this.$router.replace(`${urlConstant.WEB_URL_PURCHASES_VIEW}/${base64url.encode(this.model.id.toString())}?print=true`) }
        const onCancel = () => { this.$router.replace(urlConstant.WEB_URL_PURCHASES) }

        alertHelper.confirm(title, content, alertHelper.typeEnums.success, 'Cetak', 'Daftar Pembelian', onConfirm, onCancel)
      },
      showSaveFailedMessage (apiResponse) {
        if (!apiResponse || !apiResponse.apiError) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_PURCHASE_DUPLICATE_INVOICE_TAX_ITEM) {
          showAlert('Jenis pajak yang sama diinput lebih dari sekali untuk salah satu jenis produk, mohon cek kembali daftar pajak untuk masing-masing produk.')
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_SUPPLIERS, { params: { 
            pageSize: 1,
            pageNumber: 1,
            order: supplierSearchQueryOrderEnum.CreatedAtAsc 
          }}),
          this.axios.get(urlConstant.API_URL_REFERENCES_TAX_ITEMS),
        ])
          .then(responses => {
            const pSupRes = responses[0]
            const pTaxRes = responses[1]

            // Initial suppliers
            const suppliers = pSupRes.data.isSuccess ? pSupRes.data.suppliers : []
            this.selects.initialSuppliers = suppliers
            this.selects.suppliers = _.cloneDeep(this.selects.initialSuppliers)
            if (this.selects.suppliers && this.selects.suppliers.length) {
              this.model.supplierId = this.selects.suppliers[0].id
              this.model.supplierCode = this.selects.suppliers[0].code
              this.model.supplierName = this.selects.suppliers[0].name
            }

            // Tax items
            const taxItems = pTaxRes.data.isSuccess ? pTaxRes.data.taxItems : []
            taxItems.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.taxItems = taxItems
            this.model.defaultTaxItem = taxItems.find(ti => ti.code === taxItemCodeEnum.getDefault())

            this.isReferencesLoading = false
          })
          .catch(() => {
            this.isReferencesLoading = false
          })
      },
      fetchSuppliers (query) {
        this.supplierSearchText = query

        if (!query) {
          this.selects.suppliers = _.cloneDeep(this.selects.initialSuppliers)
          return
        }

        const params = { 
          name: query, 
          phoneNumber: query,
          email: query,
          useOrOperator: true,
          order: supplierSearchQueryOrderEnum.NameAsc,
          pageSize: 10,
          pageNumber: 1 
        }

        this.isFetchingSuppliers = true
        this.axios.get(urlConstant.API_URL_SUPPLIERS, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              this.selects.suppliers = []
              this.isFetchingSuppliers = false
              return
            }
            
            this.selects.suppliers = response.data.suppliers
            this.isFetchingSuppliers = false
          })
          .catch(() => {
            this.selects.suppliers = []
            this.isFetchingSuppliers = false
          })
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_PURCHASES)
        }
      },
      onSupplierChange (model) {
        if (!model.supplierId) {
          this.selects.suppliers = _.cloneDeep(this.selects.initialSuppliers)

          const initialSupplier = this.selects.suppliers[0]

          model.supplierId = initialSupplier.id
          model.supplierCode = initialSupplier.code
          model.supplierName = initialSupplier.name

          return
        }

        const supplier = this.selects.suppliers.find(c => c.id === model.supplierId)
        
        model.supplierCode = supplier ? supplier.code : ''
        model.supplierName = supplier ? supplier.name : ''
      },
      getSupplierDescription (supplier, searchQuery) {
        const regex = new RegExp(searchQuery, 'i')

        const defaultResult = supplier.phoneNumbersStr
          ? `<i class="fa fa-phone"></i>&nbsp;${filterHelper.highlight(supplier.phoneNumbersStr, searchQuery)}`
          : ''

        if (regex.test(supplier.phoneNumbersStr)) {
          return defaultResult
        }

        if (regex.test(supplier.email)) {
          return `<i class="fa fa-envelope-o"></i>&nbsp;${filterHelper.highlight(supplier.email, searchQuery)}`
        }

        return defaultResult
      },
      calculateAllItemAmounts (recalculateTax = true) {
        this.model.items
          .filter(i => !!i.product)
          .forEach(item => {
            this.calculateItemAmounts(item, recalculateTax)
          })
      },
      calculateItemAmounts (purchaseItem, recalculateTax = true) {
        purchaseItem.taxAmount = null

        if (!purchaseItem || 
            (!purchaseItem.unitPurchasePrice && purchaseItem.unitPurchasePrice !== 0) || 
            purchaseItem.unitPurchasePrice < 0 ||
            (!purchaseItem.quantity && purchaseItem.quantity !== 0) ||
            purchaseItem.quantity < 0) {
          return
        }

        // Calculate tax
        if (recalculateTax) {
          for (const tax of purchaseItem.taxes) {
            this.calculateTaxItemAmounts(tax, mathHelper.round(purchaseItem.unitPurchasePrice * purchaseItem.quantity))
          }
        }
        purchaseItem.taxAmount = mathHelper.round(purchaseItem.taxes.reduce((val, tax) => val + tax.amount, 0))

        // Recalculate the purchase price when the tax is inclusive
        if (this.model.taxInclusive) {
          let unitTaxAmount = mathHelper.round(purchaseItem.taxAmount / purchaseItem.quantity)
          purchaseItem.unitPrice = mathHelper.round(purchaseItem.unitPurchasePrice - unitTaxAmount)
        }
        else {
          throw new Error('Tax should always be inclusive for purchase invoice!')
        }

        this.calculatePurchaseAmounts()
      },
      calculateTaxItemAmounts (tax, itemSubTotalAmount) {
        tax.amount = null

        const taxItem = tax && tax.taxItemId 
          ? this.selects.taxItems.find(ti => ti.id === tax.taxItemId) 
          : null

        if (!taxItem) {
          return
        }

        if (taxItem.type === taxItemTypeEnum.FIXED_AMOUNT) {
          tax.amount = taxItem.amount
          return
        }
        if (taxItem.type === taxItemTypeEnum.PERCENTAGE) {
          tax.amount = mathHelper.round(this.model.taxInclusive 
            ? itemSubTotalAmount - ((100 * itemSubTotalAmount) / (100 + taxItem.amount)) 
            : itemSubTotalAmount * taxItem.amount / 100)
          return
        }
      },
      calculatePurchaseAmounts () {
        this.model.displaySubTotalAmount = null
        this.model.taxGroups = []
        this.model.taxAmount = null
        this.model.totalAmount = null

        // Sub total
        this.model.displaySubTotalAmount = mathHelper.round(this.model.items.reduce((val, item) => val + item.displaySubTotalAmount, 0))

        // Taxes
        this.updateTaxGroups()
        this.model.taxAmount = mathHelper.round(this.model.taxGroups.reduce((val, tg) => val + tg.amount, 0))
        
        // Grand total
        this.model.totalAmount = this.model.displaySubTotalAmount
      },
      updateTaxGroups () {
        const allTaxes = this.model.items
          .reduce((taxes, item) => {
            taxes.push(...item.taxes)
            return taxes
          }, [])
          .filter(tax => tax.taxItemId)

        const group = _.groupBy(allTaxes, tax => tax.taxItemId)

        this.model.taxGroups = []
        
        for (const key of Object.keys(group)) {
          const taxGroup = group[key]
          const totalTaxAmount = mathHelper.round(taxGroup.reduce((val, tax) => val + tax.amount, 0))

          this.model.taxGroups.push({
            name: taxGroup[0].taxItemName,
            amount: totalTaxAmount,
            count: taxGroup.length
          })
        }
      },
      openAddSupplierModal () {
        this.$refs.supplierDropdown.blur()
        this.modals.addSupplier = true
      },
      closeAddSupplierModal (supplier) {
        if (supplier) {
          this.selects.suppliers = [supplier]
          this.model.supplierId = supplier.id
          this.model.supplierCode = supplier.code
          this.model.supplierName = supplier.name
        }

        this.modals.addSupplier = false
      },
      openAddProductModal () {
        this.modals.addProduct = true
      },
      closeAddProductModal (product) {
        if (product) {
          var item = {
            id: null,
            product: product,
            productId: null,
            note: product.internalNote,
            quantity: product.unitAmount,
            unitPurchasePrice: product.unitPurchasePrice,
            displaySubTotalAmount: product.unitPurchasePrice * product.unitAmount,
            taxes: [_.cloneDeep(initialPurchaseTaxItem)],
            taxAmount: null,
          }

          this.model.items.push(item)

          this.onTaxItemChange(item)
        }

        this.modals.addProduct = false
      },
      onTaxItemChange (purchaseItem) {
        if (!purchaseItem.taxes || !purchaseItem.taxes[0]) {
          this.calculateItemAmounts(purchaseItem)
          return
        }

        const tax = purchaseItem.taxes[0]

        if (!tax.taxItemId) {
          tax.taxItemName = ''
          this.calculateItemAmounts(purchaseItem)
          return
        }

        const taxItem = this.selects.taxItems.find(ti => ti.id === tax.taxItemId)

        tax.taxItemName = taxItem ? taxItem.name : ''
        this.calculateItemAmounts(purchaseItem)
      },
      onTableSelectionChange (value) {
        this.selectedItem = value
      },
      selectTableItem (row) {
        this.$refs.itemsTable.setCurrentRow(row)
      },
      removeItem (row, rowIndex) {
        const contentHtml = `Apakah kamu yakin ingin mengeluarkan produk <strong>${row.product.name}</strong> dari transaksi pembelian?`
        const onConfirm = () => {
          this.model.items.splice(rowIndex, 1)
          this.calculatePurchaseAmounts()
        }

        alertHelper.confirmHtml(null, contentHtml, alertHelper.typeEnums.warning, 'Keluarkan Produk', 'Batal', onConfirm)
      },
      getStatusAlertColor (purchaseStatus) {
        switch (purchaseStatus) {
          case purchaseInvoiceStatusEnum.OPEN:
            return 'alert-warning'
          case purchaseInvoiceStatusEnum.CLOSED:
            return 'alert-muted'
          case purchaseInvoiceStatusEnum.PAID:
            return 'alert-success'
          case purchaseInvoiceStatusEnum.VOIDED:
            return 'alert-danger'
          default:
            return 'alert-muted'
        }
      },
      getStatusLocal (purchaseStatus) {
        return purchaseInvoiceStatusEnum.getLocal(purchaseStatus).toUpperCase()
      },
      createPayment () {
        this.isShowPayment = true

        this.$nextTick(() => {
          document.querySelector('#purchase-detail-payment').scrollIntoView({behavior: "smooth"})
        })
      },
      cancelPurchaseInvoice () {
        const showSuccessMessage = (invoice) => {
          const title = 'Invoice pembelian telah dibatalkan!'
          const content = `Nomor invoice: ${invoice.transactionNo}`

          alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const action = () => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_PURCHASES_CANCEL}/${this.model.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              showSuccessMessage(response.data.purchase)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }

        const content = 'Apakah kamu yakin ingin membatalkan invoice pembelian ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Batalkan Invoice', 'Tidak', action)
      },
      cancelPurchaseInvoicePayment () {
        const showSuccessMessage = (payment) => {
          const title = 'Pembayaran invoice pembelian telah dibatalkan!'
          const content = `Nomor pembayaran: ${payment.transactionNo}`

          alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const action = () => {
          this.isSaveLoading = true
          
          this.axios.post(`${urlConstant.API_URL_PURCHASES_PAYMENTS_CANCEL}/${this.model.activePayment.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              showSuccessMessage(response.data.payment)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }

        const content = 'Apakah kamu yakin ingin membatalkan pembayaran untuk invoice pembelian ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Batalkan Pembayaran', 'Tidak', action)
      },
      cancelPaymentAndPurchaseInvoice () {
        let paymentResult = {}
        let invoiceResult = {}

        const showSuccessMessage = () => {
          const title = 'Pembayaran beserta invoice pembelian telah dibatalkan!'
          const contentHtml = `Nomor pembayaran: ${paymentResult.transactionNo}<br/>Nomor invoice: ${invoiceResult.transactionNo}`

          alertHelper.basicHtml(title, contentHtml, alertHelper.typeEnums.success, null, this.goToPrevPage)
        }

        const cancelInvoiceAction = () => new Promise((resolve, reject) => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_PURCHASES_CANCEL}/${this.model.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                reject()
                return
              }

              invoiceResult = response.data.purchase
              
              this.isSaveLoading = false
              resolve()
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
              reject()
            })
        })

        const cancelPaymentAction = () => new Promise((resolve, reject) => {
          this.isSaveLoading = true

          this.axios.post(`${urlConstant.API_URL_PURCHASES_PAYMENTS_CANCEL}/${this.model.activePayment.id}`)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                reject()
                return
              }

              paymentResult = response.data.payment

              this.isSaveLoading = false
              resolve()
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
              reject()
            })
        })

        const action = () => {
          cancelPaymentAction().then(() => {
            cancelInvoiceAction().then(() => {
              showSuccessMessage()
            })
          })
        }

        const content = 'Apakah kamu yakin ingin membatalkan pembayaran beserta invoice pembelian ini?'

        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Batalkan Pembayaran & Invoice', 'Tidak', action)
      },
      print () {
        this.isPrinting = true
        this.printIndex++

        this.$nextTick(() => {
          this.$refs.printFrame.onload = () => { 
            this.isPrinting = false
          }
        })
      },
    },
    computed: {
      isLoading () {
        return this.isPurchaseLoading || this.isReferencesLoading || this.isSaveLoading || this.isPrinting
      },
      itemsHaveSameTax () {
        const items = this.model.items.filter(i => !!i.product)
        return this.model.taxGroups.length === 1 && 
               this.model.taxGroups[0].count === items.length
      },
      canCancelPurchaseInvoicePayment () {
        const userRole = this.$store.getters.getUserRole
        const authorized = userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE.includes(userRole)

        return this.isView &&
               this.model.status === purchaseInvoiceStatusEnum.PAID && 
               this.model.activePayment.status === purchaseInvoiceStatusEnum.PAID &&
               authorized &&
               this.isShowPayment
      },
      canCancelPurchaseInvoice () {
        const userRole = this.$store.getters.getUserRole
        const authorized = userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE.includes(userRole)

        return this.isView &&
               (this.model.status === purchaseInvoiceStatusEnum.OPEN || this.model.status === purchaseInvoiceStatusEnum.VOIDED) && 
               authorized &&
               !this.isShowPayment
      },
      canCreatePayment () {
        return this.isView &&
               (this.model.status === purchaseInvoiceStatusEnum.OPEN || this.model.status === purchaseInvoiceStatusEnum.VOIDED) && 
               !this.isShowPayment
      },
      canPrint () {
        return this.isView &&
               this.model.status === purchaseInvoiceStatusEnum.PAID &&
               this.isShowPayment
      },
      canBack () {
        return this.isView &&
               (this.model.status === purchaseInvoiceStatusEnum.PAID || !this.isShowPayment)
      },
      isDefaultSupplierSelected () {
        return this.model.supplierCode === supplierConstant.DEFAULT_SUPPLIER_CODE
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name))
    },
    destroyed () {
      for (const prop in this.intervals) {
        clearInterval(this.intervals[prop])
      }
    },
  }
</script>
<style scoped lang="scss">
  .section-items {
    table {
      tbody {
        tr {
          .cell-edit, .cell-edit-info {
            display: none;
          }
          .cell-error {
            font-size: 80%;
            padding-left: 0;
            text-align: left;
            display: block;
          }
        }
      }
    }

    &.section-items-edit-mode {
      table {
        tbody {
          tr {
            &.current-row {
              .cell-edit {
                display: inline;
              }
              .cell-view, .cell-edit-info {
                font-size: 80%;
                padding-left: 11px;
                text-align: left;
                display: block;
              }
              .cell-error {
                padding-left: 11px;
              }
              .cell-index {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  .print-frame {
    width: 0;
    height: 0;
    position: absolute;

    // ----- DEBUG
    // width: 100%;
    // height: 300mm;
    // position: static;
  }
</style>
