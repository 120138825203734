<template>
  <form v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            
    <div class="d-flex flex-wrap justify-content-md-end justify-content-center" v-if="isView">
      <p-button outline round @click="closeForm()">Kembali</p-button>
    </div>

    <fg-input label="Kode" 
              v-model="model.code"
              v-validate="'required|max:20'"
              data-vv-name="Kode"
              disabled
              required
              v-if="isView || isEdit"
              :error="getError('Kode')"></fg-input>

    <fg-input label="Nama" 
              v-model="model.name"
              :disabled="isView"
              required
              v-validate="'required|max:100'"
              data-vv-name="Nama"
              :error="getError('Nama')"></fg-input>

    <fg-input label="Deskripsi" 
              v-model="model.description"
              :disabled="isView"
              data-vv-name="Deskripsi"
              :error="getError('Deskripsi')"></fg-input>

    <fg-input label="Alamat" :disabled="isView">
      <textarea class="form-control" 
                rows="3" 
                v-model="model.address" 
                :disabled="isView"></textarea>
    </fg-input>

    <fg-input label="Nomor telepon" :disabled="isView"
              :error="getError('Nomor telepon')">
      <textarea class="form-control" 
                rows="3" 
                placeholder="Contoh: 081XXX, 082XXX"
                v-model="model.phoneNumbersStr" 
                :disabled="isView"
                v-validate="{ regex:/^([0-9 ,]+)$/ }"
                data-vv-name="Nomor telepon"></textarea>
      <template slot="infoBlock" v-if="isNew || isEdit">
        <div class="small mt-1">
          Jika lebih dari satu nomor, pisahkan dengan tanda koma.
        </div>
      </template>
    </fg-input>

    <fg-input label="Email" 
              v-model="model.email"
              :disabled="isView"
              type="email"
              v-validate="'email'"
              data-vv-name="Email"
              :error="getError('Email')"></fg-input>
    
    <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
      <p-button outline round @click="closeForm()" v-if="isView">Kembali</p-button>
      <p-button outline round @click="closeForm()" v-if="isNew || isEdit">Batal</p-button>
      <p-button type="primary" 
                round
                @click="validate()"
                v-if="isNew">Tambahkan Vendor</p-button>
      <p-button type="primary" 
                round
                @click="validate()"
                v-if="isEdit">Simpan Perubahan</p-button>
    </div>

  </form>
</template>
<script>
  import Vue from 'vue'
  import base64url from 'base64url'
  import _ from 'lodash'
  import moment from 'moment'
  import { Loading, DatePicker } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import alertHelper from '../../../helpers/alertHelper'
  import * as apiErrorCodeConstant from '../../../constants/apiErrorCodeConstant'

  Vue.use(Loading)

  export default {
    inheritAttrs: false,
    name: 'supplier-detail-form',
    components: {
      [DatePicker.name]: DatePicker,
    },
    props: {
      isModal: { type: Boolean },
      isNew: { type: Boolean },
      isView: { type: Boolean },
      isEdit: { type: Boolean },
    },
    data () {
      return {
        isSupplierLoading: false,
        isSaveLoading: false,
        initialModel: {},
        model: {
          id: null,
          code: '',
          name: '',
          description: '',
          address: '',
          phoneNumbers: [],
          phoneNumbersStr: '',
          email: '',
        },
        datePickerOptions: {
          firstDayOfWeek: 1,
          disabledDate (time) {
            return time.getTime() > moment()
          }
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.initialModel = _.cloneDeep(this.model)

        if (this.isNew) {
          return
        }

        const supplierId = base64url.decode(this.$route.params.supplierSlug)

        this.isSupplierLoading = true
        this.axios.get(`${urlConstant.API_URL_SUPPLIERS}/${supplierId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isSupplierLoading = false
              return
            }
            
            if (!response.data.supplier) {
              alertHelper.basic('Vendor tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.closeForm)
              this.isSupplierLoading = false
              return
            }

            this.initialModel = _.cloneDeep(response.data.supplier)
            this.model = response.data.supplier
            
            this.isSupplierLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isSupplierLoading = false
          })
      },
      getError (fieldName) {
        const errorMsg = this.errors.first(fieldName)

        if (errorMsg && fieldName === "Nomor telepon") {
          return "Nomor telepon hanya boleh berisi angka dan tanda koma sebagai pemisah."
        }

        return errorMsg
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.saveSupplier()
        })
      },
      saveSupplier () {
        this.isSaveLoading = true

        if (this.model.phoneNumbersStr) {
          this.model.phoneNumbers = this.model.phoneNumbersStr.split(",")
        }
        
        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_SUPPLIERS, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.supplier)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        } else {
          this.axios.post(`${urlConstant.API_URL_SUPPLIERS}/${this.model.id}`, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                this.showSaveFailedMessage(response.data)
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.supplier)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      showSaveSuccessMessage (supplier) {
        const title = this.isNew ? 'Vendor berhasil ditambahkan!' : 'Detail vendor berhasil diubah!'
        const content = `Nama vendor: ${supplier.name}`

        alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.closeForm(supplier))
      },
      showSaveFailedMessage (apiResponse) {
        if (!apiResponse || !apiResponse.apiError || !apiResponse.apiErrorCode) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const showAlert = (body) => {
          alertHelper.basicHtml(null, body, alertHelper.typeEnums.warning)
        }

        if (apiResponse.apiErrorCode == apiErrorCodeConstant.API_ERROR_SUPPLIER_DUPLICATE_ID_DETAILS) {
          showAlert('Vendor dengan nama yang sama sudah terdaftar.')
          return
        }

        errorHandlerHelper.handleGeneralApiError()
      },
      closeForm (supplier) {
        this.$emit('close', supplier)
      },
    },
    computed: {
      isLoading () {
        return this.isSupplierLoading || this.isSaveLoading
      },
    },
  }
</script>
<style scoped lang="scss">
</style>
