<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Produk</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <div class="order-md-2 text-center">
              <p-button round type="primary" @click="newProduct">
                <i slot="label" class="nc-icon nc-simple-add"></i>
                &nbsp;Tambahkan Produk Baru
              </p-button>
            </div>
            <div class="order-md-1 text-center">
              <p-button :outline="!isShowFilter" round @click="toggleFilter" class="kf-color-only-transition">
                Saring&nbsp;
                <i slot="labelRight" class="nc-icon" :class="{ 'nc-minimal-down': !isShowFilter, 'nc-minimal-up': isShowFilter }"></i>
              </p-button>
            </div>
          </div>
        </div>

        <collapse-transition :duration="200">
          <div class="col-12 collapsed kf-table-filter-fields" v-show="isShowFilter">
            <form class="my-2" v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
              <div class="row">
                <div class="col-md-4">
                  <fg-input label="Kode produk" v-model="searchModel.code"></fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Nama produk" v-model="searchModel.name"></fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Bahan">
                    <el-select class="kf select-default" v-model="searchModel.materialId" clearable filterable>
                      <el-option v-for="option in selects.materials"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Kategori">
                    <el-select class="kf select-default" v-model="searchModel.categoryId" clearable filterable>
                      <el-option v-for="option in selects.categories"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Barang pesanan saja">
                    <div class="d-flex flex-wrap align-items-center form-control no-border no-background p-0">
                      <p-switch v-model="searchModel.isCustomOrderOnly" type="success" class="mb-0"></p-switch>
                      <span class="ml-2">{{getBooleanLocal(searchModel.isCustomOrderOnly)}}</span>
                    </div>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Tampilkan stok kosong">
                    <div class="d-flex flex-wrap align-items-center form-control no-border no-background p-0">
                      <p-switch v-model="searchModel.includeEmptyStock" type="success" class="mb-0"></p-switch>
                      <span class="ml-2">{{getBooleanLocal(searchModel.includeEmptyStock)}}</span>
                    </div>
                  </fg-input>
                </div>
              </div>

              <div class="d-flex justify-content-md-end justify-content-center">
                <p-button outline round @click="resetFilter">Reset</p-button>
                <p-button round @click="applyFilter">Terapkan</p-button>
              </div>
            </form>
          </div>
        </collapse-transition>

        <div class="col-12 mb-3">
          <el-table :data="products" 
                    header-row-class-name="text-primary" 
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column prop="code" label="Kode" width="190"></el-table-column>
            <el-table-column prop="name" label="Nama" min-width="220"></el-table-column>
            <el-table-column prop="materialName" label="Bahan" width="130"></el-table-column>
            <el-table-column label="Karat" width="110">
              <template slot-scope="scope">
                <el-tooltip placement="top" :content="caratTooltip(scope.row)">
                  <div>
                    <span>{{scope.row.carat}}k</span>
                    <span v-if="scope.row.caratPercentage"> / {{scope.row.caratPercentage}}%</span>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="Berat" width="105">
              <template slot-scope="scope">
                <el-tooltip placement="top" :content="weightTooltip(scope.row)">
                  <div>
                    <span>{{scope.row.weight}}gr</span>
                    <span v-if="scope.row.size"> / {{scope.row.size}}</span>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="categoryName" label="Kategori" width="150"></el-table-column>
            <el-table-column label="Stok" width="105">
              <template slot-scope="scope">
                <div>{{scope.row.unitAmount}} {{scope.row.unitName}}</div>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="center" label="Tindakan" width="120">
              <template slot-scope="props">
                <el-tooltip placement="top" content="Detail Produk">
                  <p-button type="success" size="sm" icon @click="handleDetail(props.row)" class="rounded">
                    <i class="fa fa-file-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ubah Produk">
                  <p-button type="warning" size="sm" icon @click="handleEdit(props.row)" class="rounded">
                    <i class="fa fa-pencil-square-o"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import base64url from 'base64url'
  import _ from 'lodash'
  import { CollapseTransition } from 'vue2-transitions';
  import { Card, Pagination } from '../../UIComponents'
  import { Table, TableColumn, Tooltip, Loading, Select, Option } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import arrayHelper from '../../../helpers/arrayHelper'
  import PSwitch from '../../UIComponents/Switch.vue'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  const initialPagination = {
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    totalCount: 0,
    pageSizeOptions: pageSizeOptions
  }

  const initialSearchModel = {
    code: null,
    name: null,
    materialId: null,
    categoryId: null,
    isCustomOrderOnly: false,
    includeEmptyStock: false
  }

  export default {
    components: {
      Card,
      CollapseTransition,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
      [Option.name]: Option,
      [Select.name]: Select,
      PSwitch
    },
    data () {
      return {
        isProductsLoading: false,
        isReferencesLoading: false,
        isReferencesLoaded: false,
        isShowFilter: false,
        products: [],
        selects: {
          categories: [],
          materials: [],
        },
        searchModel: _.cloneDeep(initialSearchModel),
        pagination: _.cloneDeep(initialPagination),
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }

        this.setPagination()
        this.setSearchModel()

        this.fetchData()

        if (this.isShowFilter && !this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }
      },
      fetchData () {
        this.isProductsLoading = true

        const searchModel = _.cloneDeep(this.searchModel)
        
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,

          ...searchModel
        }

        // Re-map FE & BE params
        query.excludeEmptyStock = !query.includeEmptyStock
        delete query.includeEmptyStock
        
        this.axios.get(urlConstant.API_URL_PRODUCTS, { params: query })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isProductsLoading = false
              return
            }
            
            this.products = response.data.products
            this.pagination.totalCount = response.data.totalCount

            // Add index
            let index = ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
            this.products.forEach(p => {
              p.index = index++
            })

            this.isProductsLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isProductsLoading = false
          })
      },
      handleDetail (row) {
        this.$router.push(`${urlConstant.WEB_URL_PRODUCTS_VIEW}/${base64url.encode(row.id.toString())}`)
      },
      handleEdit (row) {
        this.$router.push(`${urlConstant.WEB_URL_PRODUCTS_EDIT}/${base64url.encode(row.id.toString())}`)
      },
      newProduct () {
        this.$router.push(urlConstant.WEB_URL_PRODUCTS_NEW)
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      changePage () {
        this.applyRoute()
      },
      toggleFilter () {
        this.isShowFilter = !this.isShowFilter

        if (this.isShowFilter && !this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }
      },
      resetFilter () {
        this.pagination = _.cloneDeep(initialPagination)
        this.searchModel = _.cloneDeep(initialSearchModel)

        this.applyRoute()
      },
      applyFilter () {
        const totalCount = this.pagination.totalCount;
        this.pagination = _.cloneDeep(initialPagination)
        this.pagination.totalCount = totalCount

        this.applyRoute()
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,
        }

        for (const prop in this.searchModel) {
          if (this.searchModel[prop]) {
            query[prop] = this.searchModel[prop]
          }
        }

        if (query.materialId) {
          query.material = base64url.encode(query.materialId.toString())
          delete query.materialId
        }

        if (query.categoryId) {
          query.category = base64url.encode(query.categoryId.toString())
          delete query.categoryId
        }

        if (query.isCustomOrderOnly) {
          query.customOrder = 'true'
          delete query.isCustomOrderOnly
        }

        if (query.includeEmptyStock) {
          query.showEmptyStock = 'true'
          delete query.includeEmptyStock
        }

        this.$router.push({ path: this.$route.path, query: query })
      },
      setSearchModel () {
        for (const prop in this.searchModel) {
          if (this.$route.query[prop]) {
            this.searchModel[prop] = this.$route.query[prop]
            this.isShowFilter = true
          }
          
          if (this.searchModel[prop] === '') {
            this.searchModel[prop] = null
          }
        }

        if (this.$route.query.material) {
          this.searchModel.materialId = parseInt(base64url.decode(this.$route.query.material))
          this.isShowFilter = true
        }

        if (this.$route.query.category) {
          this.searchModel.categoryId = parseInt(base64url.decode(this.$route.query.category))
          this.isShowFilter = true
        }

        if (this.$route.query.customOrder == 'true') {
          this.searchModel.isCustomOrderOnly = true
          this.isShowFilter = true
        }

        if (this.$route.query.showEmptyStock == 'true') {
          this.searchModel.includeEmptyStock = true
          this.isShowFilter = true
        }
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }
        
        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_MATERIALS),
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_CATEGORIES),
        ])
          .then(responses => {
            const pMatRes = responses[0]
            const pCatRes = responses[1]

            // Product materials
            let materials = pMatRes.data.isSuccess ? pMatRes.data.productMaterials : []
            materials.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.materials = materials

            // Product categories
            let categories = pCatRes.data.isSuccess ? pCatRes.data.productCategories : []
            categories.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.categories = categories

            this.isReferencesLoading = false
          })
          .catch(() => {
            this.isReferencesLoading = false
          })
      },
      caratTooltip (row) {
        if (!row || !row.carat) {
          return ''
        }

        let output = `Karat: ${row.carat}k`

        if (row.caratPercentage) {
          output += ` / Persentase Karat: ${row.caratPercentage}%`
        }

        return output
      },
      weightTooltip (row) {
        if (!row || !row.weight) {
          return ''
        }

        let output = `Berat: ${row.weight}gr`

        if (row.size) {
          output += ` / Ukuran: ${row.size}`
        }

        return output
      },
      getBooleanLocal (value) {
        return value ? 'Ya' : 'Tidak'
      },
    },
    computed: {
      isLoading () {
        return this.isProductsLoading || this.isReferencesLoading
      },
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      }
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
  }
</script>
<style scoped>
</style>
