<template>
  <div class="row">
    <div class="col-lg-8">
      <card>
        <template slot="header">
          <h5 class="card-title" v-if="isNew">Tambah Produk</h5>
          <h5 class="card-title" v-if="isView">Detail Produk</h5>
          <h5 class="card-title" v-if="isEdit">Ubah Produk</h5>
        </template>
        <template slot="default">
          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage()" v-if="isView">Kembali</p-button>
          </div>

          <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <form>
              <fg-input label="Kode produk" 
                        v-model="model.code"
                        v-validate="'required|max:20'"
                        data-vv-name="Kode produk"
                        disabled
                        required
                        v-if="isView || isEdit"
                        :error="getError('Kode produk')"></fg-input>

              <fg-input label="Nama produk" 
                        v-model="model.name"
                        placeholder="Contoh: Cincin Ukir Mata 5 Merah Hitam 5.75gr"
                        :disabled="isView"
                        required
                        @change="onNameChange"
                        v-validate="'required|max:150'"
                        data-vv-name="Nama produk"
                        :error="getError('Nama produk')"></fg-input>
              
              <fg-input label="Deskripsi" :disabled="isView">
                <textarea class="form-control" 
                          rows="3" 
                          placeholder="Contoh: Model ukir bali dengan permata mirah dikelilingi permata hitam."
                          v-model="model.description" 
                          @change="onDescriptionChange"
                          :disabled="isView"></textarea>
              </fg-input>

              <div class="row">
                <div class="col-md-6">

                  <fg-input type="number" 
                            onwheel="this.blur()"
                            label="Karat"
                            required
                            v-model.number="model.carat"
                            v-validate="'required|min_value:0|max_value:24'"
                            data-vv-name="Karat"
                            :disabled="isView"
                            :error="getError('Karat')">
                    <template slot="addonRight">karat</template>
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input type="number" 
                            onwheel="this.blur()"
                            label="Persentase karat"
                            required
                            v-model.number="model.caratPercentage"
                            v-validate="'required|min_value:0|max_value:100'"
                            data-vv-name="Persentase karat"
                            :disabled="isView"
                            :error="getError('Persentase karat')">
                    <template slot="addonRight">%</template>
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input type="number"
                            label="Berat"
                            required
                            onwheel="this.blur()" 
                            v-model.number="model.weight"
                            v-validate="'required|min_value:0'"
                            data-vv-name="Berat"
                            :disabled="isView"
                            :error="getError('Berat')">
                    <template slot="addonRight">gr</template>
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input type="number"
                            label="Ukuran"
                            onwheel="this.blur()" 
                            v-model.number="model.size"
                            v-validate="'min_value:0'"
                            data-vv-name="Ukuran"
                            :disabled="isView"
                            :error="getError('Ukuran')">
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input type="number" 
                            onwheel="this.blur()"
                            label="Persentase tukar barang"
                            required
                            v-model.number="model.exchangePercentage"
                            v-validate="'required|min_value:0|max_value:120'"
                            data-vv-name="Persentase tukar barang"
                            :disabled="isView"
                            :error="getError('Persentase tukar barang')">
                    <template slot="addonRight">%</template>
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input label="Bahan" 
                            required 
                            :disabled="isView"
                            :error="getError('Bahan')">
                    <el-select class="kf select-default" 
                              v-model="model.materialId"
                              v-validate="'required'"
                              :disabled="isView"
                              data-vv-name="Bahan"
                              filterable>
                      <el-option v-for="option in selects.materials"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input label="Kategori" 
                            required 
                            :disabled="isView"
                            :error="getError('Kategori')">
                    <el-select class="kf select-default" 
                              v-model="model.categoryId" 
                              @change="onCategoryChange"
                              v-validate="'required'"
                              :disabled="isView"
                              data-vv-name="Kategori"
                              filterable>
                      <el-option v-for="option in selects.categories"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>

                </div>
                <div class="col-md-6">

                  <fg-input label="Barang pesanan" :disabled="isView" required>
                    <div class="d-flex flex-wrap align-items-center form-control no-border p-0">
                      <p-switch :disabled="isView" v-model="model.isCustomOrder" type="success" class="mb-0"></p-switch>
                      <span class="ml-2">{{getCustomOrderLocal(model.isCustomOrder)}}</span>
                    </div>
                  </fg-input>

                </div>
              </div>
              
              <fg-input kind="money" 
                        v-model.number="model.purchasePrice"
                        v-validate="'required|min_value:0|max_value:100000000000'"
                        data-vv-name="Harga perolehan"
                        label="Harga perolehan (per unit)"
                        required
                        :disabled="isView"
                        :error="getError('Harga perolehan')">
                <template slot="addonLeft">Rp</template>
                <template slot="infoBlock">
                  <div class="small mt-1" v-if="(isNew || isEdit) && getRecommendedPurchasePrice">
                    Harga rekomendasi: {{getRecommendedPurchasePrice | currencyFormat}}. Klik&nbsp;<a href="javascript:void(0);" @click="useRecommendedPurchasePrice">disini</a>&nbsp;untuk memakai harga rekomendasi.
                  </div>
                </template>
              </fg-input>
              
              <fg-input kind="money" 
                        v-model.number="model.sellPrice"
                        v-validate="`required|min_value:0|max_value:100000000000|min_value:${model.purchasePrice}`"
                        data-vv-name="Harga jual ekspektasi"
                        label="Harga jual ekspektasi"
                        required
                        :disabled="isView"
                        :error="getError('Harga jual ekspektasi')">
                <template slot="addonLeft">Rp</template>
              </fg-input>

              <div class="row">
                <div class="col-md-6">
                  
                  <fg-input type="number" 
                            onwheel="this.blur()"
                            label="Jumlah stok" 
                            required
                            v-model.number="model.unitAmount"
                            v-validate="'required|min_value:0'"
                            data-vv-name="Jumlah stok"
                            :disabled="isView"
                            :error="getError('Jumlah stok')"></fg-input>

                </div>
                <div class="col-md-6">
                  
                  <fg-input label="Satuan stok" 
                            required
                            disabled
                            :error="getError('Satuan stok')">
                    <el-select class="kf select-default" 
                              v-model="model.unitId"
                              filterable
                              v-validate="'required'"
                              disabled
                              data-vv-name="Satuan stok">
                      <el-option v-for="option in selects.units"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.description"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>

                </div>
              </div>
            </form>

            <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
              <p-button outline round @click="goToPrevPage()" v-if="isView">Kembali</p-button>
              <p-button outline round @click="goToPrevPage()" v-if="isNew || isEdit">Batal</p-button>
              <p-button type="primary" round @click="validate()" v-if="isNew">Tambahkan Produk</p-button>
              <p-button type="primary" round @click="validate()" v-if="isEdit">Simpan Perubahan</p-button>
              <p-button type="primary" round @click="printBarcode()" v-if="canPrintBarcode"><i class="fa fa-barcode"></i>&nbsp;&nbsp;Barcode</p-button>
            </div>

            <iframe 
              :src="printSrc"
              v-if="canPrintBarcode && printIndex > 0"
              :key="printIndex"
              frameBorder="0"
              ref="printFrame"
              class="print-frame">
            </iframe>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import base64url from 'base64url'
  import _ from 'lodash'
  import { Select, Option, Loading } from 'element-ui'
  import { Card } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import arrayHelper from '../../../helpers/arrayHelper'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import { GOLD_PRICE_FETCH } from '../../../store/actions/goldPrice'
  import alertHelper from '../../../helpers/alertHelper'
  import * as productMaterialCodeEnum from '../../../enums/productMaterialCodeEnum'
  import mathHelper from '../../../helpers/mathHelper'
  import PSwitch from '../../UIComponents/Switch.vue'

  Vue.use(Loading)

  export default {
    components: {
      Card,
      [Option.name]: Option,
      [Select.name]: Select,
      PSwitch
    },
    data () {
      return {
        isProductLoading: false,
        isReferencesLoading: false,
        isSaveLoading: false,
        isGoldPriceLoading: false,
        isNew: false,
        isView: false,
        isEdit: false,
        isPrinting: false,
        printSrc: '',
        printIndex: 0,
        hasPrevRoute: false,
        initialModel: {},
        yellowGoldProductMaterial: null,
        whiteGoldProductMaterial: null,
        model: {
          id: null,
          name: '',
          code: '',
          description: '',
          weight: null,
          size: null,
          carat: null,
          caratPercentage: null,
          exchangePercentage: null,
          categoryId: null,
          materialId: null,
          isCustomOrder: false,
          purchasePrice: null,
          sellPrice: null,
          unitId: 1,
          unitAmount: 1
        },
        selects: {
          units: [],
          categories: [],
          materials: [],
        }
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.isNew = false
        this.isView = false
        this.isEdit = false

        if (!this.$route.params.productSlug) {
          this.isNew = true
        }
        else if (this.$route.path.includes('/view/')) {
          this.isView = true
        }
        else if (this.$route.path.includes('/edit/')) {
          this.isEdit = true
        }
        else {
          this.isNew = true
        }
        
        this.fetchReferences()

        if (this.isNew) {
          this.fetchGoldPrice()
          return
        }

        if (this.isEdit) {
          this.fetchGoldPrice()
        }

        this.printSrc = `${window.location.origin}${urlConstant.WEB_URL_PRODUCTS_PRINT_BARCODE}/${this.$route.params.productSlug}`

        const productId = base64url.decode(this.$route.params.productSlug)

        this.isProductLoading = true
        this.axios.get(`${urlConstant.API_URL_PRODUCTS}/${productId}`)
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isProductLoading = false
              return
            }

            if (!response.data.product) {
              alertHelper.basic('Produk tidak ditemukan!', null, alertHelper.typeEnums.warning, 'Kembali', this.goToPrevPage)
              this.isProductLoading = false
              return
            }

            this.initialModel = _.cloneDeep(response.data.product)
            this.model = response.data.product
            
            this.isProductLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isProductLoading = false
          })
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.saveProduct()
        })
      },
      saveProduct () {
        this.isSaveLoading = true

        if (this.isNew) {
          this.axios.post(urlConstant.API_URL_PRODUCTS, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.product)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        } else {
          this.axios.post(`${urlConstant.API_URL_PRODUCTS}/${this.model.id}`, this.model)
            .then(response => {
              if (!response.data.isSuccess) {
                errorHandlerHelper.handleGeneralApiError()
                this.isSaveLoading = false
                return
              }

              this.showSaveSuccessMessage(response.data.product)
              this.isSaveLoading = false
            })
            .catch(error => {
              errorHandlerHelper.handleGeneralApiError(error)
              this.isSaveLoading = false
            })
        }
      },
      showSaveSuccessMessage (product) {
        const title = this.isNew ? 'Produk berhasil ditambahkan!' : 'Produk berhasil diubah!'
        const content = `Kode produk: ${product.code}`

        alertHelper.basic(title, content, alertHelper.typeEnums.success, null, this.goToPrevPage)
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_CATEGORIES),
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_MATERIALS),
          this.axios.get(urlConstant.API_URL_PRODUCTS_REFERENCES_UNITS)
        ])
          .then(responses => {
            const pCatRes = responses[0]
            const pMatRes = responses[1]
            const pUnitRes = responses[2]

            // Product categories
            let categories = pCatRes.data.isSuccess ? pCatRes.data.productCategories : []
            categories.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.categories = categories

            // Product materials
            let materials = pMatRes.data.isSuccess ? pMatRes.data.productMaterials : []
            materials.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.materials = materials
            this.yellowGoldProductMaterial = materials.find(m => m.code === productMaterialCodeEnum.YELLOW_GOLD)
            this.whiteGoldProductMaterial = materials.find(m => m.code === productMaterialCodeEnum.WHITE_GOLD)

            // Product units
            let units = pUnitRes.data.isSuccess ? pUnitRes.data.productUnits : []
            units.sort((a, b) => arrayHelper.stringCompare(a.description, b.description))
            this.selects.units = units

            this.isReferencesLoading = false;
          })
          .catch(() => {
            this.isReferencesLoading = false;
          })
      },
      fetchGoldPrice () {
        this.isGoldPriceLoading = true

        this.$store.dispatch(GOLD_PRICE_FETCH)
          .then(() => {
            this.isGoldPriceLoading = false
          })
          .catch((error) => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isGoldPriceLoading = false
          })
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_PRODUCTS)
        }
      },
      onCategoryChange () {
        if (this.isNew) {
          return
        }

        const title = 'Apakah kamu yakin?'
        const content = 'Mengubah kategori akan mengubah awalan huruf pada kode produk.'
        const onCancel = () => { this.model.categoryId = this.initialModel.categoryId }

        alertHelper.confirm(title, content, alertHelper.typeEnums.warning, 'Ya', 'Batal', null, onCancel)
      },
      onNameChange () {
        if (!this.model.name) {
          return
        }

        this.model.name = this.model.name.trim()
        this.model.name = `${this.model.name.charAt(0).toUpperCase()}${this.model.name.substring(1)}`
      },
      onDescriptionChange () {
        if (!this.model.description) {
          return
        }

        this.model.description = this.model.description.trim()
        this.model.description = `${this.model.description.charAt(0).toUpperCase()}${this.model.description.substring(1)}`
      },
      useRecommendedPurchasePrice () {
        this.model.purchasePrice = this.getRecommendedPurchasePrice
      },
      getCustomOrderLocal (isCustomOrder) {
        return isCustomOrder ? 'Ya' : 'Tidak'
      },
      printBarcode () {
        this.isPrinting = true
        this.printIndex++

        this.$nextTick(() => {
          this.$refs.printFrame.onload = () => { 
            this.isPrinting = false
          }
        })
      },
    },
    computed: {
      isLoading () {
        return this.isProductLoading || this.isReferencesLoading || this.isSaveLoading || this.isGoldPriceLoading || this.isPrinting
      },
      getRecommendedPurchasePrice () {
        if ((!this.yellowGoldProductMaterial || this.yellowGoldProductMaterial.id !== this.model.materialId) &&
            (!this.whiteGoldProductMaterial || this.whiteGoldProductMaterial.id !== this.model.materialId)) {
          return null
        }

        const gp = this.$store.getters.getGoldPrice
        if (!gp || !gp.price || !this.model || !this.model.weight || !this.model.exchangePercentage) {
          return null
        }

        return mathHelper.round(gp.price * this.model.weight * this.model.exchangePercentage / 100)
      },
      canPrintBarcode () {
        return this.isView
      },
    },
    beforeRouteEnter(to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name));
    },
    filters: {
      ...filterHelper.spread
    },
  }
</script>
<style scoped lang="scss">
  .print-frame {
    width: 0;
    height: 0;
    position: absolute;

    // ----- DEBUG
    // width: 100%;
    // height: 300mm;
    // position: static;
  }
</style>
