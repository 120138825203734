import { render, staticRenderFns } from "./GoldPriceDetail.vue?vue&type=template&id=cfa6fd26&scoped=true&"
import script from "./GoldPriceDetail.vue?vue&type=script&lang=js&"
export * from "./GoldPriceDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa6fd26",
  null
  
)

export default component.exports