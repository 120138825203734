<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Pelanggan</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-column flex-md-row justify-content-md-end">
            <div class="text-center">
              <p-button round type="primary" @click="addCustomer">
                <i slot="label" class="nc-icon nc-simple-add"></i>
                &nbsp;Tambah Pelanggan
              </p-button>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <el-table :data="customers" 
                    header-row-class-name="text-primary" 
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column label="Kode" prop="code" width="140"></el-table-column>
            <el-table-column label="Nama" prop="name" width="250"></el-table-column>
            <el-table-column label="Alamat" prop="address" min-width="250"></el-table-column>
            <el-table-column label="Tgl & Tempat Lahir" width="225">
              <template slot-scope="scope">
                <div>{{scope.row.birthDate | dateFormat}}<span v-if="!!scope.row.birthDate && !!scope.row.birthPlace"> - </span>{{scope.row.birthPlace}}</div>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="center" label="Tindakan" width="120">
              <template slot-scope="props">
                <el-tooltip placement="top" content="Detail Pelanggan">
                  <p-button type="success" size="sm" icon @click="viewCustomer(props.row)" class="rounded">
                    <i class="fa fa-file-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ubah Detail Pelanggan">
                  <p-button type="warning" size="sm" icon @click="editCustomer(props.row)" class="rounded">
                    <i class="fa fa-pencil-square-o"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import { Card, Pagination } from '../../UIComponents'
  import { Loading, Table, TableColumn, Tooltip } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import routerHelper from '../../../helpers/routerHelper'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  export default {
    components: {
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
      [Tooltip.name]: Tooltip,
    },
    data () {
      return {
        isLoading: false,
        customers: [],
        pagination: {
          pageNumber: defaultPageNumber,
          pageSize: defaultPageSize,
          totalCount: 0,
          pageSizeOptions: pageSizeOptions
        },
      }
    },
    created() {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }

        this.setPagination()
        this.fetchData()
      },
      fetchData () {
        this.isLoading = true

        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        }
        
        this.axios.get(urlConstant.API_URL_CUSTOMERS, { params: query })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isLoading = false
              return
            }
            
            this.customers = response.data.customers
            this.pagination.totalCount = response.data.totalCount

            this.isLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isLoading = false
          })
      },
      addCustomer () {
        routerHelper.safePush(urlConstant.WEB_URL_CUSTOMERS_NEW)
      },
      viewCustomer (row) {
        routerHelper.safePush(`${urlConstant.WEB_URL_CUSTOMERS_VIEW}/${base64url.encode(row.id.toString())}`)
      },
      editCustomer (row) {
        routerHelper.safePush(`${urlConstant.WEB_URL_CUSTOMERS_EDIT}/${base64url.encode(row.id.toString())}`)
      },
      changePage () {
        this.applyRoute()
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        }

        routerHelper.safePush({ path: this.$route.path, query: query })
      },
      setDefaultRouteQuery () {
        let isChanged = false
        let query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }

        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
    },
    computed: {
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      }
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
  }
</script>
<style scoped lang="scss">
</style>
