<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Transfer Produk</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <div class="order-md-2 text-center">
              <p-button round type="primary" @click="newTransfer()">
                <i slot="label" class="nc-icon nc-simple-add"></i>
                &nbsp;Buat Transfer Produk
              </p-button>
            </div>
            <div class="order-md-1 text-center">
              <p-button :outline="!isShowFilter" round @click="toggleFilter" class="kf-color-only-transition">
                Saring&nbsp;
                <i slot="labelRight" class="nc-icon" :class="{ 'nc-minimal-down': !isShowFilter, 'nc-minimal-up': isShowFilter }"></i>
              </p-button>
            </div>
          </div>
        </div>
        
        <collapse-transition :duration="200">
          <div class="col-12 collapsed kf-table-filter-fields" v-show="isShowFilter">
            <form class="my-2" v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
              <div class="row">
                <div class="col-md-4">
                  <fg-input label="Kode" v-model="searchModel.code"></fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Jenis">
                    <el-select class="kf select-default" v-model="searchModel.direction" clearable filterable>
                      <el-option v-for="option in selects.directions"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Toko">
                    <el-select class="kf select-default" v-model="searchModel.store" clearable filterable>
                      <el-option v-for="option in selects.filteredDomainSettings"
                                class="kf select-default"
                                :value="option.host"
                                :label="option.name"
                                :key="option.host">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input label="Status">
                    <el-select class="kf select-default" v-model="searchModel.status" clearable filterable>
                      <el-option v-for="option in selects.statuses"
                                class="kf select-default"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
              </div>

              <div class="d-flex justify-content-md-end justify-content-center">
                <p-button outline round @click="resetFilter">Reset</p-button>
                <p-button round @click="applyFilter">Terapkan</p-button>
              </div>
            </form>
          </div>
        </collapse-transition>

        <div class="col-12 mb-3">
          <el-table :data="transfers" 
                    header-row-class-name="text-primary"
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column prop="code" label="Kode" width="160"></el-table-column>
            <el-table-column label="Jenis" align="center" width="100">
              <template slot-scope="scope">
                <div class="text-uppercase" :class="getDirectionTextColor(scope.row.direction)">{{getDirectionLocal(scope.row.direction)}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Toko" min-width="220">
              <template slot-scope="scope">
                <div>{{getStoreName(scope.row.store)}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Tanggal Transfer" width="195">
              <template slot-scope="scope">
                <div>{{scope.row.transferDate | dateTimeFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Tanggal Diterima" width="195">
              <template slot-scope="scope">
                <div>{{scope.row.receivedDate | dateTimeFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Status" align="center" width="120">
              <template slot-scope="scope">
                <div class="text-uppercase" :class="getStatusTextColor(scope.row.status)">{{getStatusLocal(scope.row.status)}}</div>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="center" label="Tindakan" width="120">
              <template slot-scope="props">
                <el-tooltip placement="top" content="Detail Transfer">
                  <p-button type="success" size="sm" icon @click="handleDetail(props.row)" class="rounded">
                    <i class="fa fa-file-o"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import { CollapseTransition } from 'vue2-transitions';
  import { Card, Pagination } from '../../UIComponents'
  import { Table, TableColumn, Loading, Tooltip, Option, Select } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import routerHelper from '../../../helpers/routerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import * as productTransferStatusEnum from '../../../enums/productTransferStatusEnum'
  import * as productTransferDirectionEnum from '../../../enums/productTransferDirectionEnum'
  import arrayHelper from '../../../helpers/arrayHelper'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  const initialPagination = {
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    totalCount: 0,
    pageSizeOptions: pageSizeOptions
  }

  const initialSearchModel = {
    code: null,
    store: null,
    direction: null,
    status: null,
  }

  export default {
    components: {
      Card,
      CollapseTransition,
      [Pagination.name]: Pagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
      [Option.name]: Option,
      [Select.name]: Select,
    },
    data () {
      return {
        isTransfersLoading: false,
        isReferencesLoading: false,
        isReferencesLoaded: false,
        isShowFilter: false,
        transfers: [],
        selects: {
          domainSettings: [],
          filteredDomainSettings: [],
          directions: [],
          statuses: [],
        },
        searchModel: _.cloneDeep(initialSearchModel),
        pagination: _.cloneDeep(initialPagination),
      }
    },
    created() {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setPagination()
        this.setSearchModel()

        if (!this.isReferencesLoaded) {
          this.isReferencesLoaded = true
          this.fetchReferences()
        }

        this.fetchData()
      },
      fetchData () {
        this.isTransfersLoading = true

        // Clone deep so we don't mutate the original object
        const searchModel = _.cloneDeep(this.searchModel)
        
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,

          ...searchModel
        }
        
        this.axios.get(urlConstant.API_URL_PRODUCT_TRANSFERS, { params: query })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isTransfersLoading = false
              return
            }
            
            this.transfers = response.data.productTransfers
            this.pagination.totalCount = response.data.totalCount

            this.isTransfersLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isTransfersLoading = false
          })
      },
      fetchReferences () {
        this.isReferencesLoading = true

        Promise.all([
          this.axios.get(urlConstant.API_URL_DOMAIN_LIST),
          this.axios.get(urlConstant.API_URL_PRODUCT_TRANSFERS_DIRECTIONS),
          this.axios.get(urlConstant.API_URL_PRODUCT_TRANSFERS_STATUSES),
        ])
          .then(responses => {
            const pDomainRes = responses[0]
            const pDirectionRes = responses[1]
            const pStatusRes = responses[2]
            
            // Domain settings
            let domainSettings = pDomainRes.data.isSuccess ? pDomainRes.data.domainSettingsList : []
            domainSettings.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.domainSettings = domainSettings

            // Filtered domain settings
            const currentDomainSettings = this.$store.getters.getDomainSettings
            let filteredDomainSettings = domainSettings.filter(x => x.host !== currentDomainSettings.host)
            this.selects.filteredDomainSettings = filteredDomainSettings

            // Directions
            let directions = (pDirectionRes.data.isSuccess ? pDirectionRes.data.productTransferDirections : [])
              .map(x => {
                return {
                  id: x, 
                  name: this.getDirectionLocal(x)
                }
              })
            directions.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.directions = directions

            // Statuses
            let statuses = (pStatusRes.data.isSuccess ? pStatusRes.data.productTransferStatuses : [])
              .map(x => {
                return {
                  id: x, 
                  name: this.getStatusLocal(x)
                }
              })
            statuses.sort((a, b) => arrayHelper.stringCompare(a.name, b.name))
            this.selects.statuses = statuses

            this.isReferencesLoading = false;
          })
          .catch(() => {
            this.isReferencesLoading = false;
          })
      },
      newTransfer () {
        routerHelper.safePush(urlConstant.WEB_URL_PRODUCT_TRANSFERS_NEW)
      },
      handleDetail (row) {
        routerHelper.safePush(`${urlConstant.WEB_URL_PRODUCT_TRANSFERS_VIEW}/${base64url.encode(row.id.toString())}`)
      },
      toggleFilter () {
        this.isShowFilter = !this.isShowFilter
      },
      resetFilter () {
        this.pagination = _.cloneDeep(initialPagination)
        this.searchModel = _.cloneDeep(initialSearchModel)

        this.applyRoute()
      },
      applyFilter () {
        const totalCount = this.pagination.totalCount;
        this.pagination = _.cloneDeep(initialPagination)
        this.pagination.totalCount = totalCount

        this.applyRoute()
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,
        }

        for (const prop in this.searchModel) {
          if (this.searchModel[prop]) {
            query[prop] = this.searchModel[prop]
          }
        }

        routerHelper.safePush({ path: this.$route.path, query: query })
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      setSearchModel () {
        for (const prop in this.searchModel) {
          if (this.$route.query[prop]) {
            this.searchModel[prop] = this.$route.query[prop]
            this.isShowFilter = true
          }

          if (this.searchModel[prop] === '') {
            this.searchModel[prop] = null
          }
        }
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }
        
        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      getDirectionTextColor (status) {
        switch (status) {
          case productTransferDirectionEnum.IN:
            return 'text-success'
          case productTransferDirectionEnum.OUT:
            return 'text-info'
          default:
            return 'text-muted'
        }
      },
      getDirectionLocal (status) {
        return productTransferDirectionEnum.getLocal(status).toUpperCase()
      },
      getStatusTextColor (status) {
        switch (status) {
          case productTransferStatusEnum.CREATED:
          case productTransferStatusEnum.CANCELLED:
            return 'text-muted'
          case productTransferStatusEnum.IN_PROGRESS:
            return 'text-warning'
          case productTransferStatusEnum.RECEIVED:
            return 'text-success'
          case productTransferStatusEnum.RETURNED:
            return 'text-danger'
          default:
            return 'text-muted'
        }
      },
      getStatusLocal (status) {
        return productTransferStatusEnum.getLocal(status).toUpperCase()
      },
      changePage () {
        this.applyRoute()
      },
      getStoreName (storeHost) {
        const store = this.selects.domainSettings.find(x => x.host === storeHost)
        return (store && store.name) || storeHost
      },
    },
    computed: {
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      },
      isLoading () {
        return this.isTransfersLoading || this.isReferencesLoading
      },
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
  }
</script>
<style scoped>
</style>
